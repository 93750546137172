import {MatDatetimeFormats} from "@mat-datetimepicker/core";

export const MAT_MOMENT_DATETIME_FORMATS: MatDatetimeFormats = {
  parse: {
    dateInput: "MMMM Do, h:mm:ss a",
    monthInput: "MMMM Do, h:mm:ss a",
    timeInput: "MMMM Do, h:mm:ss a",
    datetimeInput: "MMMM Do, h:mm:ss a"
  },
  display: {
    dateInput: "MMMM Do, h:mm:ss a",
    monthInput: "MMMM Do, h:mm:ss a",
    datetimeInput: "MMMM Do, h:mm:ss a",
    timeInput: "MMMM Do, h:mm:ss a",
    monthYearLabel: "MMMM Do, h:mm:ss a",
    dateA11yLabel: "MMMM Do, h:mm:ss a",
    monthYearA11yLabel: "MMMM Do, h:mm:ss a",
    popupHeaderDateLabel: "MMMM Do, h:mm:ss a"
  }
};