import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalsService } from './../../globals.service';
import { EditRowDialogComponent } from '../../dialogs/edit-row-dialog/edit-row-dialog.component';
import { ShareActivityComponent } from '../../dialogs/share-activity/share-activity.component';
import { ReportsComponent } from '../../dialogs/reports/reports.component';

import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { Activity } from '../../classes/activity';
import { ActivitesService } from '../../services/activites.service';
import * as moment from 'moment';
import { AreUSureDialogComponent } from '../../dialogs/are-u-sure-dialog/are-u-sure-dialog.component';
import { MatSort } from '@angular/material/sort';
import { DeviceDetectorService } from 'ngx-device-detector';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
// tslint:disable: forin
// tslint:disable: max-line-length


@Component({
  selector: 'app-activities-table',
  templateUrl: './activities-table.component.html',
  styleUrls: ['./activities-table.component.scss']
})

export class ActivitiesTableComponent implements OnInit {

  displayedColumns: string[];
  dataSource;
  isShown = false;
  now;
  courseStatusDisabled = false;
  createActivityText = 'תכנון ';
  @ViewChild(MatSort) sort: MatSort;
  isMobile = true;
  constructor(private deviceService: DeviceDetectorService,
    private router: Router,
    public dialog: MatDialog,
    public activitesService: ActivitesService,
    private GlobalsService: GlobalsService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private firestore: AngularFirestore) {
    this.now = new Date();

    localStorage.removeItem('configId');
    localStorage.setItem('subComponent', 'activities');
    this.isMobile = this.deviceService.isMobile();
    this.displayedColumns = this.isMobile ? [ 'activityName', 'activityType', 'status', 'edit'] : [ 'activityName', 'activityType', 'activityCode', 'startDate', 'endDate', 'status', 'edit'];
    this.activitesService.getActivities('notFinished', 'all', 'all', 'sort-by-creation-date').then( activities => {
      this.dataSource = new MatTableDataSource<Activity>(activities);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
    if (this.GlobalsService.createActivity){
      this.GlobalsService.createActivity = undefined;
      this.createActivity();
    }
    this.createActivityText += 'פעילות';

    this.matIconRegistry.addSvgIcon(`icon_preview`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/preview.svg`));
    this.matIconRegistry.addSvgIcon(`icon_property`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/property.svg`));
    this.matIconRegistry.addSvgIcon(`icon_switch_on_off`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/switch_on_off.svg`));
  }
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = '';
  }

  moveToDashboard(): void{
    this.router.navigateByUrl('/statistics');
  }
  logout(): void{
    localStorage.setItem('panelEmail', '');
    localStorage.setItem('panelPassword', '');
    this.router.navigateByUrl('/login');
  }
  sharePrassed(col): void{
    const activity = (JSON.parse(JSON.stringify(col)));
    const dialogRef = this.dialog.open(ShareActivityComponent, {
      width: '500px',
      data: activity
    });
  }
  editPrassed(col): void{
    this.openDialog(col);
  }
  downloadCurseStatus(col): void{
    this.courseStatusDisabled = true;
    const dictOfUsers = {};
    let fileName:string = '';
    this.firestore.collection('activities').doc(col.activityCode).get().toPromise().then(activityDoc => {
      fileName += activityDoc.data().activityName;
    }).then(() => {
      this.firestore.collection('activities').doc(col.activityCode).collection('users').get().toPromise().then(users => {
        users.docs.forEach( userDoc => {
          dictOfUsers[userDoc.id] = {sumTime: 0, countTime: 0, sumScore: 0, countScore: 0};
        });
      }).then(() => {
        this.firestore.collection('scoreReport', ref => ref.where('activityId', '==', col.activityCode)).get().toPromise().then( scores => {
          scores.forEach( scoreDoc => {
            if (scoreDoc.data().gameTimeSecond != null){
              dictOfUsers[scoreDoc.data().userId].sumTime += scoreDoc.data().gameTimeSecond;
              dictOfUsers[scoreDoc.data().userId].countTime++;
            }
            if (scoreDoc.data().gameScore != null){
              dictOfUsers[scoreDoc.data().userId].sumScore += scoreDoc.data().gameScore;
              dictOfUsers[scoreDoc.data().userId].countScore++;
            }
          });
        }).then(() => {
          const promises = [];
          for ( let userId in dictOfUsers){
            promises.push(this.firestore.collection('users').doc(userId).collection('activities').doc(col.activityCode).get().toPromise().then(userDoc => {
              const now = new Date();
              now.setDate(now.getDate() - 8);
              if (dictOfUsers[userId].countScore !== 0){
                dictOfUsers[userId]['ציון ממוצע'] =   dictOfUsers[userId].sumScore / dictOfUsers[userId].countScore;
                dictOfUsers[userId]['ציון ממוצע'] = parseFloat(dictOfUsers[userId]['ציון ממוצע']).toFixed(1);
              }
              else{
                dictOfUsers[userId]['ציון ממוצע'] = 'אין';
              }
              if (dictOfUsers[userId].countTime !== 0){
                dictOfUsers[userId]['זמן ממוצע'] = dictOfUsers[userId].sumTime / dictOfUsers[userId].countTime;
              }
              else{
                dictOfUsers[userId]['זמן ממוצע'] = 'אין';
              }
              dictOfUsers[userId]['האם הקורס תקוע?'] = 'לא';
              if (userDoc.data().endTime != null){
                dictOfUsers[userId]['תאריך סיום קורס'] = userDoc.data().endTime.toDate().toLocaleDateString('he-IL');
              }
              else{
                dictOfUsers[userId]['תאריך סיום קורס'] = 'לא הסתיים עדיין';
              }
              dictOfUsers[userId]['תאריך תחילת קורס'] = userDoc.data().startTime.toDate().toLocaleDateString('he-IL');
              if (col.loginType !== 'solos'){
                if (col.loginType === 'hierarchy'){
                  const array: Array<string> = userDoc.data().group;
                  dictOfUsers[userId].קבוצה = array[array.length-1];
                }
                else{
                  dictOfUsers[userId].קבוצה = userDoc.data().group;
                }
              }
              if (col.isNameRequired){
                dictOfUsers[userId]['שם עובד'] = userDoc.data().name;
              }
              else{
                dictOfUsers[userId].שם = 'משתתף אנונימי מקבוצה ' + userDoc.data().group;
              }
              delete dictOfUsers[userId].sumTime;
              delete dictOfUsers[userId].countTime;
              delete dictOfUsers[userId].sumScore;
              delete dictOfUsers[userId].countScore;
            }));
          }
          Promise.all(promises).then(() => {
            const arrayOfUsers = [];
            let maxLen = 0;
            let header;
            for ( let userId in dictOfUsers){
              arrayOfUsers.push(dictOfUsers[userId]);
              if (Object.keys(dictOfUsers[userId]).length > maxLen){
                maxLen = Object.keys(dictOfUsers[userId]).length;
                header = Object.keys(dictOfUsers[userId]);
              }
            }
            const items = arrayOfUsers;
            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            header.reverse();
            const tempcsv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
            tempcsv.unshift(header.join(','));
            const content = String.fromCharCode(0xFEFF) + tempcsv.join('\r\n');
            const csvContent = 'data:text/csv;charset=utf-8,'  + content;
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            fileName += ' course status ' + new Date().toLocaleDateString('he-IL') + '.csv';
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.courseStatusDisabled = false;
          });
        });
      });
    });
  }
  deletePrassed(col): void{
    const dialogRef = this.dialog.open(AreUSureDialogComponent, {
      width: '350px',
      data: {
        title: 'האם למחוק את הפעילות?',
        ok: 'מחק'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        const index = this.dataSource.data.findIndex(act => act.activityCode === col.activityCode);
        this.dataSource.data.splice(index, 1);
        this.dataSource.paginator = this.paginator;
        this.activitesService.deleteActivity(col.activityCode);
      }
    });
  }
  finishPrassed(col): void{
    const dialogRef = this.dialog.open(AreUSureDialogComponent, {
      width: '350px',
      data: {
        title: 'האם לסיים את הפעילות?',
        ok: 'סיים'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        const index = this.dataSource.data.findIndex(act => act.activityCode === col.activityCode);
        this.dataSource.data.splice(index, 1);
        this.dataSource.paginator = this.paginator;
        col.endDate = moment(new Date());
        this.activitesService.setActivity(col, false);
      }
    });
  }
  resultPrassed(col): void{
    const activity = (JSON.parse(JSON.stringify(col)));
    const dialogRef = this.dialog.open(ReportsComponent, {
      width: '500px',
      data: activity
    });
  }

  openDialog(data): void {
    const activity = (JSON.parse(JSON.stringify(data)));
    const dialogRef = this.dialog.open(EditRowDialogComponent, {
      width: '500px',
      data: activity
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1){
        const index = this.dataSource.data.findIndex(act => act.activityCode === activity.activityCode);
        activity.startDate = moment(activity.startDate);
        activity.endDate = moment(activity.endDate);
        this.dataSource.data[index] = activity;
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  createActivity(): void{
    this.router.navigateByUrl('/main/plan-activity', { state: { ignoreLoadingBar: true } });
  }

}
