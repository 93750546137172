import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from './../../services/login-service.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import { texts, avaibleLanguages } from './../../../environments/languages';
import { AngularFireAuth } from '@angular/fire/auth';
import { GlobalsService } from '../../globals.service';


@Component({
  selector: 'app-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.css']
})
export class ChangeEmailDialogComponent implements OnInit {

  FormControl
  password:string
  email:string
  hide = true
  isUnderMinLength = false
  rememberMe = true
  language
  translte = texts
  loading = false
  NewEmail:string
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  NewEmailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);
  buttonDisable = true
  constructor(private global:GlobalsService,private auth: AngularFireAuth,private snackBar:MatSnackBar,private LoginService:LoginService,public dialogRef: MatDialogRef<ChangeEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      this.language = this.global.language
     }

  ngOnInit(): void {
  }
  getEmail(val){
    this.email = val
    if(!this.emailFormControl.hasError('email') && !this.emailFormControl.hasError('required') && !this.passwordFormControl.hasError('required')  && !this.passwordFormControl.hasError('minLength') && this.NewEmailFormControl.hasError('email') && !this.NewEmailFormControl.hasError('required') ){
      this.buttonDisable = false
    }
    else{
      this.buttonDisable = true
    }
  }
  getNewEmail(val){
    this.NewEmail = val
    if(!this.emailFormControl.hasError('email') && !this.emailFormControl.hasError('required') && !this.passwordFormControl.hasError('required')  && !this.passwordFormControl.hasError('minLength') && this.NewEmailFormControl.hasError('email') && !this.NewEmailFormControl.hasError('required')){
      this.buttonDisable = false
    }
    else{
      this.buttonDisable = true
    }
  }
  getPassword(val){
    this.password = val
    if(!this.emailFormControl.hasError('email') && !this.emailFormControl.hasError('required') && !this.passwordFormControl.hasError('required') && !this.passwordFormControl.hasError('minLength') && this.NewEmailFormControl.hasError('email') && !this.NewEmailFormControl.hasError('required')){
      this.buttonDisable = false
    }
    else{
      this.buttonDisable = true
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  loginPressed(){
    var success
    this.loading = true;
    this.LoginService.tryToLogin(this.email, this.password).subscribe(
      res => {
        if(res){
          this.auth.authState.subscribe( user => {
            if (user) {     
               user.updateEmail(this.NewEmail).then(()=>{
                this.snackBar.open("Email updated successfully", "", {
                  duration: 2000,
                });  
               }).catch(err=>{this.snackBar.open("Cant update email, try again later "+ err, "", {
                              duration: 2000,
                              
                             });  
              }).finally(()=>{this.onNoClick()})

            }
          });
        }
        else{
          this.loading = false
          this.snackBar.open("Inccorect email or password", "", {
            duration: 2000,
          });  
        }
      }, 
      error => { }
    );
  }
}
