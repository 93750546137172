import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, of ,Observable} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private auth: AngularFireAuth) { }


  tryToLogin(email: string, password: string) : Observable<any>{
    return from(this.auth.signInWithEmailAndPassword(email, password)).pipe(
      map(firebaseUser => {
        // on success
        return true;
      }),
      catchError(error => {
        // on error
        return of(false);      // <-- you need to return an observable from `catchError` - so use `of`
      })
    );
  }
}
