<div dir='rtl'>
    <h1 mat-dialog-title>{{data.name}}</h1>
    <mat-dialog-content>
        <p>{{data.desc}}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button color='primary' (click)='onNoClick()'>סגור</button>
    </mat-dialog-actions>
</div>

