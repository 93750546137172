<div class="home-container-div">
    <div class="div-ellipse-container purple" (click)="createActivity('plan-activity');">
        <div class="ellipse-outside ellipse-center border-purple"></div>           
        <div class="ellipse-inside ellipse-center">
            <div class="shape ellipse-center">               
                <mat-icon svgIcon="icon_task" class="shape-icon"></mat-icon>                   
            </div>
            <span class="shape-span">תכנון פעילות</span>
        </div>
    </div>
    <div class="div-ellipse-container green" (click)="createActivity('statistics');">
        <div class="ellipse-outside ellipse-center border-green" (click)="createActivity('statistics');"></div>            
        <div class="ellipse-inside ellipse-center">
            <div class="shape ellipse-center" style="width: 102px;">               
            <mat-icon svgIcon="icon_control" class="shape-icon" style="width: 102px;"></mat-icon> 
            </div>
            <span class="shape-span">מעקב ובקרה</span>
        </div>
    </div>
</div>