<h1 mat-dialog-title class="center" dir='rtl'>{{header}}</h1>

<mat-dialog-content  dir='rtl' style="overflow-y: scroll">
  <mat-horizontal-stepper #stepper dir='rtl'>
    <!-- enter configuration name -->
    <mat-step>
      <ng-template matStepLabel> הכנס שם להגדרה </ng-template>
      <mat-form-field class="button-row" appearance="outline">
        <mat-label>שם הפריט</mat-label>
        <input matInput placeholder="שם ההגדרה" [formControl]="formControler.get('configName')" >
        <mat-error *ngIf="formControler.get('configName').errors">
          חובה להכניס שם להגדרה      
        </mat-error>
      </mat-form-field>

      <div>
        <button mat-button matStepperNext>הבא</button>
      </div>
    </mat-step>

    <!-- choose image for jigsaw -->
    <mat-step *ngIf='isJigsaw'>
      <ng-template matStepLabel>בחר תמונה</ng-template>
      <div class="center">
        <mat-form-field appearance="outline">
          <mat-label >תמונה</mat-label>
          <mat-select [formControl]="formControler.get('jigsawImage')">
            <mat-option value="Tel aviv">תל אביב</mat-option>
            <mat-option value="Jerusalem">ירושלים</mat-option>
            <mat-option value="Bee">דבורה</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button mat-button matStepperNext>הבא</button>
      </div>
    </mat-step>

    <!-- enter questions for jigsaw -->
    <mat-step *ngIf='isJigsaw'>
      <ng-template matStepLabel>הכנס שאלות</ng-template>
        <mat-accordion *ngFor="let jigsawQuestion of questionsContorlersArr.controls; let i=index">
          <mat-expansion-panel style="margin-top: 20px;">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'שאלה מספר ' + (i+1)}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>שאלה</mat-label>
              <input matInput placeholder="היכן נמצאת העיר ניו יורק"  [formControl]="questionsContorlersArr.at(i).get('question')" maxlength="75">
              <mat-error *ngIf="questionsContorlersArr.at(i).get('question').errors">
                חובה להכניס שאלה        
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
              <mat-label>תשובה נכונה</mat-label>
              <input matInput placeholder="ארה'ב"  [formControl]="questionsContorlersArr.at(i).get('realAnswer')" maxlength="40">
              <mat-error *ngIf="questionsContorlersArr.at(i).get('realAnswer').errors">
                חובה להכניס תשובה נכונה       
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
              <mat-label>תשובה שגויה</mat-label>
              <input matInput placeholder="הממלכה המאוחדת" [formControl]="questionsContorlersArr.at(i).get('fakeAnswer1')" maxlength="40">
              <mat-error *ngIf="questionsContorlersArr.at(i).get('fakeAnswer1').errors">
                חובה להכניס תשובה לא נכונה       
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
              <mat-label>תשובה שגויה</mat-label>
              <input matInput placeholder="סין"  [formControl]="questionsContorlersArr.at(i).get('fakeAnswer2')" maxlength="40">
              <mat-error *ngIf="questionsContorlersArr.at(i).get('fakeAnswer2').errors">
                חובה להכניס תשובה לא נכונה      
              </mat-error>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>
    </mat-step>

    <!-- enter questions for pairs -->
    <mat-step *ngIf='isPairGame'>
      <ng-template matStepLabel>הכנס שאלות</ng-template>
      <mat-accordion *ngFor="let jigsawQuestion of questionsContorlersArr.controls; let i=index">
        <mat-expansion-panel style="margin-top: 20px;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'שאלה מספר ' + (i+1)}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>שאלה</mat-label>
            <input matInput placeholder="היכן נמצאת העיר ניו יורק"  [formControl]="questionsContorlersArr.at(i).get('question')" maxlength="{{isBalls ? 40 : 90}}">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('question').errors">
              חובה להכניס שאלה        
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
            <mat-label>תשובה </mat-label>
            <input matInput placeholder="ארה'ב"  [formControl]="questionsContorlersArr.at(i).get('answer')" maxlength="{{isBalls ? 9 : 70}}">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('answer').errors">
              חובה להכניס תשובה        
            </mat-error>
          </mat-form-field>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-step>

    <!-- enter question for group trivia -->
    <mat-step *ngIf='isGroupTrivia'>
      <ng-template matStepLabel>הכנס שאלות</ng-template>
      
      <mat-accordion *ngFor="let jigsawQuestion of questionsContorlersArr.controls; let i=index">
        <mat-expansion-panel style="margin-top: 20px;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'שאלה מספר ' + (i+1)}}
            </mat-panel-title>
            <mat-panel-description class="right-aligned" *ngIf='questionsContorlersArr.length > gameMinQuestions'>
              <button style="left: 20px;" mat-icon-button (click)='removeQuestion(i)'>
                <mat-icon>delete</mat-icon>
              </button>
            </mat-panel-description>

          </mat-expansion-panel-header>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>שאלה</mat-label>
            <input matInput placeholder="היכן נמצאת העיר ניו יורק"  [formControl]="questionsContorlersArr.at(i).get('question')" maxlength="70">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('question').errors">
              חובה להכניס שאלה        
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
            <mat-label>תשובה נכונה</mat-label>
            <input matInput placeholder="ארה'ב"  [formControl]="questionsContorlersArr.at(i).get('realAnswer')"  maxlength="55">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('realAnswer').errors">
              חובה להכניס תשובה נכונה       
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
            <mat-label>תשובה שגויה</mat-label>
            <input matInput placeholder="הממלכה המאוחדת" [formControl]="questionsContorlersArr.at(i).get('fakeAnswer1')"  maxlength="55">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('fakeAnswer1').errors">
              חובה להכניס תשובה לא נכונה       
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
            <mat-label>תשובה שגויה</mat-label>
            <input matInput placeholder="סין"  [formControl]="questionsContorlersArr.at(i).get('fakeAnswer2')" maxlength="55">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('fakeAnswer2').errors">
              חובה להכניס תשובה לא נכונה      
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" style="margin-top: 2vh !important;" appearance="outline">
            <mat-label>תשובה שגויה</mat-label>
            <input matInput placeholder="סין"  [formControl]="questionsContorlersArr.at(i).get('fakeAnswer3')" maxlength="55">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('fakeAnswer2').errors">
              חובה להכניס תשובה לא נכונה      
            </mat-error>
          </mat-form-field>
          <div class="center">
            <input style="display: none" type="file" accept=".jpg,.png" (change)="onFileChanged($event,i)" #fileInput>
            <button mat-raised-button color="primary" [disabled]='imageLoading' (click)="fileInput.click()">בחר תמונה<mat-progress-bar *ngIf='imageLoading' mode="indeterminate"></mat-progress-bar></button>
          </div>
          <div class='delete-image-trivia-container'>
            <img style="margin-top: 1vh; width:10%;"  [src]="questionsContorlersArr.at(i).get('img').value">
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <button mat-icon-button (click)='addQuestion()'>
        <mat-icon>add</mat-icon>
      </button>
    </mat-step>

    <!-- enter startTime for numbers -->
    <mat-step *ngIf='isNumbers'>
      <ng-template matStepLabel>זמן התחלה</ng-template>
      <mat-form-field class="button-row" appearance="outline">
        <mat-label>דקות לתחילת המשחק</mat-label>
        <input matInput type="number" placeholder="60" [formControl]="formControler.get('timeToStart')" min="0">
        <span matSuffix>דקות</span>
      </mat-form-field>
    </mat-step>

    <!-- enter questions for true false game  -->
    <mat-step *ngIf='isTrueFalse'>
      <mat-accordion *ngFor="let jigsawQuestion of questionsContorlersArr.controls; let i=index">
        <mat-expansion-panel style="margin-top: 20px;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'שאלה מספר ' + (i+1)}}
            </mat-panel-title>
            <mat-panel-description class="right-aligned" *ngIf='questionsContorlersArr.length > gameMinQuestions'>
              <button style="left: 20px;" mat-icon-button (click)='removeQuestion(i)'>
                <mat-icon>delete</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matStepLabel>הכנס שאלות</ng-template>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>שאלה</mat-label>
            <input matInput placeholder="Where is New York" [formControl]="questionsContorlersArr.at(i).get('question')"  maxlength="90">
            <mat-error *ngIf="questionsContorlersArr.at(i).get('question').errors">
              חובה להכניס שאלה        
            </mat-error>
          </mat-form-field>
          <div class="full-width" style="margin-top: 2vh !important;" appearance="outline">
            <mat-label></mat-label>
            <mat-radio-group id="rdConfigurationGame" [formControl]="questionsContorlersArr.at(i).get('answer')">
              <mat-radio-button value="true">נכון</mat-radio-button>
              <mat-radio-button value="false">לא נכון</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="questionsContorlersArr.at(i).get('answer').errors">
              חובה לבחור תשובה        
            </mat-error>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <button mat-icon-button (click)='addQuestion()'>
        <mat-icon>add</mat-icon>
      </button>

    </mat-step>
  </mat-horizontal-stepper>
  <div class="center" mat-dialog-actions>
    <button mat-flat-button color="primary" (click)='saveConfig()' [disabled]='disableSave || imageLoading'>שמור<mat-progress-bar *ngIf='disableSave || imageLoading' mode="indeterminate"></mat-progress-bar></button>
    <button mat-button (click)="onNoClick()" >ביטול</button>
  </div>
</mat-dialog-content>



