<h1 mat-dialog-title class="center" dir='rtl'>{{header}}</h1>

<mat-dialog-content  dir='rtl' style="overflow-y: hidden">
    <mat-form-field>
        <mat-label>שם הלקוח</mat-label>
        <input  matInput [(ngModel)]='name' required>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button  mat-button (click)="onNoClick()">ביטול</button>
    <button [disabled]='saveDisabled' mat-flat-button color="primary" (click)="onSaveClick()">שמור<mat-progress-bar class='progress2' mode="indeterminate" *ngIf='saveDisabled'></mat-progress-bar>
    </button>
</mat-dialog-actions>