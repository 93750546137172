

<div id="previewTable" class="mat-elevation-z8 table-wrapper">

    <table mat-table [dataSource]="dataSource" matSort style="margin-top: 2vh;">
      <ng-container  matColumnDef="activityCode">
        <th  mat-header-cell *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;">קוד הפעילות</th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
          {{element.activityCode}}
        </td>
      </ng-container>

      <ng-container matColumnDef="activityName" >
        <th mat-header-cell  *matHeaderCellDef style="text-align: center;padding-inline-end: 8px !important">שם הפעילות</th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important; ; width:200px">
            {{element.activityName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="activityType" >
        <th mat-header-cell  *matHeaderCellDef style="text-align: center;padding-inline-end: 8px !important;">סוג הפעילות</th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
            {{element.activityType === 'course'  ? 'קורס' : 'משחקים'}}
        </td>
      </ng-container>
  
      <ng-container  matColumnDef="startDate">
        <th mat-header-cell  *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;">תאריך התחלה</th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
          {{element.startDate.locale('he').format('MMMM Do YYYY, h:mm:ss a')}}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="endDate">
        <th mat-header-cell  *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;">תאריך סיום</th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
          {{element.endDate.locale('he').format('MMMM Do YYYY, h:mm:ss a')}}
        </td>
      </ng-container>

      <ng-container  matColumnDef="status">
        <th mat-header-cell  *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;">סטטוס</th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
          {{element.endDate.toDate() < now ? "הסתיים" : element.startDate.toDate() < now ? "פעיל" : "עוד לא התחיל" }}
        </td>
      </ng-container>

      <ng-container  matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="text-align: right;padding-inline-end: 8px !important; min-width: 260px;"> 
          <div class="buttons-wrapper-div">
            <button matTooltip="צפייה בפעילות" *ngIf="element.startDate.toDate() < now" mat-button (click)="editPrassed(element)">
              <mat-icon aria-hidden="false" aria-label="Example home icon" class="button-icon" style="margin-top: 11px;" svgIcon="icon_preview"></mat-icon>
            </button>
            <button matTooltip="עריכת פעילות" *ngIf="element.startDate.toDate() > now" mat-button (click)="editPrassed(element)">
              <mat-icon aria-hidden="false" aria-label="Example home icon" >edit</mat-icon>
            </button>         
            <button matTooltip="שיתוף פעילות" mat-button (click)="sharePrassed(element)">
              <mat-icon aria-hidden="false" aria-label="Example home icon" class="button-icon" svgIcon="icon_property"></mat-icon>
            </button>
            <button matTooltip="סיום פעילות" *ngIf="element.startDate.toDate() < now"  mat-button (click)="finishPrassed(element)">
              <mat-icon aria-hidden="false" aria-label="Example home icon" class="button-icon" svgIcon="icon_switch_on_off"></mat-icon>
            </button>
            <button matTooltip="מחיקת פעילות" *ngIf="element.startDate.toDate() > now" mat-button (click)="deletePrassed(element)">
              <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
            </button>
            <button matTooltip="הורדת דוחות" *ngIf="element.startDate.toDate() < now && element.activityType === 'course'"  mat-button (click)="downloadCurseStatus(element)">
              <mat-icon aria-hidden="false" aria-label="Example home icon">description</mat-icon>
            </button>
         </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons ></mat-paginator>
    <button style="margin: 0.5em;" mat-button color="primary" class="table-bottom-add-button"  (click)='createActivity()'>
      <mat-icon>add</mat-icon>
      {{createActivityText}}    
    </button>
</div>
