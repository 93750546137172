import { CreateSubcustomerComponent } from './../create-subcustomer/create-subcustomer.component';
import { CreateLabelComponent } from './../create-label/create-label.component';

// tslint:disable: max-line-length

import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Activity } from '../../classes/activity';
import {FormControl, Validators, FormGroup} from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ActivitesService } from '../../services/activites.service';
import * as moment from 'moment';
import 'moment/locale/he';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { CreateComponentConfigurationComponent } from '../create-component-configuration/create-component-configuration.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface GameConf {
  name: string;
  id: string;
}
export interface GameConfs {
  gameId: string;
  gameName: string;
  confs: GameConf[];
  isConfigurability: boolean;
}
export interface MyComponent {
  cid: string;
}
export interface ComponentConf {
  name: string;
  id: string;
}
export interface ComponentConfs {
  id: string;
  name: string;
  confs: ComponentConf[];
}
export interface ChosenGame {
  id: string;
}
export interface label{
  id: string;
  name: string;
}
export interface subcustomer{
  id: string;
  name: string;
}
@Component({
  selector: 'app-edit-row-dialog',
  templateUrl: './edit-row-dialog.component.html',
  styleUrls: ['./edit-row-dialog.component.scss']
})
export class EditRowDialogComponent implements OnInit {

  dialogTitle = 'עריכת פעילות';
  formControls: FormGroup = new FormGroup({activityType: new FormControl('', Validators.required), contactPosition: new FormControl('', Validators.required), name: new FormControl('', Validators.required), startDate: new FormControl('', Validators.required), endDate: new FormControl('', Validators.required), loginType: new FormControl('', Validators.required), isNameRequired: new FormControl(''), contactName: new FormControl('', Validators.required), contactPhone: new FormControl('', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]), contactMail: new FormControl('', [Validators.required, Validators.email]), label: new FormControl('', []), subcustomer: new FormControl('', []), haveLeaderBoard: new FormControl('', [])});
  gamesControls: FormGroup = new FormGroup({});
  componentsControls: FormGroup = new FormGroup({});
  gamesConfs: GameConfs[] = [];
  componentsConfs: ComponentConfs[] = [];
  components: Array<MyComponent> = [];
  fileToUpload: File = null;
  uploadProgress = false;
  saveProgress = false;
  buttonsDisabled = false;
  editable = false;
  typeNotAvailable = false;
  posibleLeaderBoard = false;
  labels: label[] = [];
  subcustomers: subcustomer[] = [];
  gamesChose: Array<ChosenGame> = [];
  slidersRefresh: EventEmitter<void> = new EventEmitter<void>();
  ngOnInit(): void {
  }
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditRowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Activity,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private snackBar: MatSnackBar,
    private activitesService: ActivitesService) {
      dialogRef.disableClose = true;
      this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('layouts').doc(data.layoutId).get().toPromise().then( layout => {
        this.formControls.controls.activityType.setValue(layout.data().type);
        if (layout.data().type === 'course'){
          this.posibleLeaderBoard = true;
        }
        this.formControls.controls.activityType.disable();
      });
      moment.locale('he');
      if (data.startDate == null || moment(data.startDate).toDate() > new Date()){
        this.editable = true;
      }
      else{
        this.dialogTitle = 'צפייה בפעילות';
        this.formControls.controls.name.disable();
        this.formControls.controls.startDate.disable();
        this.formControls.controls.endDate.disable();
        this.formControls.controls.contactName.disable();
        this.formControls.controls.contactPhone.disable();
        this.formControls.controls.contactMail.disable();
        this.formControls.controls.contactPosition.disable();
        this.formControls.controls.activityType.disable();
        this.formControls.controls.label.disable();
        this.formControls.controls.subcustomer.disable();
        this.formControls.controls.haveLeaderBoard.disable();
      }
      if (this.data.activityName === ''){
        this.dialogTitle = 'יצירת פעילות';
      }
      if (this.data.startGroup == null){
        this.data.startGroup = 1;
      }
      if (this.data.endGroup == null){
        this.data.endGroup = 10;
      }
      this.formControls.get('name').setValue(data.activityName);
      this.formControls.get('startDate').setValue(data.startDate);
      this.formControls.get('endDate').setValue(data.endDate);
      this.formControls.get('loginType').setValue(data.loginType);
      this.formControls.get('isNameRequired').setValue(data.isNameRequired);
      this.formControls.controls.contactName.setValue(data.contactName);
      this.formControls.controls.contactPhone.setValue(data.contactPhone);
      this.formControls.controls.contactMail.setValue(data.contactMail);
      this.formControls.controls.contactPosition.setValue(data.contactPosition);
      this.formControls.controls.label.setValue(data.label);
      this.formControls.controls.subcustomer.setValue(data.subcustomer);
      this.formControls.controls.haveLeaderBoard.setValue(data.haveLeaderBoard);

      if (data.activityType != null){
        this.formControls.controls.activityType.setValue(data.activityType);
      }
      firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('layouts').doc(data.layoutId).get().toPromise().then( doc => {
        const promises = [];
        doc.data().games.forEach(gameId => {
          promises.push(firestore.collection('games').doc(gameId).get().toPromise().then( gameDoc => {
            return firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('gamesConfigurationSets', ref => ref.where('gameId', '==', gameId)).get().toPromise().then(confs => {
              const gameConfs: GameConf[] = [];
              confs.forEach( confDoc => {
                if (confDoc.data().isCopy !== true){
                  gameConfs.push({name: confDoc.data().name, id: confDoc.id});
                }
                if (confDoc.id === this.data.gamesConfiguritions[confDoc.data().gameId] && confDoc.data().isCopy === true){
                  data.gamesConfiguritions[gameDoc.id] = confDoc.data().originalConfig;
                }
              });
              this.gamesConfs.push({gameId: gameDoc.id, gameName: gameDoc.data().name, confs: gameConfs, isConfigurability: gameDoc.data().isConfigurability ? gameDoc.data().isConfigurability : false});
            });
          }));
        });
        Promise.all(promises).then(() => {
          this.data.gamesConfiguritions.forEach(game => {
            const id = guidGenerator();
            this.gamesControls.addControl(id + 'type', new FormControl('', Validators.required));
            this.gamesControls.addControl(id + 'conf', new FormControl('', Validators.required));
            this.gamesChose.push({id});
            this.gamesControls.get(id + 'type').setValue(this.gamesConfs.find(x => x.gameId === game.gameId));
            this.gamesControls.get(id + 'conf').setValue(game.configId);
          });
          if (this.gamesChose.length === 0){
            this.addGame();
          }
        });
      });
      this.getLabels();
      this.getSubcustomers();
      firestore.collection('components').get().toPromise().then(components => {
        const promises = [];
        components.docs.forEach(componentDoc => {
          promises.push(firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('componentsConfigurationSets', ref => ref.where('componentId', '==', componentDoc.id)).get().toPromise().then(confs => {
            const componentConfs: GameConf[] = [];
            confs.forEach( confDoc => {
              if (confDoc.data().isCopy !== true){
                componentConfs.push({name: confDoc.data().name, id: confDoc.id});
              }
              if (confDoc.id === this.data.componentsConfiguritions[componentDoc.id] && confDoc.data().isCopy === true){
                data.componentsConfiguritions[componentDoc.id] = confDoc.data().originalConfig;
              }
            });
            this.componentsConfs.push({id: componentDoc.id, name: componentDoc.data().name['he'], confs: componentConfs});
          }));
        });
        Promise.all(promises).then(() => {
          this.data.componentsConfiguritions.forEach(component => {
            const cid = guidGenerator();
            this.componentsControls.addControl(cid + 'type', new FormControl('', Validators.required));
            this.componentsControls.addControl(cid + 'conf', new FormControl('', Validators.required));
            this.components.push({cid});
            this.componentsControls.get(cid + 'type').setValue(this.componentsConfs.find(x => x.id === component.componentId));
            this.componentsControls.get(cid + 'conf').setValue(component.configId);
          });
          if (this.components.length === 0){
            this.addComponent();
          }
        });
      });
    }

  getLabels(): void {
    this.labels = [];
    this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('labels').get().toPromise().then(labelsSnapShot => {
      labelsSnapShot.docs.forEach(labelDoc => {
        const label: label = {id: labelDoc.id, name: labelDoc.data().name};
        this.labels.push(label);
      });
    });
  }
  createNewLabel(): void{
    const dialogRef = this.dialog.open(CreateLabelComponent, {
      width: 'auto',
      data: null,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getLabels();
    });
  }

  getSubcustomers(): void {
    this.subcustomers = [];
    this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('subcustomers').get().toPromise().then(subcustomersSnapShot => {
      subcustomersSnapShot.docs.forEach(subcustomerDoc => {
        const subcustomer: subcustomer = {id: subcustomerDoc.id, name: subcustomerDoc.data().name};
        this.subcustomers.push(subcustomer);
      });
    });
  }
  createNewSubcustomers(): void{
    const dialogRef = this.dialog.open(CreateSubcustomerComponent, {
      width: 'auto',
      data: null,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSubcustomers();
    });
  }
  onNoClick(): void {
    this.dialogRef.close(0);
  }
  onSaveClick(): void {
    this.buttonsDisabled = true;
    this.saveProgress = true;
    let done = true;
    this.formControls.markAllAsTouched();
    this.gamesControls.markAllAsTouched();
    this.componentsControls.markAllAsTouched();

    if (!this.formControls.valid){
      done = false;
      this.snackBar.open('חסרים פרטים. נא להשלים', '', {duration: 5000, direction: 'rtl'});
    }
    else{
      if (this.formControls.get('activityType').value === 'games' && !this.gamesControls.valid){
        done = false;
        this.snackBar.open('חסרים פרטים. נא להשלים', '', {duration: 5000, direction: 'rtl'});
      }
      else if (this.formControls.get('activityType').value === 'course' && !this.componentsControls.valid){
        done = false;
        this.snackBar.open('חסרים פרטים. נא להשלים', '', {duration: 5000, direction: 'rtl'});
      }
      else if (this.formControls.get('activityType').value === ''){
        done = false;
        this.snackBar.open('חסרים פרטים. נא להשלים', '', {duration: 5000, direction: 'rtl'});
      }
    }
    if (done){
      this.data.activityName = this.formControls.get('name').value;
      this.data.startDate = this.formControls.get('startDate').value;
      this.data.endDate = this.formControls.get('endDate').value;
      this.data.loginType = this.formControls.get('loginType').value;
      this.data.contactMail = this.formControls.get('contactMail').value;
      this.data.contactName = this.formControls.get('contactName').value;
      this.data.contactPhone = this.formControls.get('contactPhone').value;
      this.data.contactPosition = this.formControls.get('contactPosition').value;
      //this.data.isNameRequired = this.formControls.get('isNameRequired').value;
      this.data.isNameRequired = true;
      this.data.activityType = this.formControls.get('activityType').value;
      this.data.label = this.formControls.get('label').value;
      this.data.subcustomer = this.formControls.get('subcustomer').value;
      this.data.haveLeaderBoard = this.formControls.get('haveLeaderBoard').value;
      this.data.componentsConfiguritions = [];
      this.data.gamesConfiguritions = [];
      if (this.data.startDate > this.data.endDate){
        this.buttonsDisabled = false;
        this.saveProgress = false;
      }
      if (this.formControls.get('activityType').value === 'games'){
        this.gamesChose.forEach( gameChose => {
          this.data.gamesConfiguritions.push({gameId: this.gamesControls.get(gameChose.id + 'type').value.gameId, configId: this.gamesControls.get(gameChose.id + 'conf') ? this.gamesControls.get(gameChose.id + 'conf').value : 'null'});
        });
      }
      else if (this.formControls.get('activityType').value === 'course'){
        this.components.forEach( component => {
          this.data.componentsConfiguritions.push({componentId: this.componentsControls.get(component.cid + 'type').value.id, configId: this.componentsControls.get(component.cid + 'conf').value });
        });
      }
      this.data.startDate = moment(this.data.startDate);
      this.data.endDate = moment(this.data.endDate);
      if (this.data.loginType === 'groups'){
        this.data.isNameRequired = true;
      }
      if (this.data.loginType === 'solos'){
        this.data.isNameRequired = true;
      }
      if (this.data.startDate.toDate() > this.data.endDate.toDate() || this.data.endDate.toDate() < new Date()){
        this.buttonsDisabled = false;
        this.saveProgress = false;
      }
      else{
        this.activitesService.setActivity(this.data, false).then(() => {
          this.dialogRef.close(1);
        });
      }
    }
    else{
      this.buttonsDisabled = false;
      this.saveProgress = false;
    }
  }
  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }
  uploadLogo(imageInput): void {
    this.buttonsDisabled = true;
    if (imageInput.files[0]) {
      const file: File = imageInput.files[0];
      const pattern = /image-*/;

      if (!file.type.match(pattern)) {
        alert('Only image format allowed!');
        this.buttonsDisabled = false;
        return;
      }
      else{
        const filePath = 'activity-logos/' + this.data.activityCode;
        const ref = this.storage.ref(filePath);
        this.uploadProgress = true;
        const task = ref.put(file);

        // get notified when the download URL is available
        task.snapshotChanges().pipe(
          finalize(() => {
            ref.getDownloadURL().toPromise().then( url => {
              this.data.activityLogo = url;
              this.uploadProgress = false;
              this.buttonsDisabled = false;
            });
          })
        )
        .subscribe();
      }

      // here you can do whatever you want with your image. Now you are sure that it is an image
    }
    else{
      this.buttonsDisabled = false;
    }
  }
  addComponent(): void{
    const cid = guidGenerator();
    this.componentsControls.addControl(cid + 'type', new FormControl('', Validators.required));
    this.componentsControls.addControl(cid + 'conf', new FormControl('', Validators.required));
    this.components.push({cid});
  }
  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.components, event.previousIndex, event.currentIndex);
  }
  removeComponent(index: number): void{
    this.componentsControls.removeControl(this.components[index].cid + 'type');
    this.componentsControls.removeControl(this.components[index].cid + 'conf');
    this.components.splice(index, 1);
  }
  componentTypeSelected(component: MyComponent): void{
    this.componentsControls.get(component.cid + 'conf').setValue(null);
  }
  createConfig(component: MyComponent): void{
    const dialogRef = this.dialog.open(CreateComponentConfigurationComponent, {
      width: 'auto',
      data: {
        createConfig: true,
        componentId: this.componentsControls.get(component.cid + 'type').value.id,
        editable: true
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.created){
        this.componentsControls.get(component.cid + 'type').value.confs.push({id: result.confId, name: result.confName});
        this.componentsControls.get(component.cid + 'conf').setValue(result.confId);
      }
    });
  }
  editConfig(component: MyComponent): void{
    this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('componentsConfigurationSets').doc(this.componentsControls.get(component.cid + 'conf').value).get().toPromise().then(configDoc => {
      const dialogRef = this.dialog.open(CreateComponentConfigurationComponent, {
        width: 'auto',
        data: {
          configId: this.componentsControls.get(component.cid + 'conf').value,
          componentId: configDoc.data().componentId,
          configurations: configDoc.data().configurations, name: configDoc.data().name,
          createConfig: false,
          editable: true},
      });
    });
  }
  addGame(): void{
    const id = guidGenerator();
    this.gamesControls.addControl(id + 'type', new FormControl('', Validators.required));
    this.gamesControls.addControl(id + 'conf', new FormControl('', Validators.required));
    this.gamesChose.push({id});
  }
  editGameConfig(component: MyComponent): void{
    // this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('componentsConfigurationSets').doc(this.componentsControls.get(component.cid + 'conf').value).get().toPromise().then(configDoc => {
    //   const dialogRef = this.dialog.open(CreateComponentConfigurationComponent, {
    //     width: 'auto',
    //     data: {configId: this.componentsControls.get(component.cid + 'conf').value, componentId: configDoc.data().componentId, configurations: configDoc.data().configurations, name: configDoc.data().name, createConfig: false},
    //   });
    // });
  }
  removeGame(index: number): void{
    this.gamesControls.removeControl(this.gamesChose[index].id + 'type');
    this.gamesControls.removeControl(this.gamesChose[index].id + 'conf');
    this.gamesChose.splice(index, 1);
  }
  gameTypeSelected(gameChose: ChosenGame): void{
    if (!this.gamesControls.get(gameChose.id + 'type').value.isConfigurability){
      this.gamesControls.removeControl(gameChose.id + 'conf');
    }
    else{
      this.gamesControls.addControl(gameChose.id + 'conf', new FormControl('', Validators.required));
    }
  }
  createGameConfig(component: MyComponent): void{
    // const dialogRef = this.dialog.open(CreateComponentConfigurationComponent, {
    //   width: 'auto',
    //   data: {createConfig: true, componentId: this.componentsControls.get(component.cid + 'type').value.id},
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.created){
    //     this.componentsControls.get(component.cid + 'type').value.confs.push({id: result.confId, name: result.confName});
    //     this.componentsControls.get(component.cid + 'conf').setValue(result.confId);
    //   }
    // });
  }

}

function guidGenerator() {
  var S4 = function() {
     return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}
