<h1 mat-dialog-title class="center">{{dialogTitle}}</h1>
<div dir="rtl" mat-dialog-content >
  <div class="form-field" >
    <img class='logo center' [src]='data.activityLogo'>
    <div class="form-group">
      <input style="width: 0px; height: 0px;" type="file" (change)="uploadLogo(file)" accept="image/*" #file>
      <button [disabled]='buttonsDisabled || !editable'  mat-button color="primary" class="center" (click)="file.click(uploadProgress)">בחר לוגו</button>
      <mat-progress-bar class='progress' mode="indeterminate" [ngStyle]="{'visibility':uploadProgress==true ? 'visible' : 'hidden'}"></mat-progress-bar>
    </div>
  </div>

  <mat-form-field class="form-field">
    <mat-label>שם הפעילות</mat-label>
    <input  matInput [formControl]="formControls.get('name')" required maxlength="15">
    <mat-error *ngIf="formControls.get('name').hasError('required')">חייב להכניס שם.</mat-error>
  </mat-form-field>
  <mat-form-field  class="form-field">
    <mat-placeholder>תאריך התחלה</mat-placeholder>
    <mat-datetimepicker-toggle [for]="startdatetimePicker" matSuffix></mat-datetimepicker-toggle>
    <mat-datetimepicker
      #startdatetimePicker
      type="datetime"
      openOnFocus="true"
      timeInterval="1">
    </mat-datetimepicker>
    <input
      matInput
      [matDatetimepicker]="startdatetimePicker"
      required
      autocomplete="false" [formControl]="formControls.get('startDate')">
      <mat-error *ngIf="formControls.get('startDate').hasError('required')">חייב להכניס תאריך התחלה.</mat-error>
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-placeholder>תאריך סיום</mat-placeholder>
    <mat-datetimepicker-toggle [for]="enddatetimePicker" matSuffix></mat-datetimepicker-toggle>
    <mat-datetimepicker
      #enddatetimePicker
      type="datetime"
      openOnFocus="true"
      timeInterval="1">
    </mat-datetimepicker>
    <input
      matInput
      [matDatetimepicker]="enddatetimePicker"
      required
      autocomplete="false"
      [formControl]="formControls.get('endDate')">
      <mat-error *ngIf="formControls.get('endDate').hasError('required')">חייב להכניס תאריך סיום.</mat-error>
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-label>תגית</mat-label>
    <mat-select  [formControl]="formControls.get('label')">
      <mat-option *ngFor="let label of labels" [value]="label.id">
        {{label.name}}
      </mat-option>
      <mat-option (click)="createNewLabel()" class="noselect">
        <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
        צור תגית חדשה
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="form-field">
    <mat-label>לקוח</mat-label>
    <mat-select  [formControl]="formControls.get('subcustomer')">
      <mat-option *ngFor="let label of subcustomers" [value]="label.id">
        {{label.name}}
      </mat-option>
      <mat-option (click)="createNewSubcustomers()" class="noselect">
        <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
        צור לקוח חדש
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-slide-toggle class="form-field" *ngIf="posibleLeaderBoard" [formControl]="formControls.get('haveLeaderBoard')">האם לוח התוצאות זמין</mat-slide-toggle>

  <mat-form-field class="form-field">
    <mat-label>סוג הפעילות</mat-label>
    <mat-select [disabled]="!editable || typeNotAvailable" [formControl]="formControls.get('activityType')" required>
      <mat-option value="games">משחקים</mat-option>
      <mat-option value="course">קורס</mat-option>
    </mat-select>
    <mat-error *ngIf="formControls.get('activityType').hasError('required')">חייב לבחור סוג פעילות.</mat-error>
  </mat-form-field>
  <mat-expansion-panel expanded='false' style="margin-bottom: 1.25em;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        פרטי איש קשר
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field class="form-field">
      <mat-label>שם מלא</mat-label>
      <input matInput [formControl]="formControls.get('contactName')" required>
      <mat-error *ngIf="formControls.get('contactName').hasError('required')">חייב להכניס שם.</mat-error>
    </mat-form-field>
    <mat-form-field class="form-field">
      <mat-label>תפקיד</mat-label>
      <input matInput [formControl]="formControls.get('contactPosition')" required>
      <mat-error *ngIf="formControls.get('contactPosition').hasError('required')">חייב להכניס תפקיד.</mat-error>
    </mat-form-field>
    <mat-form-field class="form-field">
      <mat-label>טלפון נייד</mat-label>
      <input matInput [formControl]="formControls.get('contactPhone')" required>
      <mat-error *ngIf="formControls.get('contactPhone').hasError('required') || formControls.get('contactPhone').hasError('pattern')">חייב להכניס טלפון.</mat-error>
    </mat-form-field>
    <mat-form-field class="form-field">
      <mat-label>מייל</mat-label>
      <input matInput [formControl]="formControls.get('contactMail')" required>
      <mat-error *ngIf="formControls.get('contactMail').hasError('required') || formControls.get('contactMail').hasError('email')">חייב להכניס מייל.</mat-error>
    </mat-form-field>
  </mat-expansion-panel>
  <mat-expansion-panel expanded='false' style="margin-bottom: 1.25em;">
    <mat-expansion-panel-header>
      <mat-panel-title>
        הגדרות התחברות
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-form-field class="form-field">
      <mat-label>סוג התחברות</mat-label>
      <mat-select [disabled]="!editable" [formControl]="formControls.get('loginType')" required>
        <mat-option value="solos">אישי</mat-option>
        <mat-option value="groups">קבוצתי</mat-option>
        <mat-option value="hierarchy">מבנה החברה</mat-option>
      </mat-select>
      <mat-error *ngIf="formControls.get('loginType').hasError('required')">חייב לבחור סוג התחברות.</mat-error>
    </mat-form-field>
    <span *ngIf="formControls.get('loginType').value==='groups'" class='center'>טווח קבוצות</span>
    <div dir="ltr" *ngIf="formControls.get('loginType').value==='groups'" class="form-field range-control range">
      <ng-container matColumnDef="from">
        <mat-cell
          class="range-control">
          <mat-form-field>
            <input [disabled]="!editable" readonly matInput [(ngModel)]="data.startGroup" type="number">
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="range">
        <mat-cell
          class="range-control range">
          <ng5-slider
            [(value)]="data.startGroup"
            [(highValue)]="data.endGroup"
            [options]="{
              floor: 0,
              ceil: 20,
              disabled: !editable,
              hideLimitLabels: true,
              hidePointerLabels: true
            }"
            [manualRefresh]="slidersRefresh"
          ></ng5-slider>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="to">
        <mat-cell
          class="range-control"
          >
          <mat-form-field>
            <input [disabled]="!editable" readonly matInput [(ngModel)]="data.endGroup"  type="number" >
          </mat-form-field>
        </mat-cell>
      </ng-container>
      </div>

    <mat-checkbox [disabled]="!editable" *ngIf="formControls.get('loginType').value==='hierarchy' && false" [formControl]="formControls.get('isNameRequired')" >חובה להכניס שם</mat-checkbox>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="formControls.get('activityType').value==='games'" expanded='false' style="margin-bottom: 1.25em;" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        משחקים
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-accordion cdkDropList>
      <div [cdkDragDisabled]='true' class="example-box" *ngFor="let gameChose of gamesChose; let i = index" cdkDrag>
        <mat-expansion-panel style="background: primary!important;width: 100%;">
          <mat-expansion-panel-header>
            <button *ngIf='gamesChose.length !== 1 && editable' (click)='removeGame(i)' mat-icon-button aria-label="Example icon button with a vertical three dot icon">
              <mat-icon>delete</mat-icon>
            </button>
            <mat-panel-title class="noselect">
            {{gamesControls.get(gameChose.id + 'type').value ? gamesControls.get(gameChose.id + 'type').value.gameName['he'] : 'בחר משחק'}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field class="noselect" class="form-field">
            <mat-label class="noselect">בחר משחק</mat-label>
            <mat-select (selectionChange)="gameTypeSelected(gameChose)" class="noselect" [disabled]="!editable" [formControl]="gamesControls.get(gameChose.id + 'type')" required>
              <mat-option class="noselect" *ngFor="let game of gamesConfs" [value]="game">
                {{game.gameName['he']}}
              </mat-option>
            </mat-select>
            <mat-error class="noselect" *ngIf="gamesControls.get(gameChose.id + 'type').hasError('required')">חייב לבחור משחק.</mat-error>
          </mat-form-field>
          <div style="display: flex;">
            <mat-form-field class="noselect" *ngIf="gamesControls.get(gameChose.id + 'type').valid && gamesControls.get(gameChose.id + 'type').value.isConfigurability" class="form-field">
              <mat-label class="noselect">הגדרה למשחק</mat-label>
              <mat-select class="noselect" [disabled]="!editable" [formControl]="gamesControls.get(gameChose.id + 'conf')" required>
                <mat-option class="noselect" *ngFor="let gameConf of gamesControls.get(gameChose.id + 'type').value.confs" [value]="gameConf.id">
                  {{gameConf.name}}
                </mat-option>
                <mat-option *ngIf='false' (click)='createGameConfig(gameChose)' class="noselect">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
                  יצירת הגדרה
                </mat-option>
              </mat-select>
              <mat-error class="noselect" *ngIf="gamesControls.get(gameChose.id + 'conf').hasError('required')">חייב לבחור הגדרה למשחק.</mat-error>
            </mat-form-field>
            <button *ngIf="false && gamesControls.get(gameChose.id + 'conf') && gamesControls.get(gameChose.id + 'conf').valid && gamesControls.get(gameChose.id + 'conf').value != 'null'" (click)='editGameConfig(gameChose)' mat-icon-button aria-label="Example icon button with a vertical three dot icon">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
    <button [disabled]='!editable || gamesChose.length>=9' color='primary' mat-raised-button (click)='addGame()' style="width:80%;margin: auto;position: relative;display: block;">הוספת משחק</button>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="formControls.get('activityType').value==='course'"expanded='false' style="margin-bottom: 1.25em;" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        תחנות
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
      <div [cdkDragDisabled]='!editable' class="example-box" *ngFor="let component of components; let i = index" cdkDrag>
        <mat-expansion-panel style="background: primary!important;width: 100%;">
          <mat-expansion-panel-header>
            <button *ngIf='components.length !== 1 && editable' (click)='removeComponent(i)' mat-icon-button aria-label="Example icon button with a vertical three dot icon">
              <mat-icon>delete</mat-icon>
            </button>
            <mat-panel-title class="noselect">
            {{componentsControls.get(component.cid + 'type').value ? 'תחנה מסוג ' + componentsControls.get(component.cid + 'type').value.name : 'תחנה ריקה'}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field class="noselect" class="form-field">
            <mat-label class="noselect">סוג התחנה</mat-label>
            <mat-select (selectionChange)="componentTypeSelected(component)" class="noselect" [disabled]="!editable" [formControl]="componentsControls.get(component.cid + 'type')" required>
              <mat-option class="noselect" *ngFor="let componentConfs of componentsConfs" [value]="componentConfs">
                {{componentConfs.name}}
              </mat-option>
            </mat-select>
            <mat-error class="noselect" *ngIf="componentsControls.get(component.cid + 'type').hasError('required')">חייב לבחור סוג לתחנה.</mat-error>
          </mat-form-field>
          <div style="display: flex;">
            <mat-form-field class="noselect" *ngIf="componentsControls.get(component.cid + 'type').valid" class="form-field">
              <mat-label class="noselect">הגדרה לתחנה</mat-label>
              <mat-select class="noselect" [disabled]="!editable" [formControl]="componentsControls.get(component.cid + 'conf')" required>
                <mat-option class="noselect" *ngFor="let componentConf of componentsControls.get(component.cid + 'type').value.confs" [value]="componentConf.id">
                  {{componentConf.name}}
                </mat-option>
                <mat-option (click)='createConfig(component)' class="noselect">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
                  יצירת הגדרה
                </mat-option>
              </mat-select>
              <mat-error class="noselect" *ngIf="componentsControls.get(component.cid + 'conf').hasError('required')">חייב לבחור הגדרה לתחנה.</mat-error>
            </mat-form-field>
            <button  *ngIf="componentsControls.get(component.cid + 'conf').valid" (click)='editConfig(component)' mat-icon-button aria-label="Example icon button with a vertical three dot icon">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
    <button [disabled]='!editable || components.length>=20' color='primary' mat-raised-button (click)='addComponent()' style="width:80%;margin: auto;position: relative;display: block;">הוספת תחנה</button>
  </mat-expansion-panel>

</div>
<div mat-dialog-actions>
  <button  [disabled]='buttonsDisabled' mat-button (click)="onNoClick()">ביטול</button>
  <div *ngIf="editable">
    <button [disabled]='buttonsDisabled' mat-flat-button color="primary" (click)="onSaveClick()" >שמור</button>
    <mat-progress-bar class='progress2' mode="indeterminate" [ngStyle]="{'visibility':saveProgress==true ? 'visible' : 'hidden'}"></mat-progress-bar>
  </div>
</div>
