<h1 mat-dialog-title class="center">Change email</h1>
<div mat-dialog-content style="overflow-y: hidden;">
  <mat-form-field class="full-width" appearance="outline" >
    <mat-label>{{translte["email"][language]}}</mat-label>
    <input (keyup)="getEmail($event.target.value)" [formControl]="emailFormControl" matInput placeholder="yourEmail@example.com">
    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        {{translte["validEmail"][language]}}
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        {{translte["EmailIsRequired"][language]}}
      </mat-error>
</mat-form-field>
<mat-form-field class="full-width" appearance="outline">
    <mat-label>{{translte["password"][language]}}</mat-label>
    <input (keyup)="getPassword($event.target.value)"  id="passwordInput" [formControl]="passwordFormControl" matInput placeholder="abcd1234" [type]="hide ? 'password' : 'text'">
    <mat-error *ngIf="this.passwordFormControl.hasError('required')">
        {{translte["passwordIsRequired"][language]}}
    </mat-error>
    <mat-error *ngIf="this.passwordFormControl.hasError('minLength') != null && !this.passwordFormControl.hasError('required')">
        {{translte["passwordMinLength"][language]}}
    </mat-error>
    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>  
    
</mat-form-field>
<mat-form-field class="full-width" appearance="outline" >
  <mat-label>{{translte["newEmail"][language]}}</mat-label>
  <input (keyup)="getNewEmail($event.target.value)" [formControl]="NewEmailFormControl" matInput placeholder="yourEmail@example.com">
  <mat-error *ngIf="NewEmailFormControl.hasError('email') && !NewEmailFormControl.hasError('required')">
      {{translte["validEmail"][language]}}
    </mat-error>
    <mat-error *ngIf="NewEmailFormControl.hasError('required')">
      {{translte["EmailIsRequired"][language]}}
    </mat-error>
</mat-form-field>  
</div>
<div mat-dialog-actions class="center">
  <button mat-button (click)="onNoClick()" [disabled]="loading">Cancel</button>
  <button mat-flat-button color="primary" (click)="loginPressed()"[disabled]="loading" >Save</button>
</div>