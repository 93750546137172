<h1 mat-dialog-title class="center">Change logo</h1>
<div mat-dialog-content style="overflow-y: hidden;">
    <div class="center">
        <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput>
        <button mat-raised-button color="primary" a (click)="fileInput.click()">Select File</button>
    </div>
    <img style="margin-top: 1vh; width:80%; margin-left: 10%; "  [src]="imageSrc">
</div>
<div mat-dialog-actions class="center">
  <button mat-button (click)="onNoClick()" [disabled]="loading">Cancel</button>
  <button mat-flat-button color="primary" (click)="onSaveClick()"[disabled]="loading" >Save</button>
</div>