<page-header></page-header>
<mat-drawer-container class="container">
    <mat-drawer mode="side" opened class="sideMenu" style="background-color: #741ba1;">
    </mat-drawer>
    <mat-drawer-content>
        <mat-spinner class="loading-spiner"></mat-spinner>
        <mat-card class="mat-elevation-z8" style="width: 85%; margin-left: 7.5%; margin-top: 2vh;">
            <mat-card-title class="button-row">Settings</mat-card-title>
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Company name</mat-label>
                <input matInput  [(ngModel)]="companyName"  [disabled] = "!editable">
            </mat-form-field>

            <div class="button-row">
                <button mat-raised-button color="warn" [disabled] = "!editable" (click)="changeEmail()">Change email</button>  
            </div>

            <div class="button-row">
                <button mat-raised-button color="warn" [disabled] = "!editable"  (click)="changePassword()">Change password</button>  
            </div>
            <div class="button-row" *ngIf="!editable">
                <button mat-raised-button color="primary" (click)="editPrassed()">Edit</button>  
            </div>
            <div class="button-row" *ngIf="editable">

                <span style="margin-right: 1vh;">
                    <button mat-raised-button (click)="cancelChanges()" >Cancel</button>

                </span>
                <span style="margin-left: 1vh;">
                    <button mat-raised-button color="primary" (click)="saveInfo()">Save</button>
                </span>
            </div>
        </mat-card>
    </mat-drawer-content>
</mat-drawer-container>
