import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {NavItem} from '../nav-item';
import {Router} from '@angular/router';
import {NavService} from '../nav.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { gamesID } from '../dialogs/edit-configuration/edit-configuration.component';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';


@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() toggle: boolean;
  curSubId;
  curComp;
  constructor(public navService: NavService,
              public router: Router,
              private matIconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer) {
    this.curSubId = localStorage.getItem('configId');
    this.curComp = localStorage.getItem('subComponent');
    if (this.depth === undefined) {
      this.depth = 0;
    };
    this.handleSvgIcons();

  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item: NavItem): void {
    if (!item.children || !item.children.length) {
      if (item.extras != null){
        if (item.extras.action === false){
          return;
        }
      }
      this.router.navigate([item.route], { state: item.extras });
      if (this.toggle){
        this.navService.toggleNav();
      }
      if (item.extras != null){

        if (item.extras.gameId != null){
          localStorage.setItem('configId', item.extras.gameId);
          window.location.reload();
        }
        else if (item.extras.componentId != null){
          localStorage.setItem('configId', item.extras.componentId);
          window.location.reload();
        }
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  handleSvgIcons(): void{
    this.matIconRegistry.addSvgIcon(`icon_home`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_home.svg`));
    this.matIconRegistry.addSvgIcon(`icon_plan_game`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_plan_game.svg`));
    this.matIconRegistry.addSvgIcon(`icon_settings`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_settings.svg`));
    this.matIconRegistry.addSvgIcon(`icon_puzle`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_puzle.svg`));
    this.matIconRegistry.addSvgIcon(`icon_logout`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_logout.svg`));
    this.matIconRegistry.addSvgIcon(`icon_activity-arhive`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_activity-arhive.svg`));
    this.matIconRegistry.addSvgIcon(`icon_control_and_track`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_control_and_track.svg`));
    this.matIconRegistry.addSvgIcon(`icon_library`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_library.svg`));

  }

}
