import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
// tslint:disable: max-line-length
// tslint:disable: class-name

export interface layout{
  name: string;
  url: string;
  description: string;
  openActivities: number;
  recentActivities: number;
  allActivities: number;
  color: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private layouts: Map<string, layout> = new Map();
  constructor(private firestore: AngularFirestore) { }

  public getlayoutsData(): Promise<Map<string, layout>>{
    return this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('layouts').get().toPromise().then( layoutsCallback => {
      const promises = [];
      layoutsCallback.docs.forEach(layoutDoc => {
        promises.push(this.getLayoutData(layoutDoc).then(layoutData => {
          this.layouts.set(layoutDoc.id, layoutData);
        }));
      });
      return Promise.all(promises).then(() => {
        return this.layouts;
      });
    });
  }
  private getLayoutData(layoutDoc): Promise<layout>{
    let localDescription = '';
    let localURL = '';
    let localcolor = '#3f51b5';
    let open = 0;
    let recent = 0;
    let closed = 0;

    if (layoutDoc.exists){
      if (layoutDoc.data().description){
        localDescription = layoutDoc.data().description;
      }
      if (layoutDoc.data().color){
        localcolor = layoutDoc.data().color;
      }
      if (layoutDoc.data().url){
        localURL = layoutDoc.data().url;
      }
      return this.firestore.collection('activities').ref.where('layoutId', '==', layoutDoc.id).where('start time', '<', new Date()).get().then( allActivities => {
        allActivities.docs.forEach(activityDoc => {
          if (activityDoc.data()['end time'].toDate() < new Date()){
            closed++;
            if (this.getDeltaInDays(activityDoc.data()['end time'].toDate()) < 90){
              recent++;
            }
          }
          else{
            open++;
          }
        });
        const data: layout = {name: layoutDoc.data().name, description: localDescription, url: localURL, openActivities: open, recentActivities: recent, allActivities: closed, color: localcolor, type: layoutDoc.data().type};
        return data;
      });
    }
  }
  private getDeltaInDays(date): number{
    const diff = Math.abs(new Date().getTime() - date.getTime());
    return  Math.ceil(diff / (1000 * 3600 * 24));
  }
}
