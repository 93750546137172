export const avaibleLanguages = ["he","en"]
export const texts = {
    login:{
        he:"כניסה למערכת",
        en:"Login",
    },
    email:{
        he:"דואר אלקטרוני",
        en:"Email"
    },
    EmailIsRequired:{
        he:"חובה להזין דואר אלקטרוני",
        en:"Email is required"
    },
    validEmail:{
        he:"חובה להזין כתובת דואר אלקטרוני חוקית",
        en:"Please enter a valid email address"
    },
    password :{
        he:"סיסמא",
        en:"Password"
    },
    passwordIsRequired:{
        he:"חובה להזין סיסמא",
        en:"Password is required"
    },
    passwordMinLength:{
        he:"ארוך סיסמא הוא לפחות 6 תווים",
        en:"The minimum Length for password is 6"
    },
    rememberMe:{
        he:"זכור אותי",
        en:"Remember me"
    },
    enterToPanel:{
        he:"היכנס לפאנל",
        en:"Enter to panel"
    },
    newEmail:{
        he:"הכנס כתובת דואר אלקטרוני חדשה",
        en:"Enter new email addres"
    },
    newPassword:{
        he:"הכנס סיסמא חדשה",
        en:"Enter a new password"
    }
};