import { DownloadPaymentReportService } from './download-payment-report.service';
import { CustomerStatusReportService } from './../../services/customer-status-report.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { StatisticsService } from './statistics.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as moment from 'moment';
import 'moment/locale/he';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class statisticsComponent implements OnInit {
  dailyUsersData: ChartDataSets[] = [{ data: [0, 0, 0, 0, 0, 0, 0], label: 'משתמשים חדשים' },];
  monthlyUsersData: ChartDataSets[] = [{ data: [0, 0, 0, 0, 0, 0, 0], label: 'משתמשים חדשים' },];
  dailyUsersLabels: Label[] = ['?', '?', '?', '?', '?', '?', '?'];
  dailyLineChartColors: Color[] = [
    {      
      backgroundColor: 'rgba(204, 0, 255,0.3)',
    },
  ];
  monthLineChartColors: Color[] = [
    {     
      backgroundColor: 'rgba(0, 153, 51,0.3)',
    },
  ];
  public barChartColors: Color[] = [
    {
      backgroundColor: '#4ad3cc',//'rgba(0, 153, 255,0.6)',
    },
  ];
  
  public barChartColorsLeft: Color[] = [
    {
      backgroundColor: '#fa9a3c',//'rgba(0, 153, 255,0.6)',
    },
  ];

  lineChartLegend = false;
  lineChartType = 'line';
  lineChartPlugins = [];
  chartsOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {         
          gridLines:{
            display: false
          }
        }
      ]
    }
  };
  monthlyUsersLabels: Label[] = ['?', '?', '?', '?', '?', '?', '?'];
  activitiesLabels: Label[] = ['?', '?', '?', '?', '?', '?', '?'];
  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartPlugins = [];
  now = new Date();
  startDate = new FormControl(new Date(this.now.setMonth(this.now.getMonth() - 1)));
  endDate = new FormControl(new Date(this.now.setMonth(this.now.getMonth() + 2)));
  activitiesUsersData: ChartDataSets[] = [{ data: [0, 0, 0, 0, 0, 0, 0], label: 'משתמשים' }];
  activitiesScoresData: ChartDataSets[] = [{ data: [0, 0, 0, 0, 0, 0, 0], label: 'ניקוד' }];
  usersCount = 0;
  activitiesCount = 0;
  runningActivitiesCount = 0;
  courses = 0;
  statusReportDisabled = false;
  paymentReportDisabled = false
  constructor(private router: Router, private dashboardService: StatisticsService, private auth: AngularFireAuth,
    private _snackBar: MatSnackBar, private CustomerStatusReportService:CustomerStatusReportService, private DownloadPaymentReportService: DownloadPaymentReportService
  ) {
    auth.authState.subscribe(() => {
      dashboardService.getCharts(this.startDate.value.toDate(), this.endDate.value.toDate()).then(data => {
        this.dailyUsersData = data.dailyUsersData;
        this.activitiesUsersData = data.activitiesUsersData;
        this.activitiesScoresData = data.activitiesScoresData;
        this.activitiesLabels = data.activitiesLabels;
        this.dailyUsersLabels = data.dailyUsersLabels;
        this.usersCount = data.usersCount;
        this.activitiesCount = data.activitiesCount;
        this.runningActivitiesCount = data.runningActivitiesCount;
        this.courses = data.courses;
      });
      dashboardService.getMonthChart().then(data => {
        this.monthlyUsersLabels = data.monthlyUsersLabels;
        this.monthlyUsersData = data.monthlyUsersData;
      })
    });
    localStorage.removeItem('configId');
    localStorage.setItem('subComponent', 'statistics');

  }
  ngOnInit(): void {
  }
  downloadStatusReport(): void{
    this.statusReportDisabled = true;
    this.CustomerStatusReportService.downloadStatusReport(this.startDate.value.toDate(),this.endDate.value.toDate()).then(() => {
      this.statusReportDisabled = false;
    });
  }
  updateCharts() {
    if (this.startDate.value != null && this.endDate.value != null) {
      this.dashboardService.getCharts(this.startDate.value.toDate(), this.endDate.value.toDate()).then(data => {
        this.dailyUsersData = data.dailyUsersData;
        this.activitiesUsersData = data.activitiesUsersData;
        this.activitiesScoresData = data.activitiesScoresData;
        this.activitiesLabels = data.activitiesLabels;
        this.dailyUsersLabels = data.dailyUsersLabels;
        this.usersCount = data.usersCount;
        this.activitiesCount = data.activitiesCount;
        this.runningActivitiesCount = data.runningActivitiesCount;
        this.courses = data.courses;
      });
    }
  }
  downloadReport(): void {
    this.paymentReportDisabled = true;
    this.DownloadPaymentReportService.downloadStatusReport(this.startDate.value.toDate(),this.endDate.value.toDate()).then(() => {
      this.paymentReportDisabled = false;
    })
  }
}
