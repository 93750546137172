import { LibraryComponent } from './tabs/library/library.component';
import { CGuard } from './c.guard';
import { AppComponent } from './app.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ComponentsComponent } from './tabs/components/components.component';
import { planActivityComponent } from './tabs/plan-activity/plan-activity.component';
import { ConfigurationsComponent } from './tabs/configurations/configurations.component';
import { SettingsComponent } from './settings/settings.component';
import { statisticsComponent } from './tabs/statistics/statistics.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivitiesTableComponent } from './tabs/activities-table/activities-table.component';
import { ActivitiesArchiveComponent } from './tabs/activities-archive/activities-archive.component';
import { canActivate, loggedIn } from '@angular/fire/auth-guard';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '404', component: NotfoundComponent},
  {path: '', component: AppComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'configuration', component: ConfigurationsComponent},  
  {path: 'main', component: MainComponent, children: [
    {path: 'statistics', component: statisticsComponent, canActivate: [CGuard]},
    {path: 'activities', component: ActivitiesTableComponent, canActivate: [CGuard]},
    {path: 'archive', component: ActivitiesArchiveComponent, canActivate: [CGuard]},
    {path: 'configurations', component: ConfigurationsComponent, canActivate: [CGuard]},
    {path: 'component', component: ComponentsComponent, canActivate: [CGuard]},
    {path: 'plan-activity', component: planActivityComponent, canActivate: [CGuard]},
    {path: 'home', component: HomeComponent, canActivate: [CGuard]},
    {path: 'library', component: LibraryComponent, canActivate: [CGuard]}
  ]},
  {path: '**', redirectTo: '/404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
