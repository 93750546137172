import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { label } from './../edit-row-dialog/edit-row-dialog.component';
import { Component, OnInit, Inject } from '@angular/core';
// tslint:disable: max-line-length
@Component({
  selector: 'app-create-subcustomer',
  templateUrl: './create-subcustomer.component.html',
  styleUrls: ['./create-subcustomer.component.css']
})
export class CreateSubcustomerComponent implements OnInit {

  header = '';
  name = '';
  saveDisabled = false;
  constructor(public dialogRef: MatDialogRef<CreateSubcustomerComponent>, @Inject(MAT_DIALOG_DATA) public data: label, private firestore: AngularFirestore, private snackBar: MatSnackBar
  ) {
    if (data === null){
      this.header = 'צור לקוח';
    }
    else{
      this.name = data.name;
      this.header = 'ערוך לקוח';
    }
   }

  ngOnInit(): void {
  }
  onSaveClick(): void{
    if (this.name == ''){
      this.snackBar.open('חסרים פרטים. נא להשלים', '', {duration: 5000, direction: 'rtl'});
      return;
    }
    this.saveDisabled = true;
    if (this.data == null){
      this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('subcustomers').add({name: this.name}).then(() => {
        this.dialogRef.close();
      });
    }
    else {
      this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('subcustomers').doc(this.data.id).update({name: this.name}).then(() => {
        this.dialogRef.close();
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
