import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalsService } from './../../globals.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';

export class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-change-image-dialog',
  templateUrl: './change-image-dialog.component.html',
  styleUrls: ['./change-image-dialog.component.css']
})
export class ChangeImageDialogComponent implements OnInit {

  loading = false
  selectedFile: ImageSnippet;
  image
  imageSrc

  constructor(private firestore:AngularFirestore,private global:GlobalsService,private storage:AngularFireStorage,private snackBar:MatSnackBar,public dialogRef: MatDialogRef<ChangeImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {
  }
  onFileChanged(event: any) {
    const file: File = event.target.files[0];
    this.image = file
    var reader = new FileReader()
    reader.onload= ( e=>{
      this.imageSrc = e.target.result
    })
    reader.readAsDataURL(file)
  }
  onNoClick(): void {
    this.dialogRef.close("");
  }
  onSaveClick(): void {
    var newUrl:string = ""
    this.loading = true
    this.storage.upload("logo/"+this.global.userUid,this.image).then(snapshot=>{
      snapshot.ref.getDownloadURL().then(URL=>{
        newUrl = URL
        this.firestore.collection("customers").doc(this.global.userUid).update({"logo url":newUrl}).then(()=>{
          this.snackBar.open("image uploaded successfully","",{
            duration: 2000,
           })
          this.dialogRef.close(newUrl);
        })
      })
      
    },err=>{
      this.snackBar.open("error while upload image","",{
        duration: 2000,
       })
       this.dialogRef.close(newUrl);
    })
    
  }

}
