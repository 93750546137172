<h1 mat-dialog-title dir="rtl">הורדת דוחות פעילות</h1>
<div dir="rtl" mat-dialog-content >
    <button mat-stroked-button class="center" color="primary" (click)="downloadStatistics()">הורדת דו"ח סטטיסטיקה</button>
    <button mat-stroked-button class="center" color="primary" (click)="downloadGrades()">הורדת דו"ח ציונים לפעילות</button>
    <button mat-stroked-button class="center" color="primary" (click)="downloadDetailed()">הורדת דו"ח מפורט למשחקים</button>
    <button mat-stroked-button class="center" *ngIf="data.activityType === 'games' || data.haveLeaderBoard" color="primary" (click)="showLeaderboard()">צפייה בלוח התוצאות</button>
    <button mat-stroked-button class="center" *ngIf="data.activityType === 'course'" color="primary" (click)="downloadCourseStatus()">הורדת דו"ח סטטוס קורס</button>
    <button mat-stroked-button class="center" *ngIf="hasSatisReport" color="primary" (click)="downloadSatifReport()">הורדת דו"ח חוות דעת</button>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="onNoClick()">סגור</button>
</div>