import { Component, OnInit, OnDestroy } from '@angular/core';

import { LoaderService } from './loader.service';

@Component({
    selector: 'angular-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.css']
})
export class LoaderComponent implements OnInit {

    constructor(
        public loaderService: LoaderService
    ) { }

    ngOnInit() { 
    }

} 