// tslint:disable: max-line-length
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
interface Result{
  name: string;
  score: number;
  place: number;
}
@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css']
})
export class LeaderboardComponent implements OnInit {

  activityLogo = '';
  activityName = '';
  dir = 'rtl';
  language;
  results: Result[] = [];
  finished = true;
  notfinished = true;
  constructor(private firestore: AngularFirestore, public dialogRef: MatDialogRef<LeaderboardComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.language = localStorage.getItem('language');
    this.dir = localStorage.getItem('dir');
    this.firestore.collection('activities').doc(data.activityCode).get().toPromise().then(doc => {
      if (doc.data()['end time'].toDate() > new Date()){
        this.finished = false;
      }
      else{
        this.notfinished = false;
      }
      const scores = [];
      if (doc.data().loginType === 'solos'){
        doc.ref.collection('leaderboard').get().then( leaderboard => {
          leaderboard.docs.forEach( user => {
            let userScore = 0;
            for (let key in user.data()){
              userScore += user.data()[key];
            }
            scores.push({id: user.id, score: userScore});
          });
        }).then( () => {
          scores.sort( (a, b) => a.score - b.score);
          scores.reverse();
          let i = 0;
          scores.forEach(score => {
            i++;
            const loc = i;
            firestore.collection('users').doc(score.id).collection('activities').doc(data.activityCode).get().toPromise().then( userDoc => {
              this.results.push({name: userDoc.data().name, score: Math.floor(score.score), place: loc});
            });
          });
        });
      }
      else{
        doc.ref.collection('leaderboard').get().then( leaderboard => {
          const groups = {};
          leaderboard.docs.forEach( group => {
            for (let scoreKey in group.data()){
              const scoreValue = group.data()[scoreKey];
              const path = scoreKey.split('.', 2);
              if (groups[group.id] == null){
                groups[group.id] = {};
                groups[group.id][path[1]] = scoreValue;
              }
              else if (groups[group.id][path[1]] != null){
                groups[group.id][path[1]] += scoreValue;
              }
              else{
                groups[group.id][path[1]] = scoreValue;
              }
            }
          });
          const scores = [];
          for (let group in groups){
            let sum = 0;
            let counter = 0;
            for (let user in groups[group]){
              sum += groups[group][user];
              counter++;
            }
            scores.push({group, score: sum / counter});
          }
          scores.sort( (a, b) => a.score - b.score);
          scores.reverse();
          let i = 0;
          scores.forEach(score => {
            i++;
            const loc = i;
            if (doc.data().loginType === 'groups'){
              this.results.push({name: ('קבוצה ' + score.group), score: Math.floor(score.score), place: loc});
            }
            else{
              this.results.push({name: score.group, score: Math.floor(score.score), place: loc});
            }
          });
        });
      }
      this.activityName = doc.data().activityName;
      this.activityLogo = doc.data().activityLogo;
    });
  }
  ngOnInit(): void {
  }
  onOkClick(): void {
    this.dialogRef.close(true);
  }

}
