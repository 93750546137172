import { label, subcustomer } from './../../dialogs/edit-row-dialog/edit-row-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { GlobalsService } from './../../globals.service';
// tslint:disable: max-line-length
import { EditRowDialogComponent } from '../../dialogs/edit-row-dialog/edit-row-dialog.component';
import { ShareActivityComponent } from '../../dialogs/share-activity/share-activity.component';
import { ReportsComponent } from '../../dialogs/reports/reports.component';

import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {MatPaginator,MatPaginatorIntl} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { Activity } from '../../classes/activity';
import { ActivitesService } from '../../services/activites.service';
import * as moment from 'moment';
import { AreUSureDialogComponent } from '../../dialogs/are-u-sure-dialog/are-u-sure-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-activities-archive',
  templateUrl: './activities-archive.component.html',
  styleUrls: ['./activities-archive.component.css']
})
export class ActivitiesArchiveComponent implements OnInit {
  displayedColumns: string[];
  dataSource;
  isShown = false;
  now;
  isMobile = true;
  recentOnly = false;
  labels: label[] = [];
  subcustomers: subcustomer[] = [];
  currentLabel = 'all';
  currentSubcustomer = 'all'
  constructor(private deviceService: DeviceDetectorService,
    private router: Router,
    public dialog: MatDialog,
    public activitesService: ActivitesService,
    private GlobalsService: GlobalsService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private firestore: AngularFirestore) {
    this.now = new Date();
    this.recentOnly = GlobalsService.recentOnly;
    localStorage.removeItem('configId');
    localStorage.setItem('subComponent', 'archive');
    this.isMobile = this.deviceService.isMobile();
    this.displayedColumns = this.isMobile ? [ 'activityName', 'activityType', 'status', 'edit'] : [ 'activityName', 'activityType', 'activityCode', 'startDate', 'endDate', 'status', 'edit'];
    this.filterChanged(this.recentOnly);
    this.matIconRegistry.addSvgIcon(`icon_preview`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/preview.svg`));
    this.getLabels();
    this.getSubcustomers();
  }
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginatorIntl, {static: true}) paginatorIntl: MatPaginator;

  ngOnInit(): void {
  }
  getLabels(): void {
    this.labels = [];
    this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('labels').get().toPromise().then(labelsSnapShot => {
      labelsSnapShot.docs.forEach(labelDoc => {
        const label: label = {id: labelDoc.id, name: labelDoc.data().name};
        this.labels.push(label);
      });
    });
  }

  getSubcustomers(): void {
    this.subcustomers = [];
    this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('subcustomers').get().toPromise().then(subcustomersSnapShot => {
      subcustomersSnapShot.docs.forEach(subcustomerDoc => {
        const subcustomer: subcustomer = {id: subcustomerDoc.id, name: subcustomerDoc.data().name};
        this.subcustomers.push(subcustomer);
      });
    });
  }
  filterChanged(recentOnly = this.recentOnly): void{
    this.recentOnly = recentOnly;
    this.activitesService.getActivities(recentOnly ? 'finishedRecenetly' : 'finished', this.currentLabel, this.currentSubcustomer).then( activities => {
      this.dataSource = new MatTableDataSource<Activity>(activities);
      this.dataSource.paginator = this.paginator;
    });
  }
  editPrassed(col): void{
    this.openDialog(col);
  }
  duplicatePrassed(col: Activity): void{
    const dialogRef = this.dialog.open(AreUSureDialogComponent, {
      width: '350px',
      data: {
        title: 'האם לשכפל את ההגדרות של פעילות זו?',
        ok: 'שכפל'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        const activity = new Activity(col.layoutId);
        activity.loginType = col.loginType;
        activity.isNameRequired = col.isNameRequired;
        activity.gamesConfiguritions = col.gamesConfiguritions;
        activity.activityLogo = col.activityLogo;
        activity.endGroup = col.endGroup;
        activity.startGroup = col.startGroup;
        activity.contactPosition = col.contactPosition;
        activity.contactMail = col.contactMail;
        activity.contactName = col.contactName;
        activity.contactPhone = col.contactPhone;
        activity.activityType = col.activityType;
        activity.componentsConfiguritions = col.componentsConfiguritions;
        const dialogRef1 = this.dialog.open(EditRowDialogComponent, {
          width: '500px',
          data: activity
        });

        dialogRef1.afterClosed().subscribe(result1 => {
          if (result1 === 1){
            this.router.navigateByUrl('main/activities');
          }
        });
      }
    });
  }
  deletePrassed(col): void{
    const dialogRef = this.dialog.open(AreUSureDialogComponent, {
      width: '350px',
      data: {
        title: 'האם למחוק פעילות זאת?',
        ok: 'מחק'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.firestore.collection('activities').doc(col.activityCode).get().toPromise().then(activityDoc => {
          const activity = new Activity(col.layoutId, activityDoc);
          this.activitesService.setActivity(activity, true).then(() => {
            this.filterChanged(this.recentOnly);
          });
        });
      }
    });
  }
  resultPrassed(col): void{
    const activity = (JSON.parse(JSON.stringify(col)));
    const dialogRef = this.dialog.open(ReportsComponent, {
      width: '500px',
      data: activity
    });
  }
  openDialog(data): void {
    const activity = (JSON.parse(JSON.stringify(data)));
    const dialogRef = this.dialog.open(EditRowDialogComponent, {
      width: '500px',
      data: activity
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1){
        const index = this.dataSource.data.findIndex(act => act.activityCode === activity.activityCode);
        activity.startDate = moment(activity.startDate);
        activity.endDate = moment(activity.endDate);
        this.dataSource.data[index] = activity;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

}
