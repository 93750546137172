import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router,
              private matIconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer) { 

    this.matIconRegistry.addSvgIcon(`icon_task`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/tasks.svg`));
    this.matIconRegistry.addSvgIcon(`icon_control`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/control.svg`));
  }

  ngOnInit(): void {
   
  }

  createActivity(forwardTo: string): void{
    this.router.navigateByUrl('/main/' + forwardTo, { state: { ignoreLoadingBar: true } });
  }

}
