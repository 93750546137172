import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public userUid:string;
  public language:string;
  public companyName:string;
  public logoUrl:string;
  public createActivity = false;
  public recentOnly = false;
  public backgroundColor;
  authState;
  constructor(private auth: AngularFireAuth) {
    auth.authState.subscribe((auth) => {
      this.authState = auth;
    });
  }

  get authenticated(): boolean{
    return this.authState !== null;
  }

}
