import { AngularFirestore } from '@angular/fire/firestore';
import { texts, avaibleLanguages } from './../../environments/languages';
import { LoginService } from './../services/login-service.service';
import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth';
import getUserLocale from 'get-user-locale'
import { GlobalsService } from '../globals.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  FormControl;
  password: string;
  email: string;
  hide = true;
  isUnderMinLength = false;
  rememberMe = true;
  language;
  translte = texts;
  loading = true;
  tryToLogin = false;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);
  buttonDisable = true;
  constructor(private LoginService: LoginService,
              private router: Router,
              private snackBar: MatSnackBar,
              private auth: AngularFireAuth,
              public global: GlobalsService,
              private firestore: AngularFirestore){
    this.global.language = 'he';
    auth.authState.subscribe( user => {
      if (user) {
        this.global.userUid = user.uid;
        localStorage.setItem('uid', user.uid);
        this.firestore.collection('panelUsers').doc(this.global.userUid).get().toPromise().then(doc => {
          this.firestore.collection('customers').doc(doc.data().customerId).get().toPromise().then(innnerDoc => {
            this.global.language = innnerDoc.data().language;
            this.global.companyName = innnerDoc.data().name;
            localStorage.setItem('customerId', doc.data().customerId);
          }).then(() => {
            this.router.navigateByUrl('main/plan-activity', { state: { ignoreLoadingBar: true } });
          });
        });
    }
    else{
      this.loading = false;
    }
    });
  }

  private nullOrEmpty(str: string): boolean{
    return str == null || str === '';
  }
  ngOnInit(): void {
  }

  getEmail(val): void{
    this.email = val;
    if (!this.emailFormControl.hasError('email') && !this.emailFormControl.hasError('required') && !this.passwordFormControl.hasError('required')  && !this.passwordFormControl.hasError('minLength')){
      this.buttonDisable = false;
    }
    else{
      this.buttonDisable = true;
    }
  }
  getPassword(val): void{
    this.password = val;
    if (!this.emailFormControl.hasError('email') && !this.emailFormControl.hasError('required') && !this.passwordFormControl.hasError('required') && !this.passwordFormControl.hasError('minLength')){
      this.buttonDisable = false;
    }
    else{
      this.buttonDisable = true;
    }
  }
  loginPressed(): void{
    this.buttonDisable = true;
    this.tryToLogin = true;
    this.LoginService.tryToLogin(this.email, this.password).subscribe(
      res => {
        if (res){
          this.auth.authState.subscribe( user => {
            if (user) {
              this.global.userUid = user.uid;
              localStorage.setItem('uid', user.uid);
              this.firestore.collection('panelUsers').doc(this.global.userUid).get().toPromise().then(doc => {
                this.firestore.collection('customers').doc(doc.data().customerId).get().toPromise().then(innnerDoc => {
                  this.global.language = innnerDoc.data().language;
                  this.global.companyName = innnerDoc.data().name;
                  localStorage.setItem('customerId', doc.data().customerId);
                }).then(() => {
                  this.router.navigateByUrl('main/plan-activity', { state: { ignoreLoadingBar: true } });
                });
              });
            }
          });
        }
        else{
          this.buttonDisable = false;
          this.tryToLogin = false;
          this.snackBar.open('מייל או ססמא לא נכונים', '', {
            duration: 2000,
          });
        }
      },
      error => { }
    );
  }
}
