import { GlobalsService } from '../../globals.service';
import { LayoutDesctiptionComponent } from '../../dialogs/layout-desctiption/layout-desctiption.component';
import { DashboardService, layout } from '../../services/dashboard.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Activity } from 'src/app/classes/activity';
import { EditRowDialogComponent } from 'src/app/dialogs/edit-row-dialog/edit-row-dialog.component';

// tslint:disable: prefer-for-of
// tslint:disable: max-line-length

@Component({
  selector: 'app-dashboard',
  templateUrl: './plan-activity.component.html',
  styleUrls: ['./plan-activity.component.scss']
})
export class planActivityComponent implements OnInit {
  layouts = [];
  loading = true;
  layoutId: string;
  disabeled = true;
  layoutsData: Map<string, layout>;
  layoutsArr: string[] = [];
  constructor(private firestore: AngularFirestore, private router: Router, private DashboardService: DashboardService, public dialog: MatDialog, private GlobalsService: GlobalsService ) {
    this.layoutsArr = [];
    this.DashboardService.getlayoutsData().then( layouts => {
      this.layoutsData = layouts;
      this.layoutsData.forEach((value, key) => {
        this.layoutsArr.push(key);
      });
      this.loading = false;
    });
  }
  ngOnInit(): void {
  }
  showDescription(layoudId): void{
    const dialogRef = this.dialog.open(LayoutDesctiptionComponent, {
      width: '250px',
      data: {name: this.layoutsData.get(layoudId).name, desc: this.layoutsData.get(layoudId).description}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getDividerColor(layout){
    let styles = {
      'border-top-color': '#e5e5e5'//this.layoutsData.get(layout).color ,
    };
    return styles;
  }
  getContrastYIQ(hexcolor){
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
}
  getBTNColor(layout){
    let styles = {
      'background-color': this.layoutsData.get(layout).color ,
      'color': this.getContrastYIQ(this.layoutsData.get(layout).color)
    };
    return styles;
  }
  moveToCreate(layoutId): void{
    const activity = new Activity(layoutId);
    
    const dialogRef = this.dialog.open(EditRowDialogComponent, {
      width: '500px',
      data: activity
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1){
        this.router.navigateByUrl('/main/activities', { state: { ignoreLoadingBar: true } });
      }
    });
  }
  Selected(element: string): void{
      this.layoutId = element;
      this.disabeled = false;
  }
}
