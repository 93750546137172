
<router-outlet></router-outlet>
<page-header text="כניסה"></page-header>
 <div class="center" style="background-color: #33cabd;"><!--style="background-color: #e3e3e3;" -->
    <div class="div-login-wrapper">
        <div class="div-login-card"></div>
        <mat-card class="mat-elevation-z4 m-auto loginCard" *ngIf="!loading">
            <div class="button-row">
                <mat-card-title class="card-header" >{{translte["login"][this.global.language]}}</mat-card-title>
            </div>
            <mat-form-field class="full-width" appearance="outline" >
                <mat-label>{{translte["email"][this.global.language]}}</mat-label>
                <input (keyup)="getEmail($event.target.value)" [formControl]="emailFormControl" matInput placeholder="yourEmail@example.com">
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    {{translte["validEmail"][this.global.language]}}
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    {{translte["EmailIsRequired"][this.global.language]}}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{translte["password"][this.global.language]}}</mat-label>
                <input (keyup)="getPassword($event.target.value)"  id="passwordInput" [formControl]="passwordFormControl" matInput placeholder="abcd1234" [type]="hide ? 'password' : 'text'">
                <mat-error *ngIf="this.passwordFormControl.hasError('required')">
                    {{translte["passwordIsRequired"][this.global.language]}}
                </mat-error>
                <mat-error *ngIf="this.passwordFormControl.hasError('minLength') != null && !this.passwordFormControl.hasError('required')">
                    {{translte["passwordMinLength"][this.global.language]}}
                </mat-error>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
                <section class="section">
                <mat-checkbox [(ngModel)]="rememberMe" #ctrl="ngModel" class="margin" color="primary">{{translte["rememberMe"][this.global.language]}}</mat-checkbox>
                </section>
                <section>
                <div class="button-row">
                    <button mat-raised-button color="primary" class="button-general" [disabled]='!emailFormControl.valid || !passwordFormControl.valid || tryToLogin' (click)="loginPressed()">{{translte["enterToPanel"][this.global.language]}}<mat-progress-bar *ngIf='tryToLogin' mode="indeterminate"></mat-progress-bar>
                    </button>
                </div>
                </section>
        </mat-card>
    </div>
</div>
