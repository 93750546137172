import { Injectable } from '@angular/core';

@Injectable()

export class LoaderService {

    public isShow: boolean = false;

    show() {
        this.isShow = true;
    }

    hide() {
        this.isShow = false;
    }
}