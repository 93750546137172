import { GlobalsService } from './../globals.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
// tslint:disable: forin

@Injectable({
  providedIn: 'root'
})
export class CustomerStatusReportService {

  constructor(private firestore: AngularFirestore,  private snackBar: MatSnackBar, private functions: AngularFireFunctions) { }

  downloadStatusReport(startDate: Date, endDate: Date): Promise<any> {
    const cloudFunction = this.functions.httpsCallable('CreatStatusReport');
    return cloudFunction({customerId: localStorage.getItem('customerId'),endTime: endDate.getTime(),startTime: startDate.getTime()}).toPromise().then(data => {
      data = JSON.parse(data)
      // console.log(JSON.parse(data));
      var dataDict = {};
      var user
      for (user in data){
        dataDict[user] = {}
        dataDict[user]['משתמש'] = data[user].email
        dataDict[user]['מספר המשחקים אשר שיחק בהם'] = data[user].numOfGames
        dataDict[user]['ציון ממוצע'] = data[user].avgScore
        dataDict[user]['תפקיד בחברה'] = data[user].position
      }
      let maxLen = 0;
      let header;
      const arrayOfUsers = [];
      let fileName: string = '';

      for ( let userId in dataDict){
        arrayOfUsers.push(dataDict[userId]);
        if (Object.keys(dataDict[userId]).length > maxLen){
          maxLen = Object.keys(dataDict[userId]).length;
          header = Object.keys(dataDict[userId]);
        }
      }
      if (maxLen === 0) { return  this.snackBar.open('אין נתונים בפעילות זו', '', {direction: 'rtl'}); }
      const items = arrayOfUsers;
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      const tempcsv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      tempcsv.unshift(header.join(','));
      const content = String.fromCharCode(0xFEFF) + tempcsv.join('\r\n');
      const csvContent = 'data:text/csv;charset=utf-8,'  + content;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      fileName += 'customer status ' + new Date().toLocaleDateString("he-IL") + '.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }
}
