import { CreateComponentConfigurationComponent } from './../../dialogs/create-component-configuration/create-component-configuration.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { AngularFireStorage } from '@angular/fire/storage';
import { LoaderService } from 'src/app/loader/loader.service';
import { AreUSureDialogComponent } from '../../dialogs/are-u-sure-dialog/are-u-sure-dialog.component';
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of
// tslint:disable: forin
@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.css']
})
export class ComponentsComponent implements OnInit {
  customerId: string;
  componentId: string;
  configurationArr = [];
  dataSource;
  displayedColumns: string[] = ['name', 'edit'];
  loading = true;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(private firestore: AngularFirestore,
              private router: Router,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private storage: AngularFireStorage,
              public loaderService : LoaderService
              ) {
    this.componentId = this.router.getCurrentNavigation().extras.state.componentId;
    localStorage.setItem('configId', this.componentId);
    localStorage.setItem('subComponent', 'component');
    this.customerId = localStorage.getItem('customerId');

    this.dataSource = new MatTableDataSource<any>([{game: '', name: ''}]);
    this.loaderService.show();
    this.getComponentsConfigurationSets();
  }

  private getComponentsConfigurationSets = () => {
    this.firestore
    .collection('customers')
    .doc(this.customerId)
    .collection('componentsConfigurationSets',
                  ref => ref.where('componentId', '==', this.componentId))
    .get()
    .toPromise()
    .then(async configurations => {
      const allCourseActivitiesDocs = await this.getAllCourseActivities();
      configurations.forEach(configurationDoc => {
        let configIsUsedInActivity = false;
        let activityStarted = false;
        for (let activity of allCourseActivitiesDocs.docs) {
          // check if the config is part of an activity - if so disallow to delete it
          const activityData = activity.data();
          const configIsUsedInActivityInd =
                activityData.componentsConfiguritionSets.findIndex(configuration => {
                  return configuration.configId === configurationDoc.id;
                });
          if (configIsUsedInActivityInd > -1) {
            configIsUsedInActivity = true;
            if (new Date(activityData['start time'].seconds) < new Date()) {
              activityStarted = true;
            }
            break;
          }
        }

        if (!configurationDoc.data().isCopy) {
          this.configurationArr.push({
            name: configurationDoc.data().name,
            configId: configurationDoc.id,
            allowDelete: !configIsUsedInActivity,
            allowEdit: !activityStarted
          });
        }
      });
      this.loaderService.hide();
      this.dataSource = new MatTableDataSource<any>(this.configurationArr);
      this.dataSource.paginator = this.paginator;
      // this.loaderService.show();
      // this.getConfigurationsData(configurations.docs).then( () => {
      //   this.loaderService.hide();
      //   this.dataSource = new MatTableDataSource<any>(this.configurationArr);
      //   this.dataSource.paginator = this.paginator;
      // });
    });
  };

  getConfigurationsData(configurationsDocs): Promise<any> {
    const promises = [];
    // configurationsDocs.forEach(configurationDoc => {
    //   promises.push(
    //     this.firestore
    //     .collection('components')
    //     .doc(configurationDoc.data().componentId)
    //     .get()
    //     .toPromise()
    //     .then(componentDoc => {
    //       if (configurationDoc.data().componentId === this.componentId &&
    //           !configurationDoc.data().isCopy)
    //       {
    //         this.configurationArr.push({ name: configurationDoc.data().name, configId: configurationDoc.id});
    //       }
    //     })
    //   );
    // });
    return Promise.all(promises);
  }

  private async getAllCourseActivities() {
    return await this.firestore
      .collection('activities',
                  ref => ref.where('activityType', '==', 'course')
                            .where('customerUID', '==', this.customerId))
      .get()
      .toPromise()
      .then(activityDocs => {
        return activityDocs;
      });
  }

  editPrassed(element, editable=true): void{
    this.firestore
      .collection('customers')
      .doc(this.customerId)
      .collection('componentsConfigurationSets')
      .doc(element.configId)
      .get()
      .toPromise().then(configDoc => {
        this.openDialog({
          configId: element.configId,
          componentId: configDoc.data().componentId,
          configurations: configDoc.data().configurations,
          name: configDoc.data().name,
          createConfig: false,
          editable
        });
      });
  }

  onDeleteComponent(element): void {
    const dialogRef = this.dialog.open(AreUSureDialogComponent, {
      width: 'auto',
      data: {
        title: 'האם למחוק את התחנה?',
        ok: 'מחק'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loaderService.show();
        this.firestore
        .collection('customers')
        .doc(this.customerId)
        .collection('componentsConfigurationSets',
          ref => ref.where('componentId', '==', this.componentId))
        .doc(element.configId)
        .delete().then(() => {
          const data = this.dataSource.data;
          const deletedInd = data.findIndex(config => config.configId === element.configId);
          data.splice(deletedInd, 1);
          this.dataSource.data = data;
          this.loaderService.hide();
        });
      }
    });
  }

  createConfig(): void{
    this.openDialog({createConfig: true, componentId: this.componentId, editable: true});
  }
  openDialog(data): void {
    const dialogRef = this.dialog.open(CreateComponentConfigurationComponent, {
      width: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        const data = this.dataSource.data;
        if (result.created) {
          data.unshift({
            name: result.confName,
            configId: result.confId,
            allowDelete: true,
            allowEdit: true
          });
        } else {
          for (let conf of data) {
            if (conf.configId === result.confId) {
              conf.name = result.confName;
              break;
            }
          }
        }
        this.dataSource.data = data;
      }
    });
  }
  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = "מוצר פר עמוד:";
  }

}
