<h1 mat-dialog-title class="center" dir='rtl'>{{header}}</h1>
<div dir="rtl" mat-dialog-content >
  <mat-card-content  dir='rtl'>
    <mat-horizontal-stepper  #stepper dir='rtl'>

      <mat-step>
        <ng-template matStepLabel> {{ data.editable ? 'הכנס/י שם לתחנה' : 'שם התחנה' }} </ng-template>
        <mat-form-field class="button-row" appearance="outline">
          <mat-label>שם התחנה</mat-label>
          <input [disabled]="!data.editable" matInput placeholder="תחנת תרגול חומר בטיחות" [(ngModel)]='configName' maxlength="20">
        </mat-form-field>
        <div>
          <button mat-button matStepperNext>הבא</button>
        </div>
      </mat-step>

      <mat-step >
          <ng-template matStepLabel> {{ data.editable ? 'הכנס/י כותרת לתחנה' : 'כותרת התחנה' }} </ng-template>
          <mat-form-field class="button-row" appearance="outline">
            <mat-label>כותרת התחנה</mat-label>
            <input [disabled]="!data.editable" matInput placeholder=" תחנה מספר 2" [(ngModel)]='configHeader' maxlength="15">
          </mat-form-field>
          <div>
            <button mat-button matStepperNext>הבא</button>
          </div>
      </mat-step>

      <mat-step >
          <ng-template matStepLabel> {{ data.editable ? 'הכנס/י תיאור לתחנה' : 'תיאור התחנה' }}  </ng-template>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>תיאור התחנה</mat-label>
            <input [disabled]="!data.editable" matInput placeholder="בתחנה זו תילמדו על מבנה החברה ועל מנהלייה" [(ngModel)]='configDescription' maxlength="100">
          </mat-form-field>
          <div >
            <button  mat-button matStepperNext>הבא</button>
          </div>
      </mat-step>
      <mat-step *ngIf='isMovie'>
        <ng-template matStepLabel> {{ data.editable ? 'הכנס/י קישור לסרטון' : 'קישור לסרטון' }}</ng-template>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>קישור לסרטון</mat-label>
          <input [disabled]="!data.editable" matInput placeholder="בתחנה זו תילמדו על מבנה החברה ועל מנהלייה" [(ngModel)]='configLink' >
        </mat-form-field>
      </mat-step>
      <mat-step *ngIf='isImage'>
        <ng-template matStepLabel> {{ data.editable ? 'הכנס/י תמונה' : 'התמונה' }} </ng-template>
        <div class="center">
          <input [disabled]="!data.editable" style="display: none" type='file' accept=".jpg,.png" (change)="onFileChanged($event)" #fileInput>
          <button mat-raised-button color="primary" (click)="fileInput.click()">בחר תמונה</button>
        </div>
        <img style="margin-top: 1vh; width:20%; margin-left: 40%; "  [src]="imgSrc">
      </mat-step>
      <mat-step *ngIf='isPresentation'>
        <ng-template matStepLabel> {{ data.editable ? 'הכנס/י מצגת' : 'המצגת' }} </ng-template>
        <div class="center">
          <input [disabled]="!data.editable" style="display: none" type="file" accept=".pdf,.pptx,.docx,.zip,.rar" (change)="onFileChanged($event)" #fileInput>
          <button mat-raised-button color="primary" (click)="fileInput.click()">בחר מצגת</button>
          <mat-icon *ngIf='haveImage' >done</mat-icon>

        </div>
      </mat-step>
      <mat-step *ngIf='isSatisfaction' style="overflow-y: scroll;">
        <ng-template matStepLabel> {{ data.editable ? 'הכנס/י שאלות לסקר שביעות רצון' : 'שאלות לסקר שביעות רצון' }} </ng-template>
        <div>
          <mat-accordion style="overflow-y: scroll;"  *ngFor='let surveyQuestion of surveyQuestions.controls; let i=index'>
            <mat-expansion-panel style="margin-top: 20px;">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'שאלה מספר ' + (i+1)}}
                </mat-panel-title>
                <mat-panel-description class="right-aligned" *ngIf='surveyQuestions.length > 1'>
                  <button style="left: 20px;" mat-icon-button (click)='removeQuestion(i)'>
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matStepLabel>הכנס שאלות</ng-template>
                <mat-form-field class="full-width" appearance="outline" >
                  <input [disabled]="!data.editable" matInput [formControl]='surveyQuestions.at(i)' maxlength="100">
                  <mat-error *ngIf="surveyQuestions.at(i).errors">
                    חובה להכניס שאלה        
                  </mat-error>
                </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>
          <button *ngIf='data.editable' (click)='addSurveyQuestion()' mat-icon-button>
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step *ngIf='isGame'>
        <ng-template matStepLabel>{{ data.editable ? 'בחר/י משחק' : 'המשחק' }} </ng-template>
        <mat-form-field class="button-row" appearance="outline">
          <mat-label>בחר משחק</mat-label>
          <mat-select [disabled]="!data.editable" [(ngModel)]="selectedGame" name="games" (ngModelChange)='gameReselected()'>
            <mat-option *ngFor="let game of games" [value]="game.id" >
              {{game.name['he']}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="button-row" appearance="outline" *ngIf='!loadingConfigs'>
          <mat-label>בחר הגדרה</mat-label>
          <mat-select  [disabled]="!data.editable" [(ngModel)]="selectedConfig" name="games" >
            <mat-option *ngFor="let config of configs" [value]="config.id">
              {{config.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-step>

    </mat-horizontal-stepper>
  </mat-card-content>
  <div class="center" mat-dialog-actions>
    <button mat-button (click)="onNoClick()" >ביטול</button>
    <button
      *ngIf="data.editable"
      mat-flat-button color="primary"
      (click)='saveConfig()'
      [disabled]='disableSave'>
      שמור
      <mat-progress-bar *ngIf='disableSave' mode="indeterminate"></mat-progress-bar>
    </button>
  </div>
</div>

