import { GlobalsService } from './globals.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import 'moment/locale/he';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'managmentPanel';
  constructor(private router: Router, private GlobalsService: GlobalsService) {
    moment.locale('he');
    if (localStorage.getItem('customerId') != null){
      this.router.navigateByUrl('main/plan-activity', { state: { ignoreLoadingBar: true } });
    }
    else{
      this.router.navigateByUrl('/login', { state: { ignoreLoadingBar: true } });
    }
  }

}

