<div class="div-top" style="display: none;"></div>

<a  style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;" mat-list-item [ngStyle]="{'padding-inline-start': (depth * 16) + 'px'}" (click)="onItemSelected(item)"  [disabled]="item.extras ? item.extras.action==false : false"
   [ngClass]="{'active': item.extras ? item.extras.gameId === curSubId ? true : item.extras.componentId === curSubId ? true : false  : item.route ? router.isActive(item.route, true): false, 'expanded': expanded, 'disabled': item.extras ? item.extras.action==false : false } "
   class="menu-list-item">
   <div [ngClass]="item.iconName === 'icon_dot' ?  'div-bg-select-item  div-bg-select-item-left-child': 'div-bg-select-item div-bg-select-item-left'">
    <img src="assets/images/menu_select_mask.svg"/>
   </div>
  <mat-icon *ngIf="item.iconName === 'icon_dot'"  style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
    unselectable="on"
    onselectstart="return false;" 
    onmousedown="return false;" class="routeIcon">
    stop_circle
  </mat-icon>
 <mat-icon *ngIf="item.iconName !== 'icon_dot'" [svgIcon]="item.iconName" class="route-image" ></mat-icon>

  <!-- <img [src]="'assets/images/' + item.iconName" class="route-image"/> -->
  <span style="color: #ffffff;">{{item.displayName}}</span> 
  <span  style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none; color: #ffffff;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;" fxFlex *ngIf="item.children && item.children.length">
    <span style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none; " 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;" fxFlex></span>
    <mat-icon style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" 
 unselectable="on"
 onselectstart="return false;" 
 onmousedown="return false;" [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>

<div *ngIf="expanded" >
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>
<div class="menu-item-separator">&nbsp;</div>
