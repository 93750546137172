// tslint:disable: max-line-length
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Activity } from '../../classes/activity';
import { MatTooltip } from '@angular/material/tooltip';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-share-activity',
  templateUrl: './share-activity.component.html',
  styleUrls: ['./share-activity.component.css']
})
export class ShareActivityComponent implements OnInit {
  elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string;
  href: string;
  constructor( @Inject(MAT_DIALOG_DATA) public data: Activity, public dialogRef: MatDialogRef<ShareActivityComponent>) {
    this.value = `${environment.gamesAppUrl}#?activityCode=${data.activityCode}`;
   }

  ngOnInit(): void {
  }
  downloadImage(): void{
    this.href = document.getElementsByTagName('img')[0].src;
    const a = document.createElement('a');
    a.href = this.href;
    a.download = 'activityQR.png';
    a.click();

  }
  copyLink(tooltip: MatTooltip, inputElement): void{
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    tooltip.disabled = false;
    tooltip.show();
    this.delay(2000).then( () => {
      tooltip.disabled = true;
    });

  }

  delay(ms: number): Promise<void> {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}
