import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-are-u-sure-dialog',
  templateUrl: './are-u-sure-dialog.component.html',
  styleUrls: ['./are-u-sure-dialog.component.css']
})
export class AreUSureDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AreUSureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
