<mat-toolbar >
    <span>{{text}}</span>
    <span class="example-spacer"></span>
    <span style="height: 90%;"> 
        <img style="height: 90%;" src={{logoSrc}}>
    </span>
    <span class="example-spacer"></span>
    <span class="span-logo-container"> 
        <img class="logo-image" src='assets/images/logo.png'>
    </span>
</mat-toolbar>
<img src="assets/images/mountain.png" style="position: absolute; bottom: 0; left: 0; right: 0; height: 10%; width: 100%; z-index: 10000; display:none;">
