
export const environment = {
  production: false,
  gamesAppUrl: "https://stage-play.yooz.plus/",
  firebaseConfig : {
    apiKey: "AIzaSyB6aDVh8aBrnbWPDs1J8j6E5i07wqgIjNw",
    authDomain: "yooz-staging.firebaseapp.com",
    projectId: "yooz-staging",
    storageBucket: "yooz-staging.appspot.com",
    messagingSenderId: "764796006072",
    appId: "1:764796006072:web:017dea91a3ab62b7228e4d",
    measurementId: "G-BYQNS97HEP"
  }
};