
import { Ng2ImgMaxService } from 'ng2-img-max';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalsService } from './../../globals.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { EditConfigurationComponent } from '../../dialogs/edit-configuration/edit-configuration.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { LoaderService } from 'src/app/loader/loader.service';
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of
// tslint:disable: forin
interface configuration{
  name;
  game;
}
@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.css']
})
export class ConfigurationsComponent implements OnInit {
  customerId: string;
  gameId: string;
  constructor(private firestore: AngularFirestore,
              private router: Router,
              public dialog: MatDialog,
              private loaderService : LoaderService
              ){
    this.gameId = this.router.getCurrentNavigation().extras.state.gameId;
    localStorage.setItem('configId', this.gameId);
    localStorage.setItem('subComponent', 'configurations');
    this.customerId = localStorage.getItem('customerId');
    this.loadTable();
  }
  loadTable(): void {
    this.dataSource = new MatTableDataSource<any>([{game: '', name: ''}]);
    this.firestore.collection('customers').doc(this.customerId).collection('gamesConfigurationSets').get().toPromise().then(configurations => {
      this.loaderService.show();
      this.getConfigurationsData(0, configurations.docs).then( () => {
        this.loaderService.hide();
        this.dataSource = new MatTableDataSource<any>(this.configurationArr);
        this.dataSource.paginator = this.paginator;
      });
    });
  }
  configurationArr = [];
  dataSource;
  displayedColumns: string[] = ['name', 'game', 'edit'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  getConfigurationsData(index: number, configurationsDocs): Promise<any>{
    const promises = [];
    configurationsDocs.forEach(configurationDoc => {
      promises.push(this.firestore.collection('games').doc(configurationDoc.data().gameId).get().toPromise().then(gameDoc => {
        if (configurationDoc.data().gameId === this.gameId && configurationDoc.data().isCopy !== true) {
          this.configurationArr.push({game: gameDoc.data().name, name: configurationDoc.data().name, configId: configurationDoc.id});
        }
      }));
    });
    return Promise.all(promises);
  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = "";
  }

  editPrassed(element): void{
    this.firestore
      .collection('customers')
      .doc(this.customerId)
      .collection('gamesConfigurationSets')
      .doc(element.configId)
      .get().toPromise().then(configDoc => {
        this.openDialog({
          configId: element.configId,
          gameId: configDoc.data().gameId,
          configurations: configDoc.data().configurations,
          name: configDoc.data().name,
          createConfig: false,
          image: configDoc.data().image
        }
        );
      });
  }
  createConfig(): void{
    this.openDialog({createConfig: true, gameId: this.gameId});
  }
  openDialog(data): void {
    const dialogRef = this.dialog.open(EditConfigurationComponent, {
      width: 'auto',
      data: data
    });

    dialogRef.afterClosed().subscribe( canceled => {
      if (!canceled){
        window.location.reload();
      }
    });
  }
}
