import { label, subcustomer } from './../dialogs/edit-row-dialog/edit-row-dialog.component';
// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Activity } from '../classes/activity';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class ActivitesService {

  constructor(private firestore: AngularFirestore) {}
  setActivity(data: Activity, Delete: boolean): Promise<void>{
    return this.firestore.collection('activities').doc(data.activityCode).set({componentsConfiguritionSets: data.componentsConfiguritions, activityType: data.activityType, creationDate: moment(data.creationDate).toDate(), activityLogo: data.activityLogo, activityName: data.activityName, 'end time': data.endDate.toDate(), 'start time': data.startDate.toDate(), loginType: data.loginType, isNameRequired: data.isNameRequired, gamesConfigurationSets: data.gamesConfiguritions, customerUID:  localStorage.getItem('customerId'), layoutId: data.layoutId, startGroup: data.startGroup, endGroup: data.endGroup, contactName: data.contactName, contactPhone: data.contactPhone, contactMail: data.contactMail, contactPosition: data.contactPosition, deleted: Delete, label: data.label, subcustomer: data.subcustomer, haveLeaderBoard: data.haveLeaderBoard});
  }
  private getDeltaInDays(date): number{
    const diff = Math.abs(new Date().getTime() - date.getTime());
    return  Math.ceil(diff / (1000 * 3600 * 24));
  }


  private compareFuncToSortActivityByCreationDate( activityA, activityB ) {
    const activityACreationTime = new Date (activityA.creationDate).getTime();
    const activityBCreationTime = new Date (activityB.creationDate).getTime();
    if ( activityACreationTime > activityBCreationTime ){
      return -1;
    }
    if ( activityACreationTime < activityBCreationTime ){
      return 1;
    }
    return 0;
  }

  getActivities(which: string, label='all', subcustomer='all', sortMethod=null): Promise<Activity[]>{
    const activities: Activity[] = [];
    return this.firestore
    .collection('activities', ref => ref.where('customerUID', '==', localStorage.getItem('customerId')))
    .get().toPromise().then( col => {
      col.docs.forEach( doc => {
        if (
          (doc.data().deleted === undefined || doc.data().deleted === false) &&
          (label === 'all' || (doc.data().label !== undefined && label === doc.data().label)) &&
          (subcustomer === 'all' ||
              (doc.data().subcustomer !== undefined && subcustomer === doc.data().subcustomer))){
            if (which === 'finished' && doc.data()['end time'].toDate() < new Date()){
              activities.push(new Activity(doc.data().layoutId, doc));
            }
            if (which === 'finishedRecenetly' && doc.data()['end time'].toDate() < new Date()){
              if (this.getDeltaInDays(doc.data()['end time'].toDate()) < 90){
                activities.push(new Activity(doc.data().layoutId, doc));
              }
            }
            if (which === 'notFinished' && doc.data()['end time'].toDate() > new Date()){
              activities.push(new Activity(doc.data().layoutId, doc));
            }
        }
      });

      if (sortMethod && sortMethod === 'sort-by-creation-date'){
        activities.sort( this.compareFuncToSortActivityByCreationDate );
      }
      return activities;
    });
  }

  deleteActivity(activityCode: string): void{
    this.firestore.collection('activities').doc(activityCode).delete();
  }

}
