import { ImageSnippet } from './../change-image-dialog/change-image-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalsService } from './../../globals.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { config } from 'process';
import { lchmod } from 'fs';
import { promise } from 'protractor';


// tslint:disable: prefer-for-of
// tslint:disable: max-line-length
export interface configurationData{
  name: string;
  header: string;
  description: string;
  link?: string;
  file?: File;
  url?: string;
  gameId?: string;
  configId?: string;
  surveyQuestions?: string[];
}

export const componentsId = {
  movie: 'U3yrqsw4kEGlJgkPeZxo',
  image: '5KoJq3O7vAW7bRxwvrY7',
  presentation: '5qXp60HM1svkMPK0RW8i',
  game: 'Uv34KePwYvCxVC9gToWu',
  satisfaction: 'ruvelQ6mbSE1Nm1W0dkc',
  textOnly: 'AqwsCjAR7AqPCEJOjGaU'
};

@Component({
  selector: 'app-create-component-configuration',
  templateUrl: './create-component-configuration.component.html',
  styleUrls: ['./create-component-configuration.component.css']
})

export class CreateComponentConfigurationComponent implements OnInit {
  header = '';
  disableSave = false;
  newConfig;
  configName = '';
  isCreate = false;
  customerId: string;
  configuration;
  configId;
  selectedComponent;
  isMovie = false;
  isSatisfaction = false;
  isImage = false;
  isPresentation = false;
  isGame = false;
  configHeader = '';
  configDescription = '';
  configLink = '';
  imgSrc;
  file: File;
  haveFile = false;
  selectedGame = '';
  selectedConfig = '';
  games = [];
  configs = [];
  loadingConfigs = true;
  haveImage = false;
  surveyQuestions = new FormArray([]);
  constructor(public dialogRef: MatDialogRef<CreateComponentConfigurationComponent>, private storage: AngularFireStorage,
              private firestore: AngularFirestore,
              @Inject(MAT_DIALOG_DATA) public data: any, private global: GlobalsService, private snackBar: MatSnackBar,
              private _formBuilder: FormBuilder) {
    dialogRef.disableClose = true;
    this.customerId = localStorage.getItem('customerId');
    this.selectedComponent = this.data.componentId;
    if (this.data.createConfig){
      this.isCreate = true;
      this.gameSelected();
      this.header = 'יצירת תחנה';
    }
    else{
      this.header = 'ערוך תחנה';
      this.configName = this.data.name;
      this.configId = this.data.configId;
      this.configuration = this.data.configurations;
    }

    if (this.selectedComponent === componentsId.movie){
      this.isMovie = true;
      this.setMovie();
    }
    else if (this.selectedComponent === componentsId.satisfaction){
      this.isSatisfaction = true;
      this.setSatisfaction();
    }
    else if (this.selectedComponent === componentsId.image ){
      this.isImage = true;
      this.setImage();
    }
    else if (this.selectedComponent === componentsId.textOnly ){
      this.setTextOnly();
    }
    else if (this.selectedComponent === componentsId.presentation){
      this.isPresentation = true;
      this.setPresentation();
    }
    else if (this.selectedComponent === componentsId.game){
      this.isGame = true;
      this.setGame();
    }
  }
  isGameIsAllreadyInDic(newGameId: string): boolean{
    for (let i = 0; i < this.games.length; i++){
      if (this.games[i].id === newGameId){
        return true;
      }
    }
    return false;
  }
  setGame(): void{
    this.firestore
      .collection('customers')
      .doc(localStorage.getItem('customerId'))
      .collection('layouts')
      .get()
      .toPromise()
      .then( layouts => {
        const promises = [];
        layouts.forEach(layout => {
          if (layout.data().type === 'course') {
            for (let i = 0; i < layout.data().games.length; i += 1) {
              promises.push(
                this.firestore
                  .collection('games').doc(layout.data().games[i]).get().toPromise().then( game => {
                    if (!this.isGameIsAllreadyInDic(game.id)) {
                      this.games.push({name: game.data().name, id: game.id});
                    }
                  }
                )
              );
            }
          }
        })
        Promise.all(promises).then(() => {
          if (!this.data.createConfig){
            this.configHeader = this.configuration.header;
            this.configDescription = this.configuration.description;
            this.selectedGame = this.configuration.gameId;
            this.gameReselected();
          }
        });
      });
  }
  setImage(): void{
    if (!this.data.createConfig){
      this.configHeader = this.configuration.header;
      this.configDescription = this.configuration.description;
      this.imgSrc = this.configuration.url;
      this.haveFile = true;
    }
  }
  setTextOnly(): void{
    if (!this.data.createConfig){
      this.configHeader = this.configuration.header;
      this.configDescription = this.configuration.description;
    }
  }

  setPresentation(): void{
    if (!this.data.createConfig){
      this.configHeader = this.configuration.header;
      this.configDescription = this.configuration.description;
      this.haveFile = true;
    }
  }
  setSatisfaction(): void{
    if (!this.data.createConfig){
      this.configHeader = this.configuration.header;
      this.configDescription = this.configuration.description;
      for (let i = 0; i < this.configuration.surveyQuestions.length; i++){
        this.surveyQuestions.insert(i, new FormControl(this.configuration.surveyQuestions[i], [Validators.required]));
      }
    }
    else{
      this.surveyQuestions.insert(0, new FormControl('', [Validators.required]));
    }
  }
  setMovie(): void{
    if (!this.data.createConfig){
      this.configHeader = this.configuration.header;
      this.configDescription = this.configuration.description;
      this.configLink = this.configuration.link;
    }
  }
  addSurveyQuestion(): void{
    this.surveyQuestions.insert(this.surveyQuestions.length, new FormControl('', [Validators.required]));
  }
  removeQuestion(index): void{
    this.surveyQuestions.removeAt(index);
  }
  onFileChanged(event: any): void {
    const file: File = event.target.files[0];
    this.file = file;
    const reader = new FileReader();
    reader.onload = (e => {
      this.imgSrc = e.target.result;
      this.haveImage = true;
    });
    reader.readAsDataURL(file);
  }
  gameReselected(): void{
    this.configs = [];
    this.firestore
      .collection('games')
      .doc(this.selectedGame)
      .get()
      .toPromise()
      .then(gameDoc => {
        if (gameDoc.data().isConfigurability){
          this.loadingConfigs = false;
          this.firestore
            .collection('customers')
            .doc(localStorage.getItem('customerId'))
            .collection('gamesConfigurationSets')
            .ref.where('gameId', '==', this.selectedGame)
            .get()
            .then(configurations => {
              configurations.docs.forEach(config => {
                if (config.data().isCopy === undefined){
                  this.configs.push({name: config.data().name, id: config.id});
                }
                else if (config.data().isCopy === false){
                  this.configs.push({name: config.data().name, id: config.id});
                }
              });
              this.selectedConfig = this.configuration.configId;
            });
        }
        else {
          this.selectedConfig = 'notConfigurability';
          this.loadingConfigs = true;
        }
      });
  }
  getUUID(): string{
    var short = require('short-uuid');
    var translator = short();
    return translator.new();
  }
  gameSelected(): void{
    this.data.componentId = this.selectedComponent;
    this.data.configId = this.getUUID();
    this.configuration = new Map();
  }
  ngOnInit(): void {
  }

  private isGeneralInputLegal(duration, direction): boolean {
    if (this.configName === '' ){
      this.snackBar.open('חובה להכניס שם להגדרה', '', {duration, direction});
      return false;
    }
    if (this.configHeader === ''){
      this.snackBar.open('חובה להכניס כותרת לתחנה', '', {duration, direction});
      return false;
    }
    if (this.configDescription === ''){
      this.snackBar.open('חובה להכניס תיאור לתחנה', '', {duration, direction});
      return false;
    }
    return true;
  }

  private getComponentConfigData(duration, direction): configurationData {
    const configData: configurationData = {
      name: this.configName,
      header: this.configHeader,
      description: this.configDescription
    };
    if (this.selectedComponent === componentsId.movie){
      if (this.configLink === ''){
        this.snackBar.open('חובה להכניס קישור ', '', {duration, direction});
        return null;
      }
      configData.link = this.configLink;
    }
    else if ( this.selectedComponent === componentsId.image ||
              this.selectedComponent === componentsId.presentation){
      if (this.file === undefined && !this.haveFile){
        this.snackBar.open('חובה להכניס קובץ ', '', {duration, direction});
        return null;
      }
      else if (this.haveFile){
        configData.url = this.configuration.url;
      }
      configData.file = this.file;
    }
    else if (this.selectedComponent === componentsId.game){
      if (this.selectedGame === '' ){
        this.snackBar.open('חובה לבחור משחק ', '', {duration, direction});
        return null;
      }
      if (!this.selectedConfig){
        this.snackBar.open('חובה לבחור הגדרה למשחק ', '', {duration, direction});
        return null;
      }
      configData.configId = this.selectedConfig;
      configData.gameId = this.selectedGame;
    }
    else if (this.selectedComponent === componentsId.satisfaction){
      if (!this.surveyQuestions.valid){
        this.snackBar.open('חובה להכניס שאלות לסקר חוות דעת ', '', {duration, direction});
        return null;
      }
      configData.surveyQuestions = this.surveyQuestions.value;
    }
    return configData;
  }

  private setCustomerComponentsConfigurationSetsOnFirebase(data, configId, duration, direction) {
    this.firestore
      .collection('customers')
      .doc(this.customerId)
      .collection('componentsConfigurationSets')
      .doc(configId)
      .set({...data}).then( err => {
        this.dialogRef.close({created: this.isCreate, confId: configId, confName: this.configName});
        this.snackBar.open('ההגדרה עודכנה בהצלחה', '', {duration, direction});
      });
  }
  trackByFn(index, item) {
    return index;
  }
  saveConfig(): void{
    if(!this.data.editable) return;
    const duration = 5000;
    const dir = 'rtl';
    if (!this.isGeneralInputLegal(duration, dir)){
      return;
    }
    const configData = this.getComponentConfigData(duration, dir);
    if (!configData){
      return;
    }

    this.disableSave = true;
    const result = {
      config: configData,
      componentId: this.selectedComponent,
      configId: this.data.configId,
      name: this.configName,
      createNew: this.data.createConfig
    };

    const resultConfig: configurationData = result.config;
    if (this.selectedComponent === componentsId.satisfaction ||
        this.selectedComponent === componentsId.movie ||
        this.selectedComponent === componentsId.game ||
        this.selectedComponent === componentsId.textOnly)
      {
        const data = {
          componentId: this.selectedComponent,
          configurations: result.config,
          name: result.name
        };
        this.setCustomerComponentsConfigurationSetsOnFirebase(data, result.configId, duration, dir);
    }
    else if (this.selectedComponent === componentsId.presentation ||
              this.selectedComponent === componentsId.image)
    {
      if (resultConfig.file === undefined){
        const data = {
          componentId: this.selectedComponent,
          configurations: {
            header: resultConfig.header,
            description: resultConfig.description,
            url: resultConfig.url
          },
          name: result.name
        };
        this.setCustomerComponentsConfigurationSetsOnFirebase(data, result.configId, duration, dir);
      }
      else{
        this.saveImage(resultConfig.file, result.configId).then(url => {
          resultConfig.url = url;
          const data = {
            componentId: this.selectedComponent,
            configurations: {
              header: resultConfig.header,
              description: resultConfig.description,
              url: resultConfig.url
            },
            name: result.name
          };
          this.setCustomerComponentsConfigurationSetsOnFirebase(data, result.configId, duration, dir);
        });
      }
    }
  }

  saveImage(file: File, componentId: string): Promise<string> {
    return this.storage
      .upload('components-files/' + componentId + '/' + file.name, file)
      .then(
        snapshot => {
          return snapshot.ref.getDownloadURL().then(URL => {
            return URL;
          });
        },
        err => {
          this.snackBar.open('error while upload file', '', {
            duration: 2000,
          });
        }
      );
  }
  onNoClick(): void{
    this.dialogRef.close();
  }
}
