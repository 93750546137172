

<div id="previewTable"  class="mat-elevation-z8 table-wrapper">

    <table  mat-table  [dataSource]="dataSource" matSort style="margin-top: 2vh;">

      <ng-container matColumnDef="activityCode">
        <th  mat-header-cell *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;" class="table-head"> קוד הפעילות </th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
          {{element.activityCode}}
        </td>
      </ng-container>

      <ng-container matColumnDef="activityName" >
        <th  mat-header-cell  *matHeaderCellDef style="text-align: center;padding-inline-end: 8px !important;"> שם הפעילות </th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important; width:200px">
            {{element.activityName}}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="activityType" >
        <th mat-header-cell  *matHeaderCellDef style="text-align: center;padding-inline-end: 8px !important;">סוג הפעילות</th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
            {{element.activityType === 'course'  ? 'קורס' : 'משחקים'}}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="startDate">
        <th  mat-header-cell  *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;"> תאריך התחלה </th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
          {{element.startDate.locale('he').format('MMMM Do YYYY, h:mm:ss a')}}
        </td>
      </ng-container>
  
      <ng-container  matColumnDef="endDate">
        <th  mat-header-cell  *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;"> תאריך סיום </th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important;">
          {{element.endDate.locale('he').format('MMMM Do YYYY, h:mm:ss a')}}
        </td>
      </ng-container>

      <ng-container  matColumnDef="status">
        <th  mat-header-cell  *matHeaderCellDef  style="text-align: center;padding-inline-end: 8px !important;"> סטטוס </th>
        <td  mat-cell *matCellDef="let element"  style="text-align: center;padding-inline-end: 8px !important">
          {{element.endDate.toDate() < now ? "הסתיים" : element.startDate.toDate() < now ? "פעיל" : "עוד לא התחיל" }}
        </td>
      </ng-container>

      <ng-container  matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="text-align: center;padding-inline-end: 8px !important;"> 
          <button matTooltip="צפייה בפעילות" class="material-icons color_green" [ngStyle]="{'color': '#741ba1;'}"  [hidden]="element.startDate.toDate() < now" mat-button (click)="editPrassed(element)">
            <mat-icon aria-hidden="false" aria-label="Example home icon" class="material-icons color_green button-icon" style="margin-top: 11px;" svgIcon="icon_preview"></mat-icon>
          </button>
          <button matTooltip="הורדת דוחות" disabled="{{element.endDate.toDate() > now}}" mat-button (click)="resultPrassed(element)">
            <mat-icon aria-hidden="false" aria-label="Example home icon">description</mat-icon>
          </button>
          <button matTooltip="שכפול פעילות" disabled="{{element.endDate.toDate() > now}}" mat-button (click)="duplicatePrassed(element)">
            <mat-icon aria-hidden="false" aria-label="Example home icon" >file_copy</mat-icon>
          </button>
          <button matTooltip="מחיקת פעילות" disabled="{{element.endDate.toDate() > now}}" mat-button (click)="deletePrassed(element)">
            <mat-icon aria-hidden="false" aria-label="Example home icon" >delete</mat-icon>
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    <mat-slide-toggle color='primary' style="padding: 1em;" (change)='filterChanged($event.checked)' [checked]='recentOnly'>הסתיים לאחרונה בלבד</mat-slide-toggle>
    <mat-form-field style="padding: 1em; width: 10%;" appearance="fill">
      <mat-select (selectionChange)='filterChanged()' [(value)]='currentLabel'>
        <mat-option value="all">כל התגיות</mat-option>
        <mat-option *ngFor="let label of labels" [value]="label.id">
          {{label.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="padding: 1em; width: 10%;" appearance="fill">
      <mat-select (selectionChange)='filterChanged()' [(value)]='currentSubcustomer'>
        <mat-option value="all">כל הלקוחות</mat-option>
        <mat-option *ngFor="let subcustomer of subcustomers" [value]="subcustomer.id">
          {{subcustomer.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>
