import { label } from './../dialogs/edit-row-dialog/edit-row-dialog.component';
import { GameConf, GameConfs } from '../dialogs/edit-row-dialog/edit-row-dialog.component';
import * as moment from 'moment';

export class Activity{
    label: string;
    activityCode: string;
    activityName: string;
    contactName: string;
    contactPhone: string;
    contactPosition: string;
    contactMail: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    gamesConfiguritions: Array<any>;
    layoutId: string;
    loginType: string;
    isNameRequired: boolean;
    activityLogo: any;
    startGroup: number;
    endGroup: number;
    creationDate: Date;
    activityType: string;
    subcustomer: string;
    haveLeaderBoard: boolean;
    componentsConfiguritions: Array<any>;
    constructor(layoutId: string, activityDoc?){
        this.loginType = activityDoc && activityDoc.data().loginType || '';
        this.isNameRequired = activityDoc && activityDoc.data().isNameRequired || false;
        this.activityCode = activityDoc && activityDoc.id || makeid(6);
        this.contactName = activityDoc && activityDoc.data().contactName || '';
        this.contactPhone = activityDoc && activityDoc.data().contactPhone || '';
        this.contactMail = activityDoc && activityDoc.data().contactMail || '';
        this.activityName = activityDoc && activityDoc.data().activityName || '';
        this.activityType = activityDoc && activityDoc.data().activityType || null;
        this.contactPosition = activityDoc && activityDoc.data().contactPosition || '';
        this.startDate = activityDoc && moment(activityDoc.data()['start time'].toDate()) || null;
        this.creationDate = activityDoc && activityDoc.data().creationDate.toDate() || new Date();
        this.endDate = activityDoc && moment(activityDoc.data()['end time'].toDate()) || null;
        this.gamesConfiguritions = activityDoc && activityDoc.data().gamesConfigurationSets || [];
        this.componentsConfiguritions = activityDoc && activityDoc.data().componentsConfiguritionSets || [];
        this.layoutId = activityDoc && activityDoc.data().layoutId || layoutId;
        this.activityLogo = activityDoc && activityDoc.data().activityLogo || 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png';
        this.startGroup = activityDoc && activityDoc.data().startGroup || 1;
        this.endGroup = activityDoc && activityDoc.data().endGroup || 5;
        this.label = activityDoc && activityDoc.data().label || '';
        this.subcustomer = activityDoc && activityDoc.data().subcustomer || '';
        this.haveLeaderBoard = activityDoc && activityDoc.data().haveLeaderBoard || false;
    }
}
function makeid(length): string {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
