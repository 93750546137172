import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GlobalsService } from './../../globals.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { AngularFireStorage } from '@angular/fire/storage';
import { promise } from 'protractor';
import { from } from 'rxjs';

// tslint:disable: prefer-for-of
// tslint:disable: max-line-length
// tslint:disable: forin
export const gamesID = {ballGame: 'u7HhTRF7IjznvxRQ6JXw', jigsaw: 'I7wBOeWZqdDXUkpIN3g2', memory: 'L3txSVIbfBX93EoqEj7C', groupTrivia: 'unItB2jC2iH3ShsFJirl', pairGame: 'jyeKPwy8f2CUmq1emCM9', factoryGame: 'NNdr1NRKTfWQlFwKfM5d', numbers: 'MKJ32JRmurrkIxXAuhvB', trueFalse: 'mhPqktjWFCLNEfqFhOhO'};

const duration = 5000;
const dir = 'rtl';
@Injectable({
  providedIn: 'root'
})
export class SaveConfigService {

  private customerId: string;
  constructor(private firestore: AngularFirestore,
              private golbal: GlobalsService,
              private router: Router,
              private snackBar: MatSnackBar,
              private storage: AngularFireStorage,
              private ng2ImgMax: Ng2ImgMaxService) {}

  save(result, customerId): Promise<any>{
    this.customerId = customerId;
    if (!result) return;
    return this.isActiveConfig(result).then(isActivate => {
      if (isActivate[0] !== undefined){
        return this.firestore
          .collection('customers')
          .doc(this.customerId)
          .collection('gamesConfigurationSets')
          .doc(result.configId)
          .get()
          .toPromise()
          .then(doc => {
            return this.firestore
              .collection('customers')
              .doc(this.customerId)
              .collection('gamesConfigurationSets')
              .add(doc.data())
              .then(copyDoc => {
                const promises = [];
                promises.push(this.saveConigToFirebase(result));
                promises.push(copyDoc.update({isCopy: true, originalConfig: result.configId}));
                isActivate.forEach(element => {
                  promises.push(this.firestore.collection('activities').doc(element).get().toPromise().then(activityDoc => {
                    const set = {};
                    for (const key in activityDoc.data().gamesConfigurationSets) {
                      const value = activityDoc.data().gamesConfigurationSets[key];
                      if (value === result.configId){
                        set[key] = copyDoc.id;
                      }
                      else {
                        set[key] = value;
                      }
                    }
                    promises.push(this.firestore.collection('activities').doc(element).update({gamesConfigurationSets: set}));
                  }));
                });
              return Promise.all(promises);
            });
          });
      }
      else{
        return this.saveConigToFirebase(result);
      }
    });
  }
  private isActiveConfig(result): Promise<string[]>{
    return this.firestore
      .collection('activities')
      .ref
      .where('customerUID', '==', localStorage.getItem('customerId'))
      .get()
      .then(activities => {
        const arr: string[] = [];
        for (let index = 0; index < activities.docs.length; index++) {
          const activityDoc = activities.docs[index];
          if (activityDoc.data()['start time'].toDate() < new Date() && activityDoc.data()['end time'].toDate() > new Date()){
            if (activityDoc.data().gamesConfigurationSets[result.gameID] === result.configId){
              arr.push(activityDoc.id);
            }
          }
        }
        return arr;
      });
  }

  private saveConigToFirebase(result): Promise<any>{
    if (result.gameID === gamesID.memory || result.gameID === gamesID.numbers || result.gameID === gamesID.ballGame || result.gameID === gamesID.pairGame || result.gameID === gamesID.factoryGame || result.gameID === gamesID.numbers || result.gameID === gamesID.trueFalse || result.gameID === gamesID.groupTrivia){
      return this.firestore.collection('customers').doc(this.customerId).collection('gamesConfigurationSets').doc(result.configId).set({gameId: result.gameID, configurations: result.config, name: result.name}).then( err => {
        this.snackBar.open('ההגדרה נוצרה בהצלחה', '', {duration: duration, direction: dir});
      });
    }
    if (result.gameID === gamesID.jigsaw || result.gameID === gamesID.memory){
      return this.firestore.collection('customers').doc(this.customerId).collection('gamesConfigurationSets').doc(result.configId).set({gameId: result.gameID, configurations: result.config, name: result.name, image: result.image}).then( err => {
        this.snackBar.open('ההגדרה נוצרה בהצלחה', '', {duration: duration, direction: dir});
      });
    }
  }
}
