import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-layout-desctiption',
  templateUrl: './layout-desctiption.component.html',
  styleUrls: ['./layout-desctiption.component.css']
})
export class LayoutDesctiptionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LayoutDesctiptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
