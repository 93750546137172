import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.css']
})
export class LibraryComponent implements OnInit {

  operatingInstructionDisabled = false;
  constructor() {
    localStorage.setItem('subComponent', 'library');
  }

  ngOnInit(): void {
  }
  openDriveFolder(path: string): void{
    this.operatingInstructionDisabled = true;
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = path;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
    this.operatingInstructionDisabled = false;
  }
}
