<h1 dir="rtl" mat-dialog-title class="center">שתף פעילות</h1>
<div dir="rtl" mat-dialog-content >
  <div style="text-align: center;">
    <ngx-qrcode [elementType]="elementType" [value]="value"></ngx-qrcode>
    <button  style="top: -16px;" mat-icon-button color="primary" (click)='downloadImage()'>
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>
  <div style="width: 100%; text-align: center;">
  <mat-form-field style="width: 100%;" appearance="outline">
    <mat-label>קישור לפעילות</mat-label>
    <input #inputEl (click)="copyLink(tooltip, inputEl)" color="primary" readonly matInput [value]="value">
    <div style="display: flex; flex-direction: row;" matSuffix>
      <button matTooltipDisabled="true"
      #tooltip="matTooltip"
      matTooltip="URL Copied!"
      matTooltipPosition="above"
      matTooltipHideDelay="2000" color="primary" mat-icon-button matSuffix (click)="copyLink(tooltip, inputEl)" >
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
    
  </mat-form-field>
  </div>
  <div style="width: 100%; text-align: center;">
    
    
  </div>
</div>
<div mat-dialog-actions>
  <button  mat-button  color="primary" (click)="dialogRef.close(0);">סגור</button>

</div>