<mat-toolbar #toolBar style="height: 100px;">
    <button *ngIf='isMobile' (click)='sidenav.toggle()' mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button>
    <div style="margin-right: 20%;">
      <span class="navigation-line">&nbsp;</span> 
      <span class="navigation-title">{{currentRouteName}}</span>
    </div>
    <span class="example-spacer"></span>
    <span style="height: 90%; margin-left: 155px;"> 
      <img style="height: 90%;" src={{logoSrc}}>
    </span>
    <span class="example-spacer"></span>
    <span *ngIf="logoPosition === 'top'"> 
        <img class="logo-image" src='assets/images/logo.png'>
    </span> 
    <div *ngIf="logoPosition === 'bottom'" class="bottom-logo">
      <img class="logo-image" src='assets/images/logo.png'>
    </div>
</mat-toolbar>
<mat-sidenav-container class="cmat-sidenav-container" >
    <mat-sidenav [mode]="isMobile ? 'over' : 'side'" #sidenav class="mat-sidenav" >
      <div>
      <mat-nav-list style="padding-top: 40px; padding-bottom: 40px; direction: rtl;" id="navList">
        <app-menu-list-item [toggle]='isMobile' *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
        <a mat-list-item class="logout-list-item" style="margin-right: 15px; -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" unselectable="on" onselectstart="return false;" onmousedown="return false;" (click)="logOut()">
          <div class="div-bg-select-item">
            <img src="assets/images/menu_select_mask.svg"/>
           </div>
          <mat-icon svgIcon="icon_logout" class="route-image" style="padding-inline-start: 0px;"></mat-icon>
          <span style="color: #ffffff;">התנתקות</span>
        </a>
      </mat-nav-list>
    </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div style="position: relative; z-index: 1; display: block; height: 100%; overflow: hidden; background-color: #e9e9f4;">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>