import { CustomerStatusReportService } from './../services/customer-status-report.service';
// tslint:disable: max-line-length
// tslint:disable: prefer-for-of
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { avaibleLanguages } from './../../environments/languages';
import { Router } from '@angular/router';
import { GlobalsService } from './../globals.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import getUserLocale from 'get-user-locale';
import { NavItem } from '../nav-item';
import { NavService } from '../nav.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSidenav } from '@angular/material/sidenav';
import { AngularFireAuth } from '@angular/fire/auth';
import { AreUSureDialogComponent } from '../dialogs/are-u-sure-dialog/are-u-sure-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  settingsAvaible = false;
  logoSrc = '';
  switchLanguageAvaible = false;
  switchLayoutAvaible = false;
  layouts = [];
  games = [];
  subComponent = 'statistics';
  configGameId: string;
  currentRouteName: string;
  logoPosition: string = 'top';
  navItems: NavItem[] = [
    {
      displayName: 'ראשי',
      iconName: 'icon_home',
      route: 'main/home',
      children: null,
      logoPosition: 'top',
    },
    {
      displayName: 'תכנון פעילות',
      iconName: 'icon_plan_game',
      route: 'main/plan-activity',
      children: null,
      logoPosition: 'top',
    },
    {
      displayName: 'מעקב ובקרה',
      iconName: 'icon_control_and_track',
      route: 'main/statistics',
      children: null,
      logoPosition: 'top',
    },
    {
      displayName: 'פעילויות',
      iconName: 'icon_puzle',
      route: 'main/activities',
      children: null,
      logoPosition: 'top',
    },
    {
      displayName: 'ארכיון פעילויות',
      iconName: 'icon_activity-arhive',
      route: 'main/archive',
      children: null,
      logoPosition: 'top',
    },
    {
      displayName: 'הגדרות משחקים',
      iconName: 'icon_settings',
      route: 'main/configurations',
      logoPosition: 'top',
      children: [{
        displayName: 'טוען משחקים...',
        iconName: 'icon_dot',
        route: 'main/statistics',
        children: null,
        extras: { action: false},
      }]
    },
    {
      displayName: 'ספריה',
      iconName: 'icon_library',
      route: 'main/library',
      children: null,
      logoPosition: 'top',
    }
  ];
  isMobile = true;
  language = 'en';
  constructor(private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private global: GlobalsService,
    private router: Router, private snackBar: MatSnackBar,
    private fireauth: AngularFireAuth,
    private firestore: AngularFirestore,
    private navService: NavService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private CustomerStatusReportService: CustomerStatusReportService) {
    this.isMobile = this.deviceService.isMobile();
    if (localStorage.getItem('customerId')){
      this.firestore.collection('customers').doc(localStorage.getItem('customerId')).get().toPromise().then( customerDoc => {
        this.logoSrc = customerDoc.data().url;
        this.language = customerDoc.data().language;
      });
    }
    if (localStorage.getItem('configId') != null){
      this.configGameId = localStorage.getItem('configId');
    }
    if (localStorage.getItem('subComponent') != null){
      this.subComponent = localStorage.getItem('subComponent');
      if (localStorage.getItem('subComponent') === 'configurations'){
        this.router.navigateByUrl('/main/' + this.subComponent, { state: { gameId: localStorage.getItem('configId')} });
      }
      else if (localStorage.getItem('subComponent') === 'component'){
        this.router.navigateByUrl('/main/' + this.subComponent, { state: { componentId: localStorage.getItem('configId')} });
      }
      else{
        this.router.navigateByUrl('/main/' + this.subComponent);
      }
    }
    this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('layouts').get().toPromise().then(layoutsArr => {
      this.layouts = [];
      for (let i = 0; i < layoutsArr.docs.length; i += 1) {
        const layout = layoutsArr.docs[i];
        this.layouts.push({name: layout.data().name, id: layout.id});
      }
      if (this.layouts.length < 2){
        this.switchLayoutAvaible = false;
      }
      else{
        this.switchLayoutAvaible = true;
      }
    });
    this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('layouts').get().toPromise().then( layouts => {
      layouts.forEach(layout => {
        for (let i = 0; i < layout.data().games.length; i += 1) {
          this.firestore.collection('games').doc(layout.data().games[i]).get().toPromise().then( game => {
            if (this.navItems[5].children.find(x => x.extras.gameId === game.id) === undefined){
              if (game.data().isConfigurability){
                if (this.navItems[5].children[0].extras.action === false){
                  this.navItems[5].children.splice(0, 1);
                }
                this.navItems[5].children.push({displayName: game.data().name[this.language], iconName: 'icon_dot', route: 'main/configurations', extras: {gameId: game.id}});
              }
            }
          });
        }
        if (layout.data().type === 'course'){
          this.setForCourse();
        }
      })
    });
    //set current page
    this.navigationHandler();

    this.matIconRegistry.addSvgIcon(`icon_logout`, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icon_logout.svg`));
  }
  setForCourse(): void{
    this.navItems.push({
      displayName: 'הגדרת תחנות',
      iconName: 'icon_settings',
      route: 'main/component',
      logoPosition: 'top',
      children: [{
        displayName: 'טוען תחנות...',
        iconName: 'icon_dot',
        route: null,
        children: null,
        extras: { action: false },
        logoPosition: 'top',
      }]
    });

    this.firestore.collection('components').get().toPromise().then(components => {
      components.docs.forEach(component => {
        if (this.navItems[7].children[0].extras.action === false){
          this.navItems[7].children.splice(0, 1);
        }
        this.navItems[7].children.push({displayName: component.data().name[this.language], iconName: 'icon_dot', route: 'main/component', extras: {componentId: component.id}});
      });
    });
  }
  ngOnInit(): void {
  }
  switchLanguage(): void{
    if (this.global.language === 'en'){
      this.global.language = getUserLocale();
      if (!avaibleLanguages.includes(this.global.language)){
        this.global.language = 'en';
      }
    }
    else{
      this.global.language = 'en';
    }
    this.snackBar.open('language has been changed to ' + this.global.language, '', {duration: 2000});
  }
  moveToSettings(): void{
    this.router.navigateByUrl('/settings');
  }


  logOut(): void{
    const dialogRef = this.dialog.open(AreUSureDialogComponent, {
      width: '350px',
      data: {
        title: '  האם ברצונך להתנתק?',
        ok: 'התנתק'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        window.localStorage.clear();
        this.fireauth.signOut().then(() => {
          this.router.navigateByUrl('/login');
          localStorage.removeItem('uid');
          localStorage.removeItem('customerId');
        });
      }
    });

  }
  ngAfterViewInit(): void {
    this.navService.appDrawer = this.sidenav;
    if (!this.isMobile){
      setTimeout(() => {
        this.navService.toggleNav();
      }, 10);
    }
  }

  navigationHandler():void{
    this.router.events.subscribe((routeObj:any) => {
      this.navItems.forEach(el => {
        if(el.route && routeObj.url){
          //console.log("current route: ", el.route.split('main'), routeObj.url.split('main'));
          if(el.route.split('main')[1] === routeObj.url.split('main')[1]){
            this.currentRouteName = el.displayName;
            this.logoPosition = el.logoPosition;
          }
        }
      });

      if(routeObj.url && routeObj.url.split('main')[1] === '/component'){
        this.currentRouteName = 'הגדרת תחנות';
      }
    });
  }

}
function search(nameKey, myArray){
  for (var i=0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
          return myArray[i];
      }
  }
}

