import { SaveConfigService } from './dialogs/edit-configuration/save-config.service';
import { DashboardService } from './services/dashboard.service';
import { StatisticsService } from './tabs/statistics/statistics.service';
// tslint:disable: max-line-length
import { GlobalsService } from './globals.service';
import { environment } from './../environments/environment';

import { LoginService } from './services/login-service.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { EditRowDialogComponent } from './dialogs/edit-row-dialog/edit-row-dialog.component';
import { statisticsComponent } from './tabs/statistics/statistics.component';
import { ActivitiesTableComponent } from './tabs/activities-table/activities-table.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule } from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { ActivitesService } from './services/activites.service';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatStepperModule} from '@angular/material/stepper';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSortModule} from '@angular/material/sort';
import { HttpClientModule } from "@angular/common/http";

import { Ng5SliderModule } from 'ng5-slider';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgNavigatorShareService } from 'ng-navigator-share';

import {MatDatetimepickerModule, MAT_DATETIME_FORMATS} from '@mat-datetimepicker/core';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import { MAT_MOMENT_DATETIME_FORMATS } from './../adapter/moment-datetime-formats';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { SettingsComponent } from './settings/settings.component';
import { ChangeEmailDialogComponent } from './dialogs/change-email-dialog/change-email-dialog.component';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { ChangeImageDialogComponent } from './dialogs/change-image-dialog/change-image-dialog.component';
import {ConfigurationsComponent} from './tabs/configurations/configurations.component';
import { EditConfigurationComponent } from './dialogs/edit-configuration/edit-configuration.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { CommonModule } from '@angular/common';
import { planActivityComponent } from './tabs/plan-activity/plan-activity.component';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { ShareActivityComponent } from './dialogs/share-activity/share-activity.component';
import { MainComponent } from './main/main.component';
import { ReportsComponent } from './dialogs/reports/reports.component';
import {MatTreeModule} from '@angular/material/tree';
import { NavService } from './nav.service';
import { AreUSureDialogComponent } from './dialogs/are-u-sure-dialog/are-u-sure-dialog.component';
import { ActivitiesArchiveComponent } from './tabs/activities-archive/activities-archive.component';
import { LeaderboardComponent } from './dialogs/leaderboard/leaderboard.component';
import { DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ComponentsComponent } from './tabs/components/components.component';
import { CreateComponentConfigurationComponent } from './dialogs/create-component-configuration/create-component-configuration.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { NotfoundComponent } from './notfound/notfound.component';

import { ChartsModule } from 'ng2-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { LayoutDesctiptionComponent } from './dialogs/layout-desctiption/layout-desctiption.component';
import {MatRadioModule} from '@angular/material/radio';
import { LoaderService } from './loader/loader.service';
import {LoaderComponent} from './loader/loader.component';
import { HomeComponent } from './home/home.component';
import {getHebrewPaginatorIntl} from '../environments/hebrew-paginator-intl';
import { CreateLabelComponent } from './dialogs/create-label/create-label.component';
import { CreateSubcustomerComponent } from './dialogs/create-subcustomer/create-subcustomer.component';
import { LibraryComponent } from './tabs/library/library.component'


const modules = [BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  MatCardModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  MatIconModule,
  FormsModule,
  ReactiveFormsModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatFormFieldModule,
  MatToolbarModule,
  AngularFireModule.initializeApp(environment.firebaseConfig),
  AngularFirestoreModule,
  AngularFireStorageModule,
  AngularFireFunctionsModule,
  AngularFireAuthModule,
  AngularFirePerformanceModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  Ng2ImgMaxModule,
  MatStepperModule,
  MatListModule,
  CommonModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatMomentDatetimeModule,
  MatDatetimepickerModule,
  MatProgressBarModule,
  Ng5SliderModule,
  NgxQRCodeModule,
  MatTooltipModule,
  MatTreeModule,
  ColorPickerModule,
  MatSortModule,
  ChartsModule,
  FlexLayoutModule,
  DragDropModule,
  MatGridListModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatRadioModule,
  HttpClientModule
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    statisticsComponent,
    ActivitiesTableComponent,
    EditRowDialogComponent,
    PageHeaderComponent,
    SettingsComponent,
    ChangeEmailDialogComponent,
    ChangePasswordDialogComponent,
    ChangeImageDialogComponent,
    ConfigurationsComponent,
    EditConfigurationComponent,
    planActivityComponent,
    ShareActivityComponent,
    MainComponent,
    ReportsComponent,
    MenuListItemComponent,
    AreUSureDialogComponent,
    ActivitiesArchiveComponent,
    LeaderboardComponent,
    ComponentsComponent,
    CreateComponentConfigurationComponent,
    LayoutDesctiptionComponent,
    NotfoundComponent,
    LoaderComponent,
    HomeComponent,
    CreateLabelComponent,
    CreateSubcustomerComponent,
    LibraryComponent
  ],
  imports: [...modules],
  providers: [DashboardService, SaveConfigService, StatisticsService, LoginService, ActivitesService, GlobalsService, NavService, Ng2ImgMaxService, 
              NgNavigatorShareService, LoaderService, 
             {provide: MAT_DATE_LOCALE, useValue: 'he-IL'},
             {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]}, 
             {provide: MAT_DATETIME_FORMATS, useValue: MAT_MOMENT_DATETIME_FORMATS},
             { provide: MatPaginatorIntl, useValue: getHebrewPaginatorIntl() }
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
