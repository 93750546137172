import { SaveConfigService } from './save-config.service';
import { GlobalsService } from './../../globals.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms';

// tslint:disable: prefer-for-of
// tslint:disable: max-line-length
export const gamesID = {ballGame: 'u7HhTRF7IjznvxRQ6JXw', jigsaw: 'I7wBOeWZqdDXUkpIN3g2', memory: 'L3txSVIbfBX93EoqEj7C', groupTrivia: 'unItB2jC2iH3ShsFJirl', pairGame: 'jyeKPwy8f2CUmq1emCM9', factoryGame: 'NNdr1NRKTfWQlFwKfM5d', numbers: 'MKJ32JRmurrkIxXAuhvB', trueFalse: 'mhPqktjWFCLNEfqFhOhO'};
@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.css']
})
export class EditConfigurationComponent implements OnInit {
  formControler = new FormGroup({
    configName: new FormControl('', [Validators.required])
  });
  questionsContorlersArr: FormArray = new FormArray([]);
  header = '';
  isJigsaw = false;
  isBalls = false;
  isMemory = false;
  isGroupTrivia = false;
  isTrueFalse = false;
  isPairGame = false;
  isNumbers = false;
  disableSave = false;
  configId: string;
  imageLoading = false;
  gameMinQuestions = 0;
  constructor(public dialogRef: MatDialogRef<EditConfigurationComponent>, private storage: AngularFireStorage,
              @Inject(MAT_DIALOG_DATA) public data: any, private global: GlobalsService, private snackBar: MatSnackBar,
              private saveConfigService: SaveConfigService,
              ) {
    dialogRef.disableClose = true;
    if (this.data.configId === undefined){
      this.header = 'יצירת תצורה';
      this.configId = this.getUUID();
    }
    else{
      this.header = 'ערוך תצורה';
      this.formControler.get('configName').setValue(this.data.name);
      this.configId = this.data.configId;
    }
    console.log(this.configId);
    
    if (this.data.gameId === gamesID.ballGame) {
      this.isBalls = true;
      this.setForBalls();
    }
    else if (this.data.gameId === gamesID.factoryGame) {
      this.setForFactory();
    }
    else if (this.data.gameId === gamesID.pairGame){
      this.setForPairs();
    }
    else if (this.data.gameId === gamesID.trueFalse){
      this.setForTrueFalse();
    }
    else if (this.data.gameId === gamesID.jigsaw){
      this.setForJigsaw();
    }
    else if (this.data.gameId === gamesID.numbers){
      this.setForNumbers();
    }
    else if (this.data.gameId === gamesID.groupTrivia){
      this.setForGroupTrivia();
    }
  }
  setForTrueFalse(): void{
    this.gameMinQuestions = 3;
    if (this.data.configId){
      for (let i = 0; i < this.data.configurations.answers.length; i++){
        this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), answer: new FormControl('', [Validators.required])}));
        this.questionsContorlersArr.at(i).get('question').setValue(this.data.configurations.questions[i]);
        this.questionsContorlersArr.at(i).get('answer').setValue(this.data.configurations.answers[i]);
      }
    }
    else{
      for (let i = 0; i < this.gameMinQuestions ; i++){
        this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), answer: new FormControl('', [Validators.required])}));
      }
    }
    this.isTrueFalse = true;
  }
  setForNumbers(): void{
    this.formControler.addControl('timeToStart', new FormControl('', [Validators.required]));
    if (this.data.configId){
      this.formControler.get('timeToStart').setValue(this.data.configurations.startTime);
    }
    this.isNumbers = true;
  }
  setForGroupTrivia(): void{
    this.gameMinQuestions = 5;
    if (this.data.configId){
      for (let i = 0; i < this.data.configurations.length; i++){
        this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), realAnswer: new FormControl('', [Validators.required]), fakeAnswer1: new FormControl('', [Validators.required]), fakeAnswer2: new FormControl('', [Validators.required]), fakeAnswer3: new FormControl('', [Validators.required]), img: new FormControl('', [Validators.required])}));
        this.questionsContorlersArr.at(i).get('question').setValue(this.data.configurations[i].question);
        this.questionsContorlersArr.at(i).get('realAnswer').setValue(this.data.configurations[i].realAnswer);
        this.questionsContorlersArr.at(i).get('fakeAnswer1').setValue(this.data.configurations[i].fakeAnswer1);
        this.questionsContorlersArr.at(i).get('fakeAnswer2').setValue(this.data.configurations[i].fakeAnswer2);
        this.questionsContorlersArr.at(i).get('fakeAnswer3').setValue(this.data.configurations[i].fakeAnswer3);
        this.questionsContorlersArr.at(i).get('img').setValue(this.data.configurations[i].img);
      }
    }
    else{
      for (let i = 0; i < this.gameMinQuestions; i++){
        this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), realAnswer: new FormControl('', [Validators.required]), fakeAnswer1: new FormControl('', [Validators.required]), fakeAnswer2: new FormControl('', [Validators.required]), fakeAnswer3: new FormControl('', [Validators.required]), img: new FormControl('', [Validators.required])}));
      }
    }
    this.isGroupTrivia = true;
  }
  setForFactory(): void{
    const numOfQuestions = 10;
    for (let i = 0; i < numOfQuestions; i++){
      this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), answer: new FormControl('', [Validators.required])}));
      if (this.data.configId){
        this.questionsContorlersArr.at(i).get('question').setValue(this.data.configurations.questions[i]);
        this.questionsContorlersArr.at(i).get('answer').setValue(this.data.configurations.answers[i]);
      }
    }
    this.isPairGame = true;
  }
  setForBalls(): void{
    const numOfQuestions = 10;
    for (let i = 0; i < numOfQuestions; i++){
      this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), answer: new FormControl('', [Validators.required])}));
      if (this.data.configId){
        this.questionsContorlersArr.at(i).get('question').setValue(this.data.configurations.questions[i]);
        this.questionsContorlersArr.at(i).get('answer').setValue(this.data.configurations.answers[i]);
      }
    }
    this.isPairGame = true;
  }
  setForPairs(): void{
    const numOfQuestions = 12;
    for (let i = 0; i < numOfQuestions; i++){
      this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), answer: new FormControl('', [Validators.required])}));
      if (this.data.configId){
        this.questionsContorlersArr.at(i).get('question').setValue(this.data.configurations.questions[i]);
        this.questionsContorlersArr.at(i).get('answer').setValue(this.data.configurations.answers[i]);
      }
    }
    this.isPairGame = true;
  }
  setForJigsaw(): void{
    const numOfQuestions = 12;
    this.formControler.addControl('jigsawImage', new FormControl('', Validators.required));
    if (this.data.configId){
      this.formControler.get('jigsawImage').setValue(this.data.image);
    }
    for (let i = 0; i < numOfQuestions; i++){
      this.questionsContorlersArr.insert(i, new FormGroup({question: new FormControl('', [Validators.required]), realAnswer: new FormControl('', [Validators.required]), fakeAnswer1: new FormControl('', [Validators.required]), fakeAnswer2: new FormControl('', [Validators.required])}));
      if (this.data.configId){
        this.questionsContorlersArr.at(i).get('question').setValue(this.data.configurations[i].question);
        this.questionsContorlersArr.at(i).get('realAnswer').setValue(this.data.configurations[i].realAnswer);
        this.questionsContorlersArr.at(i).get('fakeAnswer1').setValue(this.data.configurations[i].fakeAnswer1);
        this.questionsContorlersArr.at(i).get('fakeAnswer2').setValue(this.data.configurations[i].fakeAnswer2);
      }
    }
    this.isJigsaw = true;
  }
  onFileChanged(event, index): void{
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e => {
      this.imageLoading = true;
      if (this.data.gameId === gamesID.groupTrivia){
        this.storage.upload('trivia-Images/' + this.data.configId + index, file).then(snapshot => {
          snapshot.ref.getDownloadURL().then(URL => {
            this.questionsContorlersArr.at(index).get('img').setValue(URL);
            this.imageLoading = false;
          });
        });
      }
    });
    reader.readAsDataURL(file);
  }
  removeQuestion(index: number): void{
    this.questionsContorlersArr.removeAt(index);
  }
  addQuestion(): void{
    if (this.isTrueFalse){
      this.questionsContorlersArr.insert(this.questionsContorlersArr.length, new FormGroup({question: new FormControl('', [Validators.required]), answer: new FormControl('', [Validators.required])}));
    }
    else if (this.isGroupTrivia){
      this.questionsContorlersArr.insert(this.questionsContorlersArr.length, new FormGroup({question: new FormControl('', [Validators.required]), realAnswer: new FormControl('', [Validators.required]), fakeAnswer1: new FormControl('', [Validators.required]), fakeAnswer2: new FormControl('', [Validators.required]), fakeAnswer3: new FormControl('', [Validators.required]), img: new FormControl('', [Validators.required])}));
    }
  }
  getUUID(): string{
    var short = require('short-uuid');
    var translator = short();
    return translator.new();
  }

  ngOnInit(): void {
  }
  saveConfig(): void{
    this.disableSave = true;
    if (this.questionsContorlersArr.valid && this.formControler.valid){
      let localConfig = this.questionsContorlersArr.value;
      if (this.isPairGame || this.isTrueFalse){
        console.log(localConfig);
        localConfig = {answers: [], questions: []};
        for (const element of this.questionsContorlersArr.value) {
          localConfig.answers.push(element.answer);
          localConfig.questions.push(element.question);
        }
      }
      if (this.isNumbers){
        localConfig = {startTime: parseInt(this.formControler.get('timeToStart').value)};
      }
      this.saveConfigService.save({configId: this.configId, gameID: this.data.gameId, config: localConfig, name: this.formControler.value.configName, image: this.formControler.value.jigsawImage}, localStorage.getItem('customerId')).then(() => {
        this.dialogRef.close(false);
      });
    }
    else{
      this.snackBar.open('חסר מידע', '', {duration: 5000, direction: 'rtl'});
      this.disableSave = false;
    }
  }
  onNoClick(): void {
    this.dialogRef.close(true);
  }
}
