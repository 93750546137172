import { ChangePasswordDialogComponent } from './../dialogs/change-password-dialog/change-password-dialog.component';
import { ChangeEmailDialogComponent } from './../dialogs/change-email-dialog/change-email-dialog.component';
import { LoginService } from './../services/login-service.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { avaibleLanguages } from 'src/environments/languages';
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalsService } from '../globals.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { LoaderService } from 'src/app/loader/loader.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  languages: string[] = []
  selected:string
  editable = false
  companyName:string
  email:string
  data
  constructor(public dialog: MatDialog,private firestore: AngularFirestore,private globals:GlobalsService,
              private auth: AngularFireAuth,private snackBar:MatSnackBar,private LoginSerice:LoginService,
              private loaderService: LoaderService) { 
    var localLanguage
    var localName
    auth.authState.subscribe( user => {
      if (user) {     
        this.email = user.email
      }
    });
    this.firestore.collection('customers').doc(this.globals.userUid).get()
    .subscribe(doc =>{
      if(doc.exists){
        localLanguage = doc.data()["language"]
        localName = doc.data()["company name"]
      }
    },err => console.log(err),()=>{this.selected = localLanguage; this.companyName=localName; this.loaderService.show(); }
    )
    for (let index = 0; index < avaibleLanguages.length; index++) {
      const language:string = avaibleLanguages[index]
      this.languages.push(language)
    }
  }
  ngOnInit(): void {
  }
  editPrassed(){
    this.editable = true
    this.data = {companyName:this.companyName,language:this.selected,email:this.email}
  }
  saveInfo(){
    this.loaderService.show();
    this.firestore.collection('customers').doc(this.globals.userUid).update({
      "company name": this.companyName,
      "language":this.selected
    }).then(()=>{
      this.snackBar.open("Data updated successfully", "", {
        duration: 3000,
      });  
    }).catch(err =>{
      this.selected = this.data["language"]
      this.companyName = this.data["companyName"]
      this.snackBar.open("Cant update data, try again later", "", {
        duration: 3000,
      });  
    }).finally(()=>{
      this.loaderService.hide();
      this.editable = false
      this.globals.language = this.selected
    })
    
  }
  cancelChanges(){
    this.selected = this.data["language"]
    this.companyName = this.data["companyName"]
    this.editable = false
  }
  changePassword(){
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '350px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  changeEmail(){
    const dialogRef = this.dialog.open(ChangeEmailDialogComponent, {
      width: '350px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
