// tslint:disable: max-line-length
// tslint:disable: no-string-literal
// tslint:disable: forin
// tslint:disable: prefer-for-of

import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Label } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';


@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private firestore: AngularFirestore) { }
  getMonthChart(): Promise<any>{
    const promises: Promise<any>[] = [];
    const monthes: Date[] = [];
    const data = {};
    const monthlyUsersData: ChartDataSets[] = [{ data: [], label: 'משתמשים חדשים'}];
    const monthlyUsersLabels: Label[] = [];
    for (let i = -1; i < 10; i++){
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(1);
      now.setDate(1);
      now.setMonth(now.getMonth() - i);
      monthes.push(now);
    }
    monthes.reverse();
    for (let j = 0; j < monthes.length - 1; j++){
      const realIndex = j;
      promises.push(this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('usersStatistics', ref => ref.where('dateJoined', '>', monthes[realIndex]).where('dateJoined', '<', monthes[realIndex + 1])).get().toPromise().then( allUsers => {
        const month = monthes[realIndex].getMonth() + 1;
        const year = monthes[realIndex].getUTCFullYear();
        monthlyUsersLabels.push(month + '/' + year);
        monthlyUsersData[0].data.push(allUsers.docs.length);
      }));
    }
    return Promise.all(promises).then(() => {
      data['monthlyUsersData'] = monthlyUsersData;
      data['monthlyUsersLabels'] = monthlyUsersLabels;
      return data;
    });
  }
  getDateDicts(startDate: Date, endDate: Date): Promise<any>{
    // set the endDate to the end of day, 23:59:59
    EndOfDay(endDate);
    const dictOfUsers = {};
    const promises = [];
    return this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('usersStatistics', ref => ref.where('dateJoined', '>=', startDate).where('dateJoined', '<=', endDate)).get().toPromise().then( allUsers => {
      const tempMap = {};
      allUsers.docs.forEach(userDoc => {
        let userDate: Date = userDoc.data().dateJoined.toDate();
        const hour = userDate.getHours();
        const month = userDate.getMonth();
        const day = userDate.getDate();
        const year = userDate.getFullYear();
        userDate = new Date(year, month, day, hour);
        if (tempMap[day + '/' + month + '/' + year + '/' + hour + '/' + userDoc.data().activityId] == null){
          promises.push(this.firestore.collection('activities').doc(userDoc.data().activityId).get().toPromise().then(activityDoc =>{
            return this.firestore.collection('customers').doc(localStorage.getItem('customerId')).collection('layouts').doc(activityDoc.data().layoutId).get().toPromise().then(layoutDoc => {
              if (tempMap[day + '/' + month + '/' + year + '/' + hour + '/' + userDoc.data().activityId] == null){
                tempMap[day + '/' + month + '/' + year + '/' + hour + '/' + userDoc.data().activityId] = {'מספר משתמשים': 1, 'שם הפעילות': activityDoc.data().activityName, 'שם החבילה': layoutDoc.data().name, 'תאריך ושעה': userDate.toLocaleString('he-IL') };
              }
              else{
                tempMap[day + '/' + month + '/' + year + '/' + hour + '/' + userDoc.data().activityId]['מספר משתמשים']++;
              }
            });
          }));
        }
        else {
          tempMap[day + '/' + month + '/' + year + '/' + hour + '/' + userDoc.data().activityId]['מספר משתמשים']++;
        }
      });
      return Promise.all(promises).then(() => {
        return tempMap;
      });
    });
  }
  getCharts(startDate: Date, endDate: Date): Promise<any>{
    

   /*  addDays(startDate, 1);
    addDays(endDate, 1); */

    
    const promises: Promise<any>[] = [];
    const data = {};
    promises.push(this.firestore.collection('customers').doc(localStorage.getItem('customerId'))
      .collection('usersStatistics', ref => ref.where('dateJoined', '>=', startDate)
      .where('dateJoined', '<=', endDate)).get().toPromise()
            .then( allUsers => {
      const dailyUsersData: ChartDataSets[] = [{ data: [], label: 'משתמשים חדשים'}];
      const dailyUsersLabels: Label[] = [];
      const activitiesUsersData: ChartDataSets[] =  [{ data: [], label: 'משתמשים' }];
      const activitiesScoresData: ChartDataSets[] =  [{ data: [], label: 'ניקוד' }];
      const activitiesLabels: Label[] = [];
      const tempMap = {};
      const tempActivitiesMap = {};
      getDates(startDate, endDate).forEach(element => {
        const month = element.getUTCMonth() + 1;
        const day = element.getUTCDate();
        const year = element.getUTCFullYear();
        tempMap[day + '/' + month + '/' + year] = {date: element, value: 0};
      });
      allUsers.docs.forEach(userDoc => {
        let userDate: Date = userDoc.data().dateJoined.toDate();
        const month = userDate.getUTCMonth() + 1;
        const day = userDate.getUTCDate();
        const year = userDate.getUTCFullYear();
        userDate = new Date(year, month, day);
        tempMap[day + '/' + month + '/' + year].value += 1;
        if (tempActivitiesMap[userDoc.data().activityId] == null){
          tempActivitiesMap[userDoc.data().activityId] = {score: 0, users: 1};
        }
        else{
          tempActivitiesMap[userDoc.data().activityId].users++;
        }
      });
      let items = Object.keys(tempMap).map((key) => {
        return [key, tempMap[key]];
      });

      items.sort((first, second) => {
        return second[1].date - first[1].date;
      });
      items = items.reverse();
      items.forEach(element => {
        dailyUsersLabels.push(element[0]);
        dailyUsersData[0].data.push(element[1].value);
      });
      let items1 = Object.keys(tempActivitiesMap).map((key) => {
        return [key, tempActivitiesMap[key]];
      });

      items1.sort((first, second) => {
        return second[1].users - first[1].users;
      });
      items1 = items1.reverse();
      items1.forEach(element => {
        activitiesLabels.push(element[0]);
        activitiesUsersData[0].data.push(element[1].users);
        activitiesScoresData[0].data.push(0);
      });
      const promisess: Promise<any>[] = [];
      items1.forEach(element => {
        const thiscode = element[0];
        promisess.push(this.averageScoreForActivity(element[0]).then(score => {
          if (isNaN(score)){
            score = 0;
          }
          return this.firestore.collection('activities').doc(thiscode).get().toPromise().then(activityDoc => {
            activitiesLabels[activitiesLabels.indexOf(thiscode)] = activityDoc.data().activityName;
            activitiesScoresData[0].data[activitiesLabels.indexOf(activityDoc.data().activityName)] = score;
          });
        }));
      });
      return Promise.all(promisess).then(() => {
        data['usersCount'] = allUsers.docs.length;
        data['dailyUsersLabels'] = dailyUsersLabels;
        data['dailyUsersData'] = dailyUsersData;
        data['activitiesUsersData'] = activitiesUsersData;
        data['activitiesScoresData'] = activitiesScoresData;
        data['activitiesLabels'] = activitiesLabels;
      });
    }));
    promises.push(this.firestore.collection('activities', ref => ref.where('customerUID', '==', localStorage.getItem('customerId')).where('end time', '>', new Date())).get().toPromise().then( allActivities => {
      let count = 0;
      allActivities.forEach(activityDoc => {
        if (activityDoc.data()['start time'] < new Date() && activityDoc.data().activityType !== 'course'){
          count++;
        }
      });
      data['runningActivitiesCount'] = count;
    }));
    promises.push(this.firestore.collection('activities', ref => ref.where('customerUID', '==', localStorage.getItem('customerId')).where('end time', '>', new Date())).get().toPromise().then( allActivities => {
      let count = 0;
      allActivities.forEach(activityDoc => {
        if (activityDoc.data()['start time'] < new Date() && activityDoc.data().activityType === 'course'){
          count++;
        }
      });
      data['courses'] = count;
    }));
    return Promise.all(promises).then(() => {
      return data;
    });
  }
  averageScoreForActivity(activityId): Promise<number>{
    const dictOfGames = {};
    return this.firestore.collection('scoreReport', ref => ref.where('activityId', '==', activityId)).get().toPromise().then( scores => {
      scores.forEach( scoreDoc => {
        if (dictOfGames[scoreDoc.data().gameId] == null){
          dictOfGames[scoreDoc.data().gameId] = {sumScore: 0, countScore: 0};
        }
        if (scoreDoc.data().gameScore != null){
          dictOfGames[scoreDoc.data().gameId].sumScore += scoreDoc.data().gameScore;
          dictOfGames[scoreDoc.data().gameId].countScore++;
        }
      });
    }).then(() => {
      let sumScore = 0;
      let countScore = 0;
      for ( let gameId in dictOfGames){
        countScore++;
        if (dictOfGames[gameId].countScore !== 0){
          sumScore += dictOfGames[gameId].sumScore / dictOfGames[gameId].countScore;
        }
      }
      return Math.round(sumScore / countScore);
    });
  }
}
function addDays(date, days): Date {
  date.setDate(date.getDate() + days);
  return date;
}

function EndOfDay(date): Date {
   date.setHours(23, 59, 59, 999);
   return date;
}

function getDates(startDate, stopDate): Date[] {
  const dateArray = new Array();
  let currentDate = startDate;
  while (currentDate <= stopDate) {
      dateArray.push(new Date (currentDate));
      currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}


