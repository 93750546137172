


<div id="previewTable"  class="mat-elevation-z8 table-wrapper">
  <table mat-table [dataSource]="dataSource" style="margin-top: 2vh;">
  <ng-container matColumnDef="name" >
    <th mat-header-cell *matHeaderCellDef style="text-align: center;"> שם התחנה </th>
    <td mat-cell *matCellDef="let element"  style="text-align: center;">
      {{element.name}}
    </td>
  </ng-container>


  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <button mat-button (click)="editPrassed(element, false)" *ngIf='!element.allowEdit'>
        <mat-icon>preview</mat-icon>
      </button>
      <button mat-button (click)="editPrassed(element, true)" *ngIf='element.allowEdit'>
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-button (click)="onDeleteComponent(element)" *ngIf='element.allowDelete'>
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20 ,1]" showFirstLastButtons></mat-paginator>
  <button mat-button color="primary" class="button-general btn-add-config" (click)='createConfig()'>
    <mat-icon>add</mat-icon>
    <span>הוספת תחנה</span>
  </button>
</div>
