// tslint:disable: max-line-length
// tslint:disable: forin

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Activity } from '../../classes/activity';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { LeaderboardComponent } from '../leaderboard/leaderboard.component';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  hasSatisReport = false;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Activity,
    public dialogRef: MatDialogRef<ReportsComponent>,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private snackBar: MatSnackBar) {
      data.componentsConfiguritions.forEach( component => {
        if (component.componentId === 'ruvelQ6mbSE1Nm1W0dkc'){
          this.hasSatisReport = true;
        }
      });
  }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  downloadSatifReport(): void {
    const dictOfReports = {};
    let fileName:string = '';
    this.firestore.collection('activities').doc(this.data.activityCode).get().toPromise().then(activityDoc => {
      fileName += activityDoc.data().activityName;
    }).then(() => {
      this.firestore.collection('activitySatisfactionReport', ref => ref.where('activityId', '==', this.data.activityCode)).get().toPromise().then( reports => {
        reports.forEach(reportDoc => {
          if (dictOfReports[reportDoc.id] == null){
            dictOfReports[reportDoc.id] = {};
          }
          let i = reportDoc.data().answers.length;
          dictOfReports[reportDoc.id]['הערות'] = reportDoc.data().comments;
          reportDoc.data().answers.reverse().forEach(answer => {
            dictOfReports[reportDoc.id]['שאלה ' + i] = answer;
            i--;
          });
          dictOfReports[reportDoc.id]['uid'] =  reportDoc.data().userId;
        });
      }).then(() => {
        const promises = [];
        for ( let reportId in dictOfReports){
          promises.push(this.firestore.collection('users').doc(dictOfReports[reportId]['uid']).collection('activities').doc(this.data.activityCode).get().toPromise().then(userDoc => {
            delete  dictOfReports[reportId].uid;
            if (this.data.loginType !== 'solos'){
              if (this.data.loginType === 'hierarchy'){
                const array: Array<string> = userDoc.data().group;
                dictOfReports[reportId].קבוצה = array[array.length-1];
              }
              else{
                dictOfReports[reportId].קבוצה = userDoc.data().group;
              }
            }
            if (this.data.isNameRequired){
              dictOfReports[reportId].שם = userDoc.data().name;
            }
            else{
              dictOfReports[reportId].שם = 'משתתף אנונימי מקבוצה ' + userDoc.data().group;
            }
          }));
        }
        Promise.all(promises).then(() => {
          const arrayOfUsers = [];
          let maxLen = 0;
          let header;
          for ( let userId in dictOfReports){
            arrayOfUsers.push(dictOfReports[userId]);
            if (Object.keys(dictOfReports[userId]).length > maxLen){
              maxLen = Object.keys(dictOfReports[userId]).length;
              header = Object.keys(dictOfReports[userId]);
            }
          }
          const items = arrayOfUsers;
          const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
          header.reverse();
          const tempcsv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          tempcsv.unshift(header.join(','));
          const content = String.fromCharCode(0xFEFF) + tempcsv.join('\r\n');
          const csvContent = 'data:text/csv;charset=utf-8,'  + content;
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          fileName += ' statisfication ' + new Date().toLocaleDateString("he-IL") + '.csv';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        });
      });
    });
  }
  downloadGrades(): void{
    const games = {};
    let promises = [];
    let fileName:string = '';
    promises.push(this.firestore.collection('activities').doc(this.data.activityCode).get().toPromise().then(activityDoc => {
      fileName += activityDoc.data().activityName;
    }));
    promises.push(this.firestore.collection('games').get().toPromise().then( gamesCol => {
      gamesCol.forEach(gameDoc => {
        games[gameDoc.id] = {};
        games[gameDoc.id]['name'] = gameDoc.data().name;
        games[gameDoc.id]['isFinishable'] = gameDoc.data().isFinishable;
      })
    }));
    Promise.all(promises).then(() => {
      const dictOfScores = {};
      this.firestore.collection('scoreReport', ref => ref.where('activityId', '==', this.data.activityCode)).get().toPromise().then( scores => {
        scores.forEach( scoreDoc => {
          if (dictOfScores[scoreDoc.id] == null){
            dictOfScores[scoreDoc.id] = {};
          }
          if (scoreDoc.data().isDone != null){
            dictOfScores[scoreDoc.id]['סיים או לא'] = 'סיים';
          }
          else{
            if (games[scoreDoc.data().gameId].isFinishable === false){
              dictOfScores[scoreDoc.id]['סיים או לא'] = 'סיים';
            }
            else{
              dictOfScores[scoreDoc.id]['סיים או לא'] = 'לא סיים';
            }
          }
          if (scoreDoc.data().wrongAnswers != null){
            dictOfScores[scoreDoc.id]['טעויות'] = scoreDoc.data().wrongAnswers;
          }
          else{
            dictOfScores[scoreDoc.id]['טעויות'] = 'אין שאלות במשחק...';
          }
          if (scoreDoc.data().gameTimeSecond != null){
            dictOfScores[scoreDoc.id]['זמן בשניות'] = scoreDoc.data().gameTimeSecond;
          }
          dictOfScores[scoreDoc.id]['ניקוד'] = Math.round(scoreDoc.data().gameScore * 10) / 10;
          dictOfScores[scoreDoc.id]['משחק'] = games[scoreDoc.data().gameId].name['he'];
          dictOfScores[scoreDoc.id]['id'] =  scoreDoc.data().userId;
        });
      }).then(() => {
        promises = [];
        for ( let scoreId in dictOfScores){
          promises.push(this.firestore.collection('users').doc(dictOfScores[scoreId]['id']).collection('activities').doc(this.data.activityCode).get().toPromise().then(userDoc => {
            delete  dictOfScores[scoreId].id;
            if (this.data.loginType !== 'solos'){
              if (this.data.loginType === 'hierarchy'){
                const array: Array<string> = userDoc.data().group;
                dictOfScores[scoreId].קבוצה = array[array.length-1];
              }
              else{
                dictOfScores[scoreId].קבוצה = userDoc.data().group;
              }
            }
            if (this.data.isNameRequired){
              dictOfScores[scoreId].שם = userDoc.data().name;
            }
            else{
              dictOfScores[scoreId].שם = 'משתתף אנונימי מקבוצה ' + userDoc.data().group;
            }
          }));
        }
        Promise.all(promises).then(() => {
          const arrayOfUsers = [];
          let maxLen = 0;
          let header;
          for ( let userId in dictOfScores){
            arrayOfUsers.push(dictOfScores[userId]);
            if (Object.keys(dictOfScores[userId]).length > maxLen){
              maxLen = Object.keys(dictOfScores[userId]).length;
              header = Object.keys(dictOfScores[userId]);
            }
          }
          const items = arrayOfUsers;
          const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
          header.reverse();
          const tempcsv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          tempcsv.unshift(header.join(','));
          const content = String.fromCharCode(0xFEFF) + tempcsv.join('\r\n');
          const csvContent = 'data:text/csv;charset=utf-8,'  + content;
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          fileName += ' grades ' + new Date().toLocaleDateString("he-IL") + '.csv';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        });
      });
    });
  }
  downloadCourseStatus(): void{
    const dictOfUsers = {};
    let fileName:string = '';
    this.firestore.collection('activities').doc(this.data.activityCode).get().toPromise().then(activityDoc => {
      fileName += activityDoc.data().activityName;
    }).then(() => {
      this.firestore.collection('activities').doc(this.data.activityCode).collection('users').get().toPromise().then(users => {
        users.docs.forEach( userDoc => {
          dictOfUsers[userDoc.id] = {sumTime: 0, countTime: 0, sumScore: 0, countScore: 0};
        });
      }).then(() => {
        this.firestore.collection('scoreReport', ref => ref.where('activityId', '==', this.data.activityCode)).get().toPromise().then( scores => {
          scores.forEach( scoreDoc => {
            if (scoreDoc.data().gameTimeSecond != null){
              dictOfUsers[scoreDoc.data().userId].sumTime += scoreDoc.data().gameTimeSecond;
              dictOfUsers[scoreDoc.data().userId].countTime++;
            }
            if (scoreDoc.data().gameScore != null){
              dictOfUsers[scoreDoc.data().userId].sumScore += scoreDoc.data().gameScore;
              dictOfUsers[scoreDoc.data().userId].countScore++;
            }
          });
        }).then(() => {
          const promises = [];
          for ( let userId in dictOfUsers){
            promises.push(this.firestore.collection('users').doc(userId).collection('activities').doc(this.data.activityCode).get().toPromise().then(userDoc => {
              const now = new Date();
              now.setDate(now.getDate() - 8);
              if (userDoc.data().endTime != null){
                if (dictOfUsers[userId].countScore !== 0){
                  dictOfUsers[userId]['ציון ממוצע'] =   dictOfUsers[userId].sumScore / dictOfUsers[userId].countScore;
               /*    dictOfUsers[userId]['ציון ממוצע'] = Math.floor( dictOfUsers[userId]['ציון ממוצע'] * 100) / 100; */
                  dictOfUsers[userId]['ציון ממוצע'] = parseFloat(dictOfUsers[userId]['ציון ממוצע']).toFixed(1);
                }
                else{
                  dictOfUsers[userId]['ציון ממוצע'] = 'אין';
                }
                if (dictOfUsers[userId].countTime !== 0){
                  dictOfUsers[userId]['זמן ממוצע'] = dictOfUsers[userId].sumTime / dictOfUsers[userId].countTime;
                }
                else{
                  dictOfUsers[userId]['זמן ממוצע'] = 'אין';
                }
                dictOfUsers[userId]['האם הקורס תקוע?'] = 'לא';
                dictOfUsers[userId]['תאריך סיום קורס'] = userDoc.data().endTime.toDate().toLocaleDateString("he-IL");
              }
              else{
                dictOfUsers[userId]['ציון ממוצע'] = 'לא הסתיים עדיין';
                dictOfUsers[userId]['זמן ממוצע'] = 'לא הסתיים עדיין';
                if (userDoc.data().startTime.toDate() < now){
                  dictOfUsers[userId]['האם הקורס תקוע?'] = 'כן';
                }
                else{
                  dictOfUsers[userId]['האם הקורס תקוע?'] = 'לא';
                }
                dictOfUsers[userId]['תאריך סיום קורס'] = 'לא הסתיים עדיין';
              }
              dictOfUsers[userId]['תאריך תחילת קורס'] = userDoc.data().startTime.toDate().toLocaleDateString("he-IL");
              if (this.data.loginType !== 'solos'){
                if (this.data.loginType === 'hierarchy'){
                  const array: Array<string> = userDoc.data().group;
                  dictOfUsers[userId].קבוצה = array[array.length-1];
                }
                else{
                  dictOfUsers[userId].קבוצה = userDoc.data().group;
                }
              }
              if (this.data.isNameRequired){
                dictOfUsers[userId]['שם עובד'] = userDoc.data().name;
              }
              else{
                dictOfUsers[userId].שם = 'משתתף אנונימי מקבוצה ' + userDoc.data().group;
              }
              delete dictOfUsers[userId].sumTime;
              delete dictOfUsers[userId].countTime;
              delete dictOfUsers[userId].sumScore;
              delete dictOfUsers[userId].countScore;
            }));
          }
          Promise.all(promises).then(() => {
            const arrayOfUsers = [];
            let maxLen = 0;
            let header;
            for ( let userId in dictOfUsers){
              arrayOfUsers.push(dictOfUsers[userId]);
              if (Object.keys(dictOfUsers[userId]).length > maxLen){
                maxLen = Object.keys(dictOfUsers[userId]).length;
                header = Object.keys(dictOfUsers[userId]);
              }
            }
            const items = arrayOfUsers;
            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            header.reverse();
            const tempcsv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
            tempcsv.unshift(header.join(','));
            const content = String.fromCharCode(0xFEFF) + tempcsv.join('\r\n');
            const csvContent = 'data:text/csv;charset=utf-8,'  + content;
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            fileName += ' course status ' + new Date().toLocaleDateString("he-IL") + '.csv';
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          });
        });
      });
    });
  }
  downloadStatistics(): void {
    const games = {};
    let activityCode: any = {};
    let activityStartTime: any = {};
    let activityEndTime: any = {};
    let promises: Promise<any>[] = [];
    let fileName: string = '';
    promises.push(
      this.firestore
      .collection('activities')
      .doc(this.data.activityCode)
      .get()
      .toPromise()
      .then(activityDoc => {
        activityCode = activityDoc.id;
        activityStartTime = activityDoc.data()['start time'];
        activityStartTime =  new Date( activityStartTime.seconds * 1000 + activityStartTime.nanoseconds / 1000, ).toLocaleDateString("he-IL");
        activityEndTime = activityDoc.data()['end time'];
        activityEndTime =  new Date( activityEndTime.seconds * 1000 + activityEndTime.nanoseconds / 1000, ).toLocaleDateString("he-IL");
        fileName += activityDoc.data().activityName;
      })
    );
    promises.push(
      this.firestore
      .collection('games')
      .get()
      .toPromise()
      .then( gamesCol => {
        gamesCol.forEach(gameDoc => {
          games[gameDoc.id] = {};
          games[gameDoc.id]['code'] = activityCode;
          games[gameDoc.id]['startTime'] = activityStartTime;
          games[gameDoc.id]['endTime'] = activityEndTime;
          games[gameDoc.id]['name'] = gameDoc.data().name;
          games[gameDoc.id]['isFinishable'] = gameDoc.data().isFinishable;
        })
    }));
    Promise.all(promises).then(() => {
      const dictOfGames = {};
      this.firestore
      .collection('scoreReport', ref => ref.where('activityId', '==', this.data.activityCode))
      .get()
      .toPromise()
      .then( scores => {
        scores.forEach( scoreDoc => {
          if (dictOfGames[scoreDoc.data().gameId] == null){
            dictOfGames[scoreDoc.data().gameId] = {sumTime: 0, countTime: 0, sumScore: 0, countScore: 0, done: 0, count: 0};
          }
          dictOfGames[scoreDoc.data().gameId].count++;
          if (scoreDoc.data().isDone != null){
            dictOfGames[scoreDoc.data().gameId].done++;
          }
          if (scoreDoc.data().gameScore != null){
            dictOfGames[scoreDoc.data().gameId].sumScore += scoreDoc.data().gameScore;
            dictOfGames[scoreDoc.data().gameId].countScore++;
          }
          if (scoreDoc.data().gameTimeSecond != null){
            dictOfGames[scoreDoc.data().gameId].sumTime += scoreDoc.data().gameTimeSecond;
            dictOfGames[scoreDoc.data().gameId].countTime++;
          }
        });
      }).then(() => {
        for ( let gameId in dictOfGames){
          if (dictOfGames[gameId].countTime === 0){
            dictOfGames[gameId]['הזמן הממוצע'] = 0;
          }
          else{
            let totalSeconds = (dictOfGames[gameId].sumTime / dictOfGames[gameId].countTime);
            dictOfGames[gameId]['הזמן הממוצע'] = new Date(totalSeconds * 1000).toISOString().substr(11, 8)
          }
          if (dictOfGames[gameId].countScore === 0){
            dictOfGames[gameId]['הציון הממוצע'] = 0;
          }
          else{
            dictOfGames[gameId]['הציון הממוצע'] =
                                dictOfGames[gameId].sumScore / dictOfGames[gameId].countScore;
            dictOfGames[gameId]['הציון הממוצע'] =
                                parseFloat(dictOfGames[gameId]['הציון הממוצע']).toFixed(1);
          }
          if (games[gameId].isFinishable === false){
            dictOfGames[gameId]['מספר השחקנים שסיימו'] = dictOfGames[gameId].count;
          }
          else{
            dictOfGames[gameId]['מספר השחקנים שסיימו'] = dictOfGames[gameId].done;
          }
          dictOfGames[gameId]['מספר השחקנים ששיחקו'] = dictOfGames[gameId].count;
          dictOfGames[gameId]['שם המשחק'] = games[gameId].name['he'];
          dictOfGames[gameId]['סיום פעילות'] = games[gameId].endTime;
          dictOfGames[gameId]['תחילת פעילות'] = games[gameId].startTime;
          dictOfGames[gameId]['קוד פעילות'] = games[gameId].code;

          delete dictOfGames[gameId].sumTime;
          delete dictOfGames[gameId].countTime;
          delete dictOfGames[gameId].sumScore;
          delete dictOfGames[gameId].countScore;
          delete dictOfGames[gameId].done;
          delete dictOfGames[gameId].count;
        }
        const arrayOfUsers = [];
        let maxLen = 0;
        let header;
        for ( let userId in dictOfGames){
          arrayOfUsers.push(dictOfGames[userId]);
          if (Object.keys(dictOfGames[userId]).length > maxLen){
            maxLen = Object.keys(dictOfGames[userId]).length;
            header = Object.keys(dictOfGames[userId]);
          }
        }
        if (maxLen === 0) return this.snackBar.open('אין נתונים בפעילות זו', '', {duration: 2000, direction: 'rtl'});
        const items = arrayOfUsers;
        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        header.reverse();
        const tempcsv =
                items.map(row =>
                    header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        tempcsv.unshift(header.join(','));
        const content = String.fromCharCode(0xFEFF) + tempcsv.join('\r\n');
        const csvContent = 'data:text/csv;charset=utf-8,'  + content;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        fileName += 'statistics ' + new Date().toLocaleDateString("he-IL") + '.csv';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
    });
  }

  async downloadDetailed() {
    const games = {};
    let promises: Promise<any>[] = [];
    let fileName = (await this.firestore.collection('activities').doc(this.data.activityCode)
                    .get().toPromise()).data().activityName;

    (await this.firestore.collection('games').get().toPromise()).docs.forEach(gameDoc => {
      games[gameDoc.id] = {};
      games[gameDoc.id]['name'] = gameDoc.data().name;
      games[gameDoc.id]['isFinishable'] = gameDoc.data().isFinishable;
    });

    const dictOfScores = {};

    this.firestore
      .collection('gamesDetailedReport', ref => ref.where('activityId', '==', this.data.activityCode))
      .get()
      .toPromise()
      .then( scores => {
        scores.forEach( scoreDoc => {
          if (dictOfScores[scoreDoc.id] == null){
            dictOfScores[scoreDoc.id] = {};
          }
          dictOfScores[scoreDoc.id].userId = scoreDoc.data().userId;
          dictOfScores[scoreDoc.id]['נכון או לא'] = scoreDoc.data().isCorrectAnswer;
          if (scoreDoc.data().userAnswer != null){
            dictOfScores[scoreDoc.id].תשובה = scoreDoc.data().userAnswer;
          }
          dictOfScores[scoreDoc.id].שאלה = scoreDoc.data().question;
          dictOfScores[scoreDoc.id].משחק = games[scoreDoc.data().gameId].name['he'];
        });
      }).then(() => {
        promises = [];
        for ( let scoreId in dictOfScores){
          promises.push(
            this.firestore.collection('users')
              .doc(dictOfScores[scoreId].userId)
              .collection('activities')
              .doc(this.data.activityCode)
              .get()
              .toPromise().then(userDoc => {
                if (this.data.loginType !== 'solos'){
                  if (this.data.loginType === 'hierarchy'){
                    const array: Array<string> = userDoc.data().group;
                    dictOfScores[scoreId].קבוצה = array[array.length-1];
                  }
                  else{
                    dictOfScores[scoreId].קבוצה = userDoc.data().group;
                  }
                }
                if (this.data.isNameRequired){
                  dictOfScores[scoreId].שם = userDoc.data().name;
                }
                else{
                  dictOfScores[scoreId].שם = 'משתתף אנונימי מקבוצה ' + userDoc.data().group;
                }
                delete dictOfScores[scoreId].userId;
          }));
        }
        Promise.all(promises).then(() => {
          const arrayOfUsers = [];
          let maxLen = 0;
          let header;
          for ( let userId in dictOfScores){
            arrayOfUsers.push(dictOfScores[userId]);
            if (Object.keys(dictOfScores[userId]).length > maxLen){
              maxLen = Object.keys(dictOfScores[userId]).length;
              header = Object.keys(dictOfScores[userId]);
            }
          }
          const items = arrayOfUsers
          const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
          header.reverse();
          const tempcsv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          tempcsv.unshift(header.join(','));
          const content = String.fromCharCode(0xFEFF) + tempcsv.join('\r\n');
          const csvContent = 'data:text/csv;charset=utf-8,'  + content;
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          fileName += ' detailed ' + new Date().toLocaleDateString("he-IL") + '.csv';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        });
      });
  }
  showLeaderboard(): void{
    const dialogRef = this.dialog.open(LeaderboardComponent, {
      width: '550px',
      data: this.data
      });
  }
}
