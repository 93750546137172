<div id="divStatisticsContainer" class="div-statistics-container matero-row"  fxLayout="row wrap">
  <!-- Statistics -->
  <div fxFlex="100" >
    <mat-form-field appearance="fill" style="margin-inline-start: 8px;margin-block-start: 8px;width: fit-content !important;">
      <mat-label>בחירת תקופת זמן</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input #start (dateChange)='updateCharts()' matStartDate required autocomplete="false" placeholder="תאריך התחלה" [formControl]="startDate" (focus)='picker.open(); start.blur()'>
        <input #end (dateChange)='updateCharts()' matEndDate required autocomplete="false" placeholder="תאריך סיום" [formControl]="endDate" (focus)='picker.open(); end.blur()'>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>    
    <button style="margin-right: 20px; position: absolute; top: 28px;" mat-raised-button class="button-general"  [disabled]='paymentReportDisabled' (click)="downloadReport()">הפקת דוח חיובים<mat-progress-bar *ngIf='paymentReportDisabled' mode="indeterminate"></mat-progress-bar></button>
    <button style="margin-right: 200px; position: absolute; top: 28px;" mat-raised-button class="button-general" [disabled]='statusReportDisabled' (click)="downloadStatusReport()">הפקת דוח מצב נוכחי<mat-progress-bar *ngIf='statusReportDisabled' mode="indeterminate"></mat-progress-bar></button>

  </div>
  
  <div class="matero-col" fxFlex.gt-sm="33.333" fxFlex.gt-xs="50" fxFlex="100">
    <mat-card >
      <mat-card-title class="f-s-12 f-w-100">פעילויות משחקים פתוחות</mat-card-title>
      <mat-card-content>
        <p class="p-activite">{{usersCount}}</p>
        <mat-divider color='primary' style="width: 93%; border-top-color: #741ba1;"></mat-divider> 
        <!-- <mat-progress-bar [value]="100"
                      height="2px"
                      foreground="rgba(255,255,255,1)"
                      background='rgba(0,0,0,.5)'>
        </mat-progress-bar> -->
        <small class="text-muted" style="font-size: 15.5px;">בתקופה שנבחרה</small>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="matero-col" fxFlex.gt-sm="33.333" fxFlex.gt-xs="50" fxFlex="100">
    <mat-card >
      <mat-card-title class="f-s-12 f-w-100">פעילויות משחקים פתוחות</mat-card-title>
      <mat-card-content>
        <p class="p-activite">{{runningActivitiesCount}}</p>
        <mat-divider color='primary' style="width: 93%; border-top-color: #741ba1;"></mat-divider> 
        <!-- <mat-progress-bar [value]="100"
                      height="2px"
                      foreground="rgba(255,255,255,1)"
                      background='rgba(0,0,0,.5)'>
        </mat-progress-bar> -->
        <small class="text-muted" style="font-size: 15.5px;">עכשיו</small>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="matero-col" fxFlex.gt-sm="33.333" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card >
      <mat-card-title class="f-s-12 f-w-100">פעילויות קורסים פתוחות</mat-card-title>
      <mat-card-content>
        <p class="p-activite">{{courses}}</p>
        <mat-divider color='primary' style="width: 93%; border-top-color: #741ba1;"></mat-divider> 
        <!-- <mat-progress-bar [value]="100"
                      height="2px"
                      foreground="rgba(255,255,255,1)"
                      background='rgba(0,0,0,.5)'>
        </mat-progress-bar> -->
        <small class="text-muted" style="font-size: 15.5px;">עכשיו</small>
      </mat-card-content>
    </mat-card>
  </div>
  

  <!-- Charts -->
  
  <div class="matero-col"  fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-subtitle style="color: black !important; font-size: medium;">משתמשים בכל פעילות בתקופה שנבחרה</mat-card-subtitle >
      <div style="display: block;">
        <canvas baseChart 
          [datasets]="activitiesUsersData"
          [labels]="activitiesLabels"
          [options]="chartsOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [chartType]="barChartType"
          [colors]="barChartColors">
        </canvas>
      </div>
    </mat-card>
  </div>
  <div class="matero-col"  fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-subtitle style="color: black !important; font-size: medium;">ניקוד ממוצע בכל פעילות בתקופה שנבחרה</mat-card-subtitle >
      <div style="display: block;">
        <canvas baseChart 
          [datasets]="activitiesScoresData"
          [labels]="activitiesLabels"
          [options]="chartsOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [chartType]="barChartType"
          [colors]="barChartColorsLeft">
        </canvas>
      </div>
    </mat-card>
  </div>
  <div class="matero-col"  fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-subtitle style="color: black !important; font-size: medium;">משתמשים שהצטרפו בכל יום בתקופה שנבחרה</mat-card-subtitle >
      <div style="display: block;">
        <canvas baseChart 
          [colors]="dailyLineChartColors"
          [datasets]="dailyUsersData"
          [labels]="dailyUsersLabels"
          [options]="chartsOptions"
          [plugins]="lineChartPlugins"
          [legend]="lineChartLegend"
          [chartType]="lineChartType">
        </canvas>
      </div>
    </mat-card>
  </div>
  <div class="matero-col"  fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-xs="100">
    <mat-card>
      <mat-card-subtitle style="color: black !important; font-size: medium;">משתמשים חודשיים</mat-card-subtitle>
      <div style="display: block;">
        <canvas baseChart 
          [colors]="monthLineChartColors"
          [datasets]="monthlyUsersData"
          [labels]="monthlyUsersLabels"
          [options]="chartsOptions"
          [plugins]="lineChartPlugins"
          [legend]="lineChartLegend"
          [chartType]="lineChartType">
        </canvas>
      </div>
    </mat-card>
  </div>
</div>