  <div class="div-title-plan-activity">
    <span>בחר חבילה</span>
 </div>
<div *ngIf='!loading' class="div-play-activity-container"  fxLayout="row wrap">  
    <div *ngFor="let layout of layoutsArr" class="matero-col" fxFlex="100"  fxFlex.gt-xs="50" fxFlex.gt-sm="50" fxFlex.gt-md="33" fxFlex.gt-lg="33" style="height: 30%;" >
        <mat-card >
            <div class="div-info-button">
                <button (click)='showDescription(layout)' mat-icon-button color="success">
                    <mat-icon>info</mat-icon>
                </button>
            </div>
            <button (click)='moveToCreate(layout)' mat-button style="top: 10%; right: 1%;" class="table-bottom-add-button"
            [ngStyle]='getBTNColor(layout)'>{{this.layoutsData.get(layout).type=='course'?'יצירת פעילות עם תחנות':'יצירת פעילות'}}</button>
            <p  style="margin-top: 4.8em; margin-bottom: 1em; width: 70%; font-size: 18px; font-weight: bold;">{{this.layoutsData.get(layout).name}}</p>
            <!-- <img  src={{this.layoutsData.get(layout).url}} style="left: 6%; height: 30%; position: absolute; top: 16%;">             -->
            <mat-divider color='primary' style="width: 93%; border-top-color: #e5e5e5;" [ngStyle]='getDividerColor(layout)'></mat-divider>                        
            <div style="margin-top: 3em;width: 100%;display: flex; font-size: medium;justify-content: space-between;">
                <span  style="text-align: center;">
                    <p class="p-activite-title">פעילויות פתוחות</p>
                    <p class="p-activite">{{this.layoutsData.get(layout).openActivities}}</p>
                </span>
                <span style="text-align: center;">
                    <p class="p-activite-title">הסתיימו לאחרונה</p>
                    <p class="p-activite">{{this.layoutsData.get(layout).recentActivities}}</p>
                </span>
                <span style="text-align: center;">
                    <p class="p-activite-title">ארכיון פעיליות</p>
                    <p class="p-activite">{{this.layoutsData.get(layout).allActivities}}</p>
                </span>
            </div>            
        </mat-card>
    </div>
</div>
