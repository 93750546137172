
<h1 mat-dialog-title [dir]="dir">לוח תוצאות</h1>
<div [dir]="dir" mat-dialog-content style="overflow-x:hidden;" >
    <mat-list>
        <mat-list-item *ngFor="let result of results">
            <div class="group">
                <div class="rank">
                    <!-- <img src="assets/images/emptybutton.png" class="place"> -->
                    <!-- <div class="centered">{{result.place}}</div> -->
                </div>
                <div class="name">{{result.place + " - " + result.name}}</div>
                <div class="score">{{result.score}}</div>
            </div>
        </mat-list-item>
    </mat-list>
</div>
<div [dir]="dir" mat-dialog-actions>
    <button mat-stroked-button class="center" color="primary" (click)="onOkClick()">אישור</button>
</div>

    