import { element } from 'protractor';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { avaibleLanguages } from './../../environments/languages';
import { Router } from '@angular/router';
import { GlobalsService } from './../globals.service';
import { Component, OnInit, Input } from '@angular/core';
import getUserLocale from 'get-user-locale'
import { LoginComponent } from '../login/login.component';
// tslint:disable: prefer-for-of
// tslint:disable: max-line-length

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  @Input() text;
  settingsAvaible = false;
  switchLanguageAvaible = false;
  switchLayoutAvaible = false;
  layouts = [];
  logoSrc = '';
  constructor(private global: GlobalsService, private router: Router, private snackBar: MatSnackBar, private firestore: AngularFirestore) {
    if (localStorage.getItem('customerId')){
      this.firestore.collection('customers').doc(localStorage.getItem('customerId')).get().toPromise().then( customerDoc => {
        this.logoSrc = customerDoc.data().url;
        
      });
    }
    this.switchLanguageAvaible = false;
  }

  ngOnInit(): void {
  }

  switchLanguage(): void{
    if (this.global.language === 'en'){
      this.global.language = getUserLocale();
      if (!avaibleLanguages.includes(this.global.language)){
        this.global.language = 'en';
      }
    }
    else{
      this.global.language = 'en';
    }
    this.snackBar.open('language has been changed to ' + this.global.language, '', {duration: 2000});
  }
}
